import React from 'react'

import { Layout } from 'antd';

//Dispatchs

//Styles And Helpers

//Contexts

//Components

//Variables
const { Footer } = Layout;

const LayoutDefault = () => {
  const getData = () => {
    let dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();
    return anoAtual
  };

  //Dispatchs

  //States Reducer

  //States Local

  //Context

  //Cicle of Life

  //Functions

  //Render
  return (
    <Footer
      style={{
        textAlign: 'center',
      }}
    >
      Iguaçu Máquinas ©{getData()} todos os direitos reservados
    </Footer>
  );
};
export default LayoutDefault;

