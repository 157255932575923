import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as BrandsActions,
  Types as BrandsTypes,
} from '../../reducers/brands';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

//BRANDS
function* listBrands({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/brands?page=${payload?.page}${payload?.code && `&code=${payload?.code}`}${payload?.brand && `&brand=${payload?.brand}`}${payload?.fabric&& `&fabric=${payload?.fabric}`}`
    );
    
    yield put(BrandsActions.setUpdateBrands(false));
    yield put(BrandsActions.getBrandsListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(BrandsActions.getBrandsListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addBrands({ payload }) {
  try {
    yield call(api.post, '/admin/brands', payload);
    yield put(BrandsActions.setUpdateBrands(true));
    yield put(BrandsActions.postBrandsAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(BrandsActions.postBrandsAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editBrands({ payload }) {
  try {
    yield call(api.put, `/admin/brands/${payload?.id}`, payload);
    yield put(BrandsActions.setUpdateBrands(true));
    yield put(BrandsActions.putBrandsEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(BrandsActions.putBrandsEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteBrands({ payload }) {
  try {
    yield call(api.delete, `/admin/brands/${payload?.id}`);
    // console.log('data store', data)
    yield put(BrandsActions.setUpdateBrands(true));
    yield put(BrandsActions.postBrandsAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(BrandsActions.deleteBrandsDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

//BRANDS
export function* listBrandsWatcher() {
  yield takeLatest(BrandsTypes.GET_BRANDS_LIST_REQUEST, listBrands);
}

export function* addBrandsWatcher() {
  yield takeLatest(BrandsTypes.POST_BRANDS_ADD_REQUEST, addBrands);
}

export function* editBrandsWatcher() {
  yield takeLatest(BrandsTypes.PUT_BRANDS_EDIT_REQUEST, editBrands);
}

export function* deleteBrandsWatcher() {
  yield takeLatest(BrandsTypes.DELETE_BRANDS_DEL_REQUEST, deleteBrands);
}

export default function* rootSaga() {
  yield all([
    
    fork(listBrandsWatcher),
    fork(addBrandsWatcher),
    fork(editBrandsWatcher),
    fork(deleteBrandsWatcher),

  ]);
}
