import React from 'react'
import { Col, Row, Button, Switch, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

//Dispatchs
import { Creators as DiscountGroupActions } from '../../../../store/reducers/discount_group';

//Styles And Helpers

//Contexts

//Components

//Variables
const AddSchema = yup.object().shape({
  name: yup.string()
    .required('A nome do Grupo é obrigatório'),
  discount: yup.string()
    .max(6, 'O desconto inválido, ele deve ser assim Ex: 11.58')
    .typeError('O desconto deve ser um número')
    .test('validar-ponto-flutuante', 'O desconto deve ser um número em %, Ex: 11.58', value => {
      return !isNaN(value) && Number(value) === parseFloat(value);
    })
    .required('A desconto é obrigatório')
});

const Add = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingAdd } = useSelector((state) => (state.discount_group))

  //States Local

  //Context

  //Cicle of Life

  //Functions
  const submitAdd = (values) => {
    const payload = values
    dispatch(DiscountGroupActions.postDiscountGroupAddRequest(payload))
  }

  //Render
  return (
    <>
      <div className='p-2 content-box-form'>
        <Formik
          initialValues={{
            name: '',
            discount: '',
            is_active: true,
            is_free: false,
          }}
          validationSchema={AddSchema}
          onSubmit={(values) => (
            submitAdd(values)
          )
          }
        >
          {({ setFieldValue, handleSubmit, values, errors, touched }) => (
            <>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2 ant-form-item-required' htmlFor="name">Nome do Groupo<b className='text-danger'>*</b></label>
                  <Field name="name" type="text" key={'name'}>
                    {({ field }) => {
                      return <Input
                        className='input-default'
                        value={values.name}
                        {...field}
                        size="Small"
                        placeholder="Nome do Groupo"
                      />
                    }}
                  </Field>
                  {errors.name && touched.name ? <div className='error my-2 px-2'>{errors.name}</div> : null}
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="discount">Máximo de % Desconto<b className='text-danger'>*</b></label>
                  <Field name="discount" type="text" key={'discount'}>
                    {({ field }) => {
                      return <Input
                        className='input-default'
                        value={values.discount}
                        {...field}
                        size="Small"
                        placeholder="Máximo de % Desconto"
                      />
                    }}
                  </Field>
                  {errors.discount && touched.discount ? <div className='error my-2 px-2'>{errors.discount}</div> : null}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="is_active">Inativo/Ativo</label>
                  <div className='my-2'>
                    <Switch
                      onChange={(value) => setFieldValue('is_active', value)}
                      value={values.is_active}
                      checked={values.is_active}
                    />
                  </div>
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="is_free">Desconto Livre</label>
                  <div className='my-2'>
                    <Switch
                      onChange={(value) => setFieldValue('is_free', value)}
                      value={values.is_free}
                      checked={values.is_free}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='my-2' span={24}>
                  <Button
                    className='m-2'
                    disabled={(loadingAdd) ? true : false}
                    loading={(loadingAdd) ? true : false}
                    onClick={() => handleSubmit(values)}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik >
      </div >
    </>
  );
};

export default Add;