export const Types = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',

  RESETPASSWORD_REQUEST: 'RESETPASSWORD_REQUEST',
  RESETPASSWORD_SUCCESS: 'RESETPASSWORD_SUCCESS',
  RESETPASSWORD_FAIL: 'RESETPASSWORD_FAIL',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',

  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  loginErrorData: null,

  dataResetPassword: null,
  loadingResetPassword: false,
  errorResetPassword: false,
  resetErrorDataResetPassword: null,

};

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        loginErrorData: null
      };
    case Types.LOGIN_FAIL:
      return {
        ...state,
        data: null,
        loginErrorData: action.payload,
        loading: false,
        error: true,
      };

    case Types.RESETPASSWORD_REQUEST:
      return {
        ...state,
        loadingResetPassword: true,
        errorResetPassword: false,
      };
    case Types.RESETPASSWORD_SUCCESS:
      return {
        ...state,
        dataResetPassword: action.payload,
        loadingResetPassword: false,
        errorResetPassword: false,
        resetErrorDataResetPassword: null
      };
    case Types.RESETPASSWORD_FAIL:
      return {
        ...state,
        dataResetPassword: null,
        resetErrorDataResetPassword: action.payload,
        loadingResetPassword: false,
        errorResetPassword: true,
      };

    default:
      return state;
  }
}

export const Creators = {
  loginRequest: (payload) => ({
    type: Types.LOGIN_REQUEST,
    payload,
  }),
  loginRequestSuccess: (payload) => ({
    type: Types.LOGIN_SUCCESS,
    payload,
  }),
  loginRequestFail: (payload) => ({
    type: Types.LOGIN_FAIL,
    payload,
  }),

  resetPasswordRequest: (payload) => ({
    type: Types.RESETPASSWORD_REQUEST,
    payload,
  }),
  resetPasswordRequestSuccess: (payload) => ({
    type: Types.RESETPASSWORD_SUCCESS,
    payload,
  }),
  resetPasswordRequestFail: (payload) => ({
    type: Types.RESETPASSWORD_FAIL,
    payload,
  }),

  logoutRequest: (payload) => ({
    type: Types.LOGOUT_REQUEST,
    payload,
  }),

  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
