import React, { useState, useEffect } from 'react'
import { Breadcrumb, Row, Col, Button, Input, Layout, theme, Modal, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { UploadOutlined } from '@ant-design/icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../services/api';
import * as yup from 'yup';
import { toast } from 'react-toastify';

//Dispatchs
import { Creators as UserActions } from '../../store/reducers/user';
import { Creators as AdministratorsActions } from '../../store/reducers/administrators';
import { Creators as RefreshActions } from '../../store/reducers/refresh';

//Styles And Helpers


//Contexts

//Components
import FooterDefault from '../../components/LayoutDefault/FooterDefault'

//Variables
const PasswordSchema = yup.object().shape({
  old_password: yup.string()
    .min(5, 'A senha atual deve ter pelo menos 4 caracteres')
    .required('A senha atual é obrigatória'),
  new_password: yup.string()
    .min(5, 'A nova senha deve ter pelo menos 4 caracteres')
    .required('A nova senha é obrigatória'),
  new_password_confirmation: yup.string()
    .min(5, 'A confirmação senha deve ter pelo menos 4 caracteres')
    .required('A confirmação senha é obrigatória'),
});

const AlterRegisterSchema = yup.object().shape({
  first_name: yup.string()
    .min(2, 'A nome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  last_name: yup.string()
    .min(2, 'A sobrenome deve ter pelo menos 2 caracteres')
    .required('A sobrenome é obrigatória'),
  email: yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
});

const { Content } = Layout;

const Perfil = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataUpdateUserProfile, dataUserProfile, loadingUpdatePassword } = useSelector((state) => (state.user))
  const { loadingEdit } = useSelector((state) => (state.administrators))

  //States Local
  const {
    token: { colorBgContainer, colorInfo },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalAlterPassword, setOpenModalAlterPassword] = useState(false);

  //Context

  //Cicle of Life
  useEffect(() => {
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()

    dispatch(UserActions.getUserProfileRequest())
    mountPhotos()
  }, []);

  useEffect(() => {
    if (dataUpdateUserProfile) {
      dispatch(UserActions.getUserProfileRequest())
    }
  }, [dataUpdateUserProfile]);

  useEffect(() => {
    mountPhotos()
  }, [dataUserProfile]);



  //Functions
  const saveNewPassword = (values) => {
    let payloadList = values
    dispatch(UserActions.postUpdatePasswordRequest(payloadList))
  }

  const updatePefil = (values) => {
    let payloadList = values
    dispatch(AdministratorsActions.putAdministratorsEditRequest(payloadList))
  }

  const showModalAlterPassword = () => {
    setOpenModalAlterPassword(true);
  }

  const hideModalAlterPassword = () => {
    setOpenModalAlterPassword(false);
  }

  const mountPhotos = () => {
    if (dataUserProfile?.avatar_url) {
      setFileListPhotos([
        {
          uid: dataUserProfile?.id,
          name: 'Imagem ' + dataUserProfile?.id,
          status: 'done',
          url: dataUserProfile?.avatar_url,
          thumbUrl: dataUserProfile?.avatar_url,
        }
      ])
    }
  }

  const [fileListPhotos, setFileListPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('avatar', fileListPhotos[0]);

    setUploading(true);
    const id = dataUserProfile?.id
    const token = localStorage.getItem('iguacu:token');
    // You can use any AJAX library you like
    fetch(api.defaults.baseURL + `/admin/${id}/avatar`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        dispatch(UserActions.setUpdateUserProfile(true));
        toast.success("Avatar enviado com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        // console.log('upload successfully.');
      })
      .catch(() => {
        // console.log('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const removePhoto = (id) => {
    const sub_item_group_id = dataUserProfile?.id

    const token = localStorage.getItem('iguacu:token');
    // You can use any AJAX library you like
    fetch(api.defaults.baseURL + `/admin/sub_items/${sub_item_group_id}/photos/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        dispatch(UserActions.setUpdateUserProfile(true));
        // console.log('upload successfully.');
      })
      .catch(() => {
        // console.log('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const propsUpload = {
    multiple: false,
    onRemove: async (file) => {
      const index = fileListPhotos.indexOf(file);
      removePhoto(fileListPhotos[index]?.uid)
      const newFileList = fileListPhotos.slice();
      newFileList.splice(index, 1);
      setFileListPhotos(newFileList);
    },
    beforeUpload: async (file) => {

      const acceptedFormats = ['image/png', 'image/jpeg'];
      const isFormatValid = acceptedFormats.includes(file.type);
      const isSizeValid = file.size / 1024 / 1024 <= 2;

      if (!isFormatValid) {
        toast.error("Por favor, envie apenas arquivos PNG ou JPG!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      if (!isSizeValid) {
        toast.error("O tamanho máximo do arquivo é de 2MB!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      setFileListPhotos([file]);
      return false;
    },
    fileList: fileListPhotos,
  };

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{ title: 'Perfil' }]}
        />
        <div
          className='px-4 py-3'
          style={{
            background: colorBgContainer,
          }}
        >
          {
            dataUserProfile?.id &&
            <div className='container content-box-form'>
              {/* <div className='row justify-content-end'>
                <div className='p-2'>

                </div>
              </div> */}
              <div className='p-2' >
                <Formik
                  initialValues={{
                    id: dataUserProfile?.id,
                    first_name: dataUserProfile?.first_name,
                    last_name: dataUserProfile?.last_name,
                    email: dataUserProfile?.email,
                  }}
                  validationSchema={AlterRegisterSchema}
                  onSubmit={(values) => {
                    updatePefil(values)
                  }
                  }
                >
                  {({ handleSubmit, values, errors, touched }) => (
                    <div>
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <div className='my-2'>
                            <label className='p-2' htmlFor="first_name">Nome</label>
                            <Field name="first_name" type="text" key={'first_name'}>
                              {({ field }) => {
                                return <Input
                                  className='input-default'
                                  value={values.first_name}
                                  {...field}
                                  size="large"
                                  placeholder="Nome"
                                />
                              }}
                            </Field>
                            {errors.first_name && touched.first_name ? <div className='error my-2 px-2'>{errors.first_name}</div> : null}
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className='my-2'>
                            <label className='p-2' htmlFor="last_name">Sobrenome</label>
                            <Field name="last_name" type="text" key={'last_name'}>
                              {({ field }) => {
                                return <Input
                                  className='input-default'
                                  value={values.last_name}
                                  {...field}
                                  size="large"
                                  placeholder="Sobrenome"
                                />
                              }}
                            </Field>
                            {errors.last_name && touched.last_name ? <div className='error my-2 px-2'>{errors.last_name}</div> : null}
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className='my-2'>
                            <label className='p-2' htmlFor="email">Email</label>
                            <Field name="email" type="text" key={'email'}>
                              {({ field }) => {
                                return <Input
                                  className='input-default'
                                  value={values.email}
                                  {...field}
                                  size="large"
                                  placeholder="Email"
                                />
                              }}
                            </Field>
                            {errors.email && touched.email ? <div className='error my-2 px-2'>{errors.email}</div> : null}
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]}>
                        <Col span={8} className='my-2' >
                          <label className='p-2'>Avatar</label>
                          <div className='my-2'>
                            <Upload listType="picture-card" {...propsUpload}>
                              <Button icon={<UploadOutlined />}></Button>
                            </Upload>
                            <Button
                              type="primary"
                              size={'small'}
                              onClick={handleUpload}
                              loading={uploading}
                              style={{
                                margin: 3,
                              }}
                            >
                              {uploading ? 'Uploading' : 'Enviar Imagem'}
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div className='container'>
                            <div className='row justify-content-end'>
                              <div className=''>
                                <Button
                                  size='small'
                                  className='m-2'
                                  disabled={(loadingEdit) ? true : false}
                                  loading={(loadingEdit) ? true : false}
                                  onClick={() => handleSubmit(values)}
                                  type="primary"
                                >
                                  Atualizar Cadastro
                                </Button>
                                <Button
                                  size='small'
                                  className='m-2'
                                  type="primary"
                                  style={{ background: colorInfo }}
                                  onClick={showModalAlterPassword}
                                >
                                  Alterar Senha
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Alterar Senha"
          open={openModalAlterPassword}
          footer={null}
          onCancel={hideModalAlterPassword}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                old_password: '',
                new_password: '',
                new_password_confirmation: '',
              }}
              validationSchema={PasswordSchema}
              onSubmit={(values) => {
                saveNewPassword(values)
              }
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='p-2' htmlFor="old_password">Senha Atual</label>
                      <Field name="old_password" type="text" key={'old_password'}>
                        {({ field }) => {
                          return <Input
                            className='input-default'
                            value={values.old_password}
                            {...field}
                            size="large"
                            placeholder="Senha Atual"
                          />
                        }}
                      </Field>
                      {errors.old_password && touched.old_password ? <div className='error my-2 px-2'>{errors.old_password}</div> : null}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='p-2' htmlFor="new_password">Nova Senha</label>
                      <Field name="new_password" type="text" key={'new_password'}>
                        {({ field }) => {
                          return <Input
                            className='input-default'
                            value={values.new_password}
                            {...field}
                            size="large"
                            placeholder="Nova Senha"
                          />
                        }}
                      </Field>
                      {errors.new_password && touched.new_password ? <div className='error my-2 px-2'>{errors.new_password}</div> : null}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='p-2' htmlFor="new_password_confirmation">Confirmação Nova Senha</label>
                      <Field name="new_password_confirmation" type="text" key={'new_password_confirmation'}>
                        {({ field }) => {
                          return <Input
                            className='input-default'
                            value={values.new_password_confirmation}
                            {...field}
                            size="large"
                            placeholder="Confirmação Nova Senha"
                          />
                        }}
                      </Field>
                      {errors.new_password_confirmation && touched.new_password_confirmation ? <div className='error my-2 px-2'>{errors.new_password_confirmation}</div> : null}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div className='container'>
                        <div className='row justify-content-end'>
                          <div className='p-2'>
                            <Button
                              size='small'
                              className='m-2'
                              onClick={() => hideModalAlterPassword()}
                            >
                              Cancelar
                            </Button>
                            <Button
                              size='small'
                              className='m-2'
                              disabled={(loadingUpdatePassword) ? true : false}
                              loading={(loadingUpdatePassword) ? true : false}
                              onClick={() => handleSubmit(values)}
                              type="primary"
                            >
                              Alterar Senha
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>
        <FooterDefault />
      </Content>
    </>
  );
};

export default Perfil;