export const Types = {
  //Get - List
  GET_EMPLOYEES_LIST_REQUEST: 'GET_EMPLOYEES_LIST_REQUEST',
  GET_EMPLOYEES_LIST_SUCCESS: 'GET_EMPLOYEES_LIST_SUCCESS',
  GET_EMPLOYEES_LIST_FAIL: 'GET_EMPLOYEES_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_EMPLOYEES_LIST: 'SET_UPDATE_EMPLOYEES_LIST',

  //Update - Edit
  PUT_EMPLOYEES_EDIT_REQUEST: 'PUT_EMPLOYEES_EDIT_REQUEST',
  PUT_EMPLOYEES_EDIT_SUCCESS: 'PUT_EMPLOYEES_EDIT_SUCCESS',
  PUT_EMPLOYEES_EDIT_FAIL: 'PUT_EMPLOYEES_EDIT_FAIL',

  //Delete
  DELETE_EMPLOYEES_DEL_REQUEST: 'DELETE_EMPLOYEES_DEL_REQUEST',
  DELETE_EMPLOYEES_DEL_SUCCESS: 'DELETE_EMPLOYEES_DEL_SUCCESS',
  DELETE_EMPLOYEES_DEL_FAIL: 'DELETE_EMPLOYEES_DEL_FAIL',

  //Send Recover Password
  FORGUETPASSWORD_REQUEST: 'FORGUETPASSWORD_REQUEST',
  FORGUETPASSWORD_SUCCESS: 'FORGUETPASSWORD_SUCCESS',
  FORGUETPASSWORD_FAIL: 'FORGUETPASSWORD_FAIL',

  //CLear 
  CLEAR_FORGUETPASSWORD_REQUEST: 'CLEAR_FORGUETPASSWORD_REQUEST',
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {
  dataList: [],
  loadingList: false,
  errorList: false,
  errorDataList: false,
  dataListUpdate: false,

  loadingEdit: false,
  errorEdit: false,
  errorDataEdit: false,

  loadingDelete: false,
  errorDelete: false,
  errorDataDelete: false,

  dataForgetPassword: null,
  loadingForgetPassword: false,
  errorForgetPassword: false,
  forgetErrorDataForgetPassword: null,
};

export default function employees(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Get - List
    case Types.GET_EMPLOYEES_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorList: false,
      };
    case Types.GET_EMPLOYEES_LIST_SUCCESS:
      return {
        ...state,
        dataList: action.payload,
        loadingList: false,
        errorList: false,
        errorDataList: false
      };
    case Types.GET_EMPLOYEES_LIST_FAIL:
      return {
        ...state,
        dataList: false,
        loadingList: false,
        errorList: true,
        errorDataList: action.payload,
      };

    case Types.SET_UPDATE_EMPLOYEES_LIST:
      return {
        ...state,
        dataListUpdate: action.payload,
      };

    //Update - Edit
    case Types.PUT_EMPLOYEES_EDIT_REQUEST:
      return {
        ...state,
        loadingEdit: true,
        errorEdit: false,
      };
    case Types.PUT_EMPLOYEES_EDIT_SUCCESS:
      return {
        ...state,
        loadingEdit: false,
        errorEdit: false,
        errorDataEdit: false,
      };
    case Types.PUT_EMPLOYEES_EDIT_FAIL:
      return {
        ...state,
        loadingEdit: false,
        errorEdit: true,
        errorDataEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_EMPLOYEES_DEL_REQUEST:
      return {
        ...state,
        loadingDelete: true,
        errorDelete: false,
      };
    case Types.DELETE_EMPLOYEES_DEL_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: false,
        errorDataDelete: false,
      };
    case Types.DELETE_EMPLOYEES_DEL_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: true,
        errorDataDelete: action.payload,
      };

    //Get - Send Recover Password
    case Types.FORGUETPASSWORD_REQUEST:
      return {
        ...state,
        loadingForgetPassword: true,
        errorForgetPassword: false,
      };
    case Types.FORGUETPASSWORD_SUCCESS:
      return {
        ...state,
        dataForgetPassword: action.payload,
        loadingForgetPassword: false,
        errorForgetPassword: false,
        forgetErrorDataForgetPassword: null
      };
    case Types.FORGUETPASSWORD_FAIL:
      return {
        ...state,
        dataForgetPassword: null,
        forgetErrorDataForgetPassword: action.payload,
        loadingForgetPassword: false,
        errorForgetPassword: true,
      };

    //Clear Forget Password
    case Types.CLEAR_FORGUETPASSWORD_REQUEST:
      return {
        ...state,
        dataForgetPassword: null,
        loadingForgetPassword: false,
        errorForgetPassword: false,
        forgetErrorDataForgetPassword: null,

      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        dataList: false,
        loadingList: false,
        errorList: false,
        errorDataList: false,
        dataListUpdate: false,

        loadingEdit: false,
        errorEdit: false,
        errorDataEdit: false,

        loadingDelete: false,
        errorDelete: false,
        errorDataDelete: false,

        dataForgetPassword: null,
        loadingForgetPassword: false,
        errorForgetPassword: false,
        forgetErrorDataForgetPassword: null,

      };

    default:
      return state;
  }
}

export const Creators = {

  //Get - List
  getEmployeesListRequest: (payload) => ({
    type: Types.GET_EMPLOYEES_LIST_REQUEST,
    payload,
  }),
  getEmployeesListRequestSuccess: (payload) => ({
    type: Types.GET_EMPLOYEES_LIST_SUCCESS,
    payload,
  }),
  getEmployeesListRequestFail: (payload) => ({
    type: Types.GET_EMPLOYEES_LIST_FAIL,
    payload,
  }),

  setUpdateEmployees: (payload) => ({
    type: Types.SET_UPDATE_EMPLOYEES_LIST,
    payload,
  }),

  //Update - Edit 
  putEmployeesEditRequest: (payload) => ({
    type: Types.PUT_EMPLOYEES_EDIT_REQUEST,
    payload,
  }),
  putEmployeesEditRequestSuccess: () => ({
    type: Types.PUT_EMPLOYEES_EDIT_SUCCESS,
  }),
  putEmployeesEditRequestFail: (payload) => ({
    type: Types.PUT_EMPLOYEES_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteEmployeesDelRequest: (payload) => ({
    type: Types.DELETE_EMPLOYEES_DEL_REQUEST,
    payload,
  }),
  deleteEmployeesDelRequestSuccess: () => ({
    type: Types.DELETE_EMPLOYEES_DEL_SUCCESS,
  }),
  deleteEmployeesDelRequestFail: (payload) => ({
    type: Types.DELETE_EMPLOYEES_DEL_FAIL,
    payload,
  }),

  //Send Recover Passaword
  forguetPasswordRequest: (payload) => ({
    type: Types.FORGUETPASSWORD_REQUEST,
    payload,
  }),
  forguetPasswordRequestSuccess: (payload) => ({
    type: Types.FORGUETPASSWORD_SUCCESS,
    payload,
  }),
  forguetPasswordRequestFail: (payload) => ({
    type: Types.FORGUETPASSWORD_FAIL,
    payload,
  }),


  //Clear
  clearForguetPasswordRequest: () => ({
    type: Types.CLEAR_FORGUETPASSWORD_REQUEST
  }),

  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
