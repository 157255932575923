import React, { useState, useContext, useEffect } from 'react'
import { Breadcrumb, Row, Col, Button, Input, Layout, theme, Modal, Space, Table, Drawer, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import axios from 'axios';
// import api from '../../../services/api';
// import { toast } from 'react-toastify';

import {
  ArrowLeftOutlined,
  CloseOutlined,
  LoadingOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as ProductsActions } from '../../../store/reducers/products'
import { Creators as RefreshActions } from '../../../store/reducers/refresh';

//Styles And Helpers
import { converterforReal } from '../../../helpers'

//Contexts
import { ProductsContext } from '../../../contexts/ProductsContext';

//Components
import FooterDefault from '../../../components/LayoutDefault/FooterDefault'
import Show from './actions/Show'
import Groups from './actions/Groups';

//Variables
const filterListSchema = yup.object().shape({

});

const { Content } = Layout;

const Products = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataProductsList, loadingProductsList, dataProductsListUpdate } = useSelector((state) => (state.products))

  //States Local
  const {
    token: { colorBgContainer, colorWarning, colorError, colorInfo },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalFilter, setOpenModalFilter] = useState(false);

  const title = 'Config. Base'

  const [filters, setFilters] = useState({
    serie: '',
    price: '',
    model_code: '',
    model_name: '',
    basecode: '',
    machine_type_name: '',
  }
  );

  //Context
  const {
    productsContext,
    updateModal
  } = useContext(ProductsContext)

  //Cicle of Life
  useEffect(() => {
    document.title = 'Config. Base - Iguaçu Máquinas';
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  useEffect(() => {
    updateDataList('1')
  }, []);

  useEffect(() => {
    if (dataProductsListUpdate) {
      updateDataList('1')
    }
  }, [dataProductsListUpdate]);

  //Functions
  const updateDataList = (selectedPage) => {
    let payloadList = {
      page: selectedPage,
      isSugestions: 0,
      serie: filters.serie,
      price: filters.price,
      basecode: filters.basecode,
      model_code: filters.model_code,
      model_name: filters.model_name,
      machine_type_name: filters.machine_type_name,
    }
    dispatch(ProductsActions.getProductsListRequest(payloadList))
  }

  //---Filter
  const updateFilter = () => {
    updateDataList(1)
    setOpenModalFilter(false)
  }

  const setFieldFilter = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const resetFilters = () => {
    setFilters({
      serie: '',
      isSugestions: 0,
      price: '',
      model_code: '',
      model_name: '',
      basecode: '',
      machine_type_name: '',
    });
    let payloadList = {
      page: '1',
      isSugestions: 0,
      serie: '',
      price: '',
      model_code: '',
      model_name: '',
      basecode: '',
      machine_type_name: '',
    }
    dispatch(ProductsActions.getProductsListRequest(payloadList))
  }

  const showModalFilter = () => {
    setOpenModalFilter(true);
  }
  const hideModalFilter = () => {
    setOpenModalFilter(false);
  }

  // const [file, setFile] = useState(null);

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };


  // const downloadCSV = async () => {
  //   try {
  //     const token = localStorage.getItem('iguacu:token');
  //     const response = await axios.get(`${api.defaults.baseURL}/admin/products/export/csv`, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //       responseType: 'blob', // Importante definir responseType como 'blob' para receber um arquivo binário
  //     });

  //     // Cria uma URL temporária para o arquivo blob
  //     const blob = new Blob([response.data], { type: 'text/csv' });
  //     const url = window.URL.createObjectURL(blob);

  //     // Cria um link para iniciar automaticamente o download
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = 'products.csv';
  //     link.click();

  //     // Limpa a URL temporária e o link
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     toast.error("Erro ao baixar o arquivo", {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });

  //   }
  // };

  // const uploadCSV = async () => {

  //   if (!file) {
  //     toast.error("Selecione um arquivo CSV para fazer o upload.", {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });
  //     return;
  //   }

  //   const token = localStorage.getItem('iguacu:token');
  //   const formData = new FormData();
  //   formData.append('csv_file', file);

  //   try {
  //     const response = await axios.post(`${api.defaults.baseURL}/admin/products/upload/csv`, formData, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'multipart/form-data'
  //       },
  //     });

  //     toast.success("Upload feito com sucesso. Todos os dados foram processados e atualizados.", {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });

  //   } catch (error) {
  //     toast.error("Erro ao subir o arquivo", {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });
  //   }

  // };

  //---Configurations Columns Table
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.id}
        </div>
      ,
    },
    {
      title: 'Marca',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.model?.machine_type?.brand?.brand}
        </div>
      ,
    },
    {
      title: 'Cód. Grupo Máq.',
      dataIndex: 'machine_type',
      key: 'machine_type',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.model?.machine_type?.code}
        </div>
      ,
    },
    {
      title: 'Cód. Modelo',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.model?.code}
        </div>
      ,
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      key: 'modelo',
      render: (text, record, i) =>
        <div className='row px-2'>
          <b>{record?.model?.name}</b>
        </div>
      ,
    },
    {
      title: 'Série',
      dataIndex: 'serie',
      key: 'serie',
      render: (text, record, i) =>
        <div className='row px-2'>
          <b>{record?.serie}</b>
        </div>
      ,
    },
    {
      title: 'Basecode',
      dataIndex: 'basecode',
      key: 'basecode',
      render: (text, record, i) =>
        <div className='row px-2'>
          <b>{record?.basecode}</b>
        </div>
      ,
    },

    {
      title: 'Preço Custo',
      dataIndex: 'price',
      key: 'price',
      render: (text, record, i) =>
        <>
          <div className='row px-2'>
            <b>{converterforReal(record?.recomended_price || 0.00)}</b>
          </div>
        </>
      ,
    },
    {
      title: 'Ativo',
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      render: (text, record, i) =>
        <div >
          {(record?.deleted_at === null) ?
            <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
            :
            <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
          }
        </div>
      ,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        <Space direction={'vertical'} size="middle" >
          <Button
            size='small'
            type="primary"
            style={{ background: colorWarning }}
            onClick={() =>
              updateModal(
                'show',
                {
                  status: true,
                  title: `Detalhes ${title}`,
                  data: record,
                }
              )
            }
          >
            Detalhes
          </Button>
          <Button
            className=''
            size='small'
            onClick={() => {
              updateModal(
                'groups',
                {
                  status: true,
                  title: `Detalhes ${title}`,
                  data: {
                    GroupsId: record?.id
                  },
                }
              )
              updateModal(
                'show',
                {
                  status: false,
                  title: `Detalhes`,
                  data: record,
                }
              )
            }
            }
            type="primary"
            style={{ backgroundColor: colorInfo }}
          >
            Opcionais
          </Button>
        </Space >
      ),
    },
  ];

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{
            title: 'Máquinas',
          },
          { title: title }]}
        />
        <div
          className='px-4 py-3'
          style={{ background: colorBgContainer }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={showModalFilter}
                >
                  Adicionar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    resetFilters()
                  }}
                  style={{ background: colorError }}
                  type='primary'
                >
                  Limpar Filtros
                </Button>
              </div>
              {/* <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    downloadCSV()
                  }}
                  style={{ background: colorInfo }}
                  type='primary'
                >
                  Download Produtos .csv
                </Button>

                <div>
                  <h1>Upload de Arquivo .CSV</h1>
                  <input type="file" accept=".csv" onChange={handleFileChange} />
                  <button onClick={uploadCSV}>Enviar Arquivo CSV</button>
                </div>
              </div> */}
            </div>
          </div>
          <div className='table-content'>
            <Table pagination={false} loading={(loadingProductsList) && { indicator: <LoadingOutlined /> }} rowKey="id" columns={columns} dataSource={dataProductsList?.data} />
          </div>
          {
            dataProductsList?.last_page > 1 &&
            <div className='m-3 text-right'>
              <Pagination
                pageSize={dataProductsList?.per_page}
                showSizeChanger={false}
                showTotal={(total, range) => `${total} itens`}
                onChange={(e) => updateDataList(e)}
                current={dataProductsList?.current_page}
                defaultPageSize={`${dataProductsList?.per_page}`}
                total={dataProductsList?.total}
              />
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Filtros"
          open={openModalFilter}
          footer={null}
          onCancel={hideModalFilter}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                serie: filters?.serie,
                price: filters?.price,
                basecode: filters?.basecode,
                model_code: filters?.model_code,
                model_name: filters?.model_name,
                machine_type_name: filters?.machine_type_name,
              }}
              validationSchema={filterListSchema}
              onSubmit={() => (
                updateFilter()
              )
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="machine_type_name">Cód. Grupo de Máq.</label>
                      <Input
                        className='input-default'
                        value={filters.machine_type_name}
                        onChange={(e) => setFieldFilter('machine_type_name', e.target.value)}
                        size="large"
                        placeholder="Cód. Grupo de Máq."
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="basecode">Code Base</label>
                      <Input
                        className='input-default'
                        value={filters.basecode}
                        onChange={(e) => setFieldFilter('basecode', e.target.value)}
                        size="large"
                        placeholder="Code Base"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>

                    <Col span={12}>
                      <label className='p-2' htmlFor="model_code">Cód. Modelo</label>
                      <Input
                        className='input-default'
                        value={filters.model_code}
                        onChange={(e) => setFieldFilter('model_code', e.target.value)}
                        size="large"
                        placeholder="Cód. Modelo"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="model_name">Modelo</label>
                      <Input
                        className='input-default'
                        value={filters.model_name}
                        onChange={(e) => setFieldFilter('model_name', e.target.value)}
                        size="large"
                        placeholder="Modelo"
                      />
                    </Col>
                  </Row>
                 
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="serie">Serie</label>
                      <Input
                        className='input-default'
                        value={filters.serie}
                        onChange={(e) => setFieldFilter('serie', e.target.value)}
                        size="large"
                        placeholder="Série"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="price">Preço Base</label>
                      <Input
                        className='input-default'
                        value={filters.price}
                        onChange={(e) => setFieldFilter('price', e.target.value)}
                        size="large"
                        placeholder="Preço"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        size='small'
                        className='m-2'
                        disabled={(loadingProductsList) ? true : false}
                        loading={(loadingProductsList) ? true : false}
                        onClick={() => handleSubmit(values)}
                        type="primary"
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>

        {/* Modal Actions Show */}
        <Drawer
          title={'Detalhes'}
          className="drawer-products-show"
          placement={'right'}
          closable={true}
          onClose={() => updateModal(
            'show',
            {
              status: false,
              title: `Detalhes ${title}`,
              data: {},
            }
          )}
          open={productsContext?.show.status}
          key={'show'}
          closeIcon={<CloseOutlined />}
          width={'80%'}
        >
          {
            (productsContext?.show.status) &&
            <Show data={productsContext?.show?.data} ></Show>
          }
        </Drawer>

        {/* Modal Adicionar Grupo de Opcionais */}
        <Drawer
          title={'Adicionar Grupo de Opcionais'}
          className="drawer-products-opcionais"
          placement={'right'}
          closable={true}
          onClose={() => {
            updateModal(
              'groups',
              {
                status: false,
                title: `Detalhes ${title}`,
                data: {},
              }
            )
            updateModal(
              'show',
              {
                status: false,
                title: `Detalhes ${title}`,
                data: {},
              }
            )
          }}
          open={productsContext?.groups.status}
          key={'group'}
          closeIcon={<ArrowLeftOutlined />}
          width={'70%'}
        >
          <>
            {
              (productsContext?.groups.status) &&
              <Groups />
            }
          </>
        </Drawer>
        <FooterDefault />
      </Content>
    </>
  );
};

export default Products;