import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Input, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify'; 

//Dispatchs
import { Creators as RefreshActions } from '../../../../store/reducers/refresh';
import { convertDateBR, converterforReal } from '../../../../helpers';
import api from '../../../../services/api';

//Styles And Helpers

//Contexts

//Components

//Variables

const Show = ({ dataShow }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer

  //States Local
  const [dataSubitem, setDataSubitem] = useState(null);
  const [loader, setLoader] = useState(false);

  //Context

  //Cicle of Life
  useEffect(() => {
    fetchSubitem(dataShow?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mountPhotos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubitem])

  //Functions
  async function fetchSubitem(id) {

    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/sub_items/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setDataSubitem(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }


  const mountPhotos = () => {
    let photos = []

    if (dataSubitem?.photos && dataSubitem?.photos.length > 0) {
      (dataSubitem?.photos && dataSubitem?.photos.map((item, i) => {
        photos.push(
          {
            uid: item?.id,
            name: 'Imagem ' + item?.id,
            status: 'done',
            url: item?.path,
            thumbUrl: item?.path,
          }
        )
        return true
      }))
    }
    setFileListPhotos(photos)
    return true
  }

  const [fileListPhotos, setFileListPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    let dataTrat = fileListPhotos;

    if (dataSubitem?.photos.length > 0) {
      for (let index = 0; index < dataSubitem?.photos.length - 1; index++) {
        dataTrat.splice(index, 1)
      }
    }

    dataTrat.forEach((file) => {
      formData.append('photos[]', file);
    })
    setUploading(true);
    const id = dataShow?.id
    const token = localStorage.getItem('iguacu:token');
    // You can use any AJAX library you like
    fetch(api.defaults.baseURL + `/admin/sub_items/${id}/files`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        fetchSubitem(id)
        toast.success("Enviado com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        // console.log('upload successfully.');
      })
      .catch(() => {
        // console.log('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const removePhoto = (id) => {
    const sub_item_group_id = dataShow?.id

    const token = localStorage.getItem('iguacu:token');
    // You can use any AJAX library you like
    fetch(api.defaults.baseURL + `/admin/sub_items/${sub_item_group_id}/photos/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        fetchSubitem(dataShow?.id)

        // console.log('upload successfully.');
      })
      .catch(() => {
        // console.log('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const propsUpload = {
    multiple: true,
    onRemove: async (file) => {
      const index = fileListPhotos.indexOf(file);
      removePhoto(fileListPhotos[index]?.uid)
      const newFileList = fileListPhotos.slice();
      newFileList.splice(index, 1);
      setFileListPhotos(newFileList);
    },
    beforeUpload: async (file) => {

      const acceptedFormats = ['image/png', 'image/jpeg'];
      const isFormatValid = acceptedFormats.includes(file.type);
      const isSizeValid = file.size / 1024 / 1024 <= 2;

      if (!isFormatValid) {
        toast.error("Por favor, envie apenas arquivos PNG ou JPG!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      if (!isSizeValid) {
        toast.error("O tamanho máximo do arquivo é de 2MB!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      setFileListPhotos(prevFileList => [...prevFileList, file]);
      return false;
    },
    fileList: fileListPhotos,
  };

  //Render
  return (
    <>
      <div className='p-3 content-box-form'>
        {
          loader && <LoadingOutlined />
        }
        {
          dataSubitem &&
          <Formik
            initialValues={{
              name: dataSubitem?.name,
              code: dataSubitem?.code,
              sub_item_group_id: dataSubitem?.sub_item_group_id,
              group: dataSubitem?.group?.name,
              price: dataSubitem?.price,
              // description: dataSubitem?.description,
              deleted_at: dataSubitem?.deleted_at,
            }}
          >
            {({ values }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="name">Opcional</label>
                    <Input
                      className='input-default'
                      value={values.name}
                      size="Small"
                      placeholder="Nome"
                    />
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="name">Código</label>
                    <Input
                      className='input-default'
                      value={values.code}
                      size="Small"
                      placeholder="Código"
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="name">Grupo</label>
                    <Input
                      className='input-default'
                      value={values.group}
                      size="Small"
                      placeholder="Grupo"
                    />
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="price">Preço</label>
                    <Input
                      className='input-default'
                      value={converterforReal(values.price)}
                      size="Small"
                      placeholder="Preço"
                    />
                  </Col>
                </Row>
                {/* <Row gutter={[16, 16]}>
                  <Col className='my-2' span={24}>
                    <label className='px-2 ant-form-item-required' htmlFor="description">Descrição</label>
                    <TextArea
                      className='input-default'
                      disabled
                      rows={4}
                      value={values.description}
                      placeholder="Mensagem"
                    />
                  </Col>
                </Row> */}

                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={24}>
                    <h3 className='my-3'>Fotos</h3>
                    <div>
                      <div >
                        <Upload listType="picture-card" {...propsUpload}>
                          <Button icon={<UploadOutlined />}></Button>
                        </Upload>
                        <Button
                          type="primary"
                          size={'small'}
                          onClick={handleUpload}
                          disabled={fileListPhotos.length === 0}
                          loading={uploading}
                          style={{
                            margin: 3,
                          }}
                        >
                          {uploading ? 'Uploading' : 'Enviar Imagem'}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>

                {
                  values.deleted_at &&
                  <Row gutter={[16, 16]}>
                    <Col className='my-2' span={12}>
                      <label className='px-2 ant-form-item-required' htmlFor="deleted_at">Deletado Em:</label>
                      <Input
                        className='input-default'
                        value={convertDateBR(values.deleted_at)}
                        size="Small"
                        placeholder="Deletado em:"
                      />
                    </Col>
                  </Row>
                }
              </>
            )}
          </Formik >
        }
      </div >
    </>
  );
};

export default Show;