import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Switch, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import api from '../../../services/api';

//Dispatchs
import { Creators as FreightActions } from '../../../store/reducers/freight';
import { Creators as RefreshActions } from '../../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components

//Variables
const EditSchema = yup.object().shape({
  filial: yup.string()
    .required('A filial é obrigatória'),
  fees_boarding: yup.string()
    .typeError('A Taxa de Embarque deve ser um número, utilize ponto(.) no lugar de virgula(,). Exemplo: 12345.45')
    .test('validar-ponto-flutuante', 'A Taxa de Embarque deve ser um número, utilize ponto(.) no lugar de virgula(,). Exemplo: 12345.45', value => {
      const regex = /^\d+(\.\d{1,2})?$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('A taxa de embarque é obrigatório'),
  freight: yup.string()
    .typeError('O Frete deve ser um número, utilize ponto(.) no lugar de virgula(,). Exemplo: 12345.45')
    .test('validar-ponto-flutuante', 'O Frete deve ser um número, utilize ponto(.) no lugar de virgula(,). Exemplo: 12345.45', value => {
      const regex = /^\d+(\.\d{1,2})?$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('O frete é obrigatório'),
});

const Edit = ({ dataEdit }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingEdit } = useSelector((state) => (state.freight))

  //States Local
  const [dataFreight, setDataFreight] = useState(null);

  //Context

  //Cicle of Life
  useEffect(() => {
    fetchGetFreight()
  }, []);


  //Functions
  async function fetchGetFreight() {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/freight/${dataEdit?.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setDataFreight(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  const submitEdit = (values) => {
    const payload = values
    dispatch(FreightActions.putFreightEditRequest(payload))
  }

  const [loader, setLoader] = useState(null);

  //Render
  return (
    <>
      <div className='p-2 content-box-form'>
        {loader &&
          <div className='p-2'>
            <LoadingOutlined />
          </div>
        }
        {
          dataFreight?.id &&
          <Formik
            initialValues={{
              id: dataFreight?.id,
              filial: dataFreight?.filial,
              city: dataFreight?.city,
              freight: dataFreight?.freight,
              fees_boarding: dataFreight?.fees_boarding,
              is_active: (dataFreight?.is_active === 0) ? false : true,
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => (
              submitEdit(values)
            )
            }
          >
            {({ setFieldValue, handleSubmit, values, errors, touched }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="name">Filial<b className='text-danger'>*</b></label>
                    <Field name="filial" type="text" key={'filial'}>
                      {({ field }) => {
                        return <Input
                          readOnly
                          className='input-default'
                          value={values.filial}
                          {...field}
                          size="Small"
                          placeholder="Filial"
                        />
                      }}
                    </Field>
                    {errors.filial && touched.filial ? <div className='error my-2 px-2'>{errors.filial}</div> : null}
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="city">Cidade<b className='text-danger'>*</b></label>
                    <Field name="city" type="text" key={'city'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.city}
                          {...field}
                          size="Small"
                          placeholder="Cidade"
                        />
                      }}
                    </Field>
                    {errors.city && touched.city ? <div className='error my-2 px-2'>{errors.city}</div> : null}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="freight">Frete<b className='text-danger'>*</b></label>
                    <Field name="freight" type="text" key={'freight'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.freight}
                          {...field}
                          size="Small"
                          placeholder="Preço"
                        />
                      }}
                    </Field>
                    {errors.freight && touched.freight ? <div className='error my-2 px-2'>{errors.freight}</div> : null}
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="fees_boarding">Taxa de Embarque<b className='text-danger'>*</b></label>
                    <Field name="fees_boarding" type="text" key={'fees_boarding'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.fees_boarding}
                          {...field}
                          size="Small"
                          placeholder="Preço"
                        />
                      }}
                    </Field>
                    {errors.fees_boarding && touched.fees_boarding ? <div className='error my-2 px-2'>{errors.fees_boarding}</div> : null}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="is_active">Inativo/Ativo</label>
                    <div className='my-2'>
                      <Switch
                        onChange={(value) => setFieldValue('is_active', value)}
                        value={values.is_active}
                        checked={values.is_active}
                      />
                    </div>
                  </Col>

                </Row>
                <Row>
                  <Col className='my-2' span={24}>
                    <Button
                      className='m-2'
                      disabled={(loadingEdit) ? true : false}
                      loading={(loadingEdit) ? true : false}
                      onClick={() => handleSubmit(values)}
                      type="primary"
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Formik >
        }
      </div >
    </>
  );
};

export default Edit;