import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import {
  Creators as ProductModelsActions,
  Types as ProductModels
} from '../../reducers/product_models'

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

//PRODUCT MODELS
function* listProductModels({ payload }) {
  console.log('payload', payload)
  try {
    const { data } = yield call(
      api.get,
      `/admin/product_models?page=${payload?.page}
        ${payload?.code && `&code=${payload?.code}`}
        ${payload?.name && `&name=${payload?.name}`}
        ${payload?.machineType && `&machine_type%5Bname%5D%3D=${payload?.machineType}`}
        ${payload?.machineTypeCode && `&machine_type%5Bcode%5D%3D=${payload?.machineTypeCode}`}`,
    );
    yield put(ProductModelsActions.setUpdateProductModels(false));
    yield put(ProductModelsActions.getProductModelsListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductModelsActions.getProductModelsListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addProductModels({ payload }) {
  try {
    yield call(api.post, '/admin/product_models', payload);
    yield put(ProductModelsActions.setUpdateProductModels(true));
    yield put(ProductModelsActions.postProductModelsAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductModelsActions.postProductModelsAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editProductModels({ payload }) {
  try {
    yield call(api.put, `/admin/product_models/${payload?.id}`, payload);
    yield put(ProductModelsActions.setUpdateProductModels(true));
    yield put(ProductModelsActions.putProductModelsEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductModelsActions.putProductModelsEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteProductModels({ payload }) {
  try {
    yield call(api.delete, `/admin/product_models/${payload?.id}`);
    yield put(ProductModelsActions.setUpdateProductModels(true));
    yield put(ProductModelsActions.postProductModelsAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductModelsActions.deleteProductModelsDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* uploadImageProductModels({ payload }) {
  try {
    yield call(api.post, `/admin/product_models/${payload?.id}/photo`, payload?.formData);
    yield put(ProductModelsActions.setUpdateProductModels(true));
    yield put(ProductModelsActions.postProductModelsUploadImageRequestSuccess());
    toast.success("Imagem salva com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductModelsActions.postProductModelsUploadImageRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteImageProductModels({ payload }) {
  try {
    yield call(api.delete, `/admin/product_models/${payload?.id}/photo`, payload?.formData);
    yield put(ProductModelsActions.setUpdateProductModels(true));
    yield put(ProductModelsActions.deleteProductModelsDelImageRequestSuccess());
    toast.success("Imagem deletada com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductModelsActions.deleteProductModelsDelImageRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

//PRODUCT MODELS
export function* listProductModelsWatcher() {
  yield takeLatest(ProductModels.GET_PRODUCTMODELS_LIST_REQUEST, listProductModels);
}

export function* addProductModelsWatcher() {
  yield takeLatest(ProductModels.POST_PRODUCTMODELS_ADD_REQUEST, addProductModels);
}

export function* editProductModelsWatcher() {
  yield takeLatest(ProductModels.PUT_PRODUCTMODELS_EDIT_REQUEST, editProductModels);
}

export function* deleteProductModelsWatcher() {
  yield takeLatest(ProductModels.DELETE_PRODUCTMODELS_DEL_REQUEST, deleteProductModels);
}

export function* uploadImageProductModelsWatcher() {
  yield takeLatest(ProductModels.POST_PRODUCTMODELS_UPLOADIMAGE_REQUEST, uploadImageProductModels);
}

export function* deleteImageProductModelsWatcher() {
  yield takeLatest(ProductModels.DELETE_PRODUCTMODELS_DELIMAGE_REQUEST, deleteImageProductModels);
}

export default function* rootSaga() {
  yield all([

    fork(listProductModelsWatcher),
    fork(addProductModelsWatcher),
    fork(editProductModelsWatcher),
    fork(deleteProductModelsWatcher),
    fork(uploadImageProductModelsWatcher),
    fork(deleteImageProductModelsWatcher),

  ]);
}
