import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../../services/api';

import {
  Creators as RefreshActions,
  Types as RefreshTypes,
} from '../../reducers/refresh';

async function saveToken({ token }) {
  await AsyncStorage.multiSet([
    ['iguacu:token', token],
    ['iguacu:refresh', token],
  ]);
}

function* refresh({ payload }) {
  try {

    const {
      status,
      data,
      data: { new_access_token, profile },
    } = yield call(api.post, '/admin/renew_token', payload);
    
    if (status === 200 && profile?.is_active) {
      yield call(saveToken, {
        token: new_access_token,
      });
      yield put(RefreshActions.getRefreshSuccess({ data }));
    } else {
      yield put(RefreshActions.getRefreshRequestFail('Usuário inativo!'));
    }
  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(RefreshActions.getRefreshRequestFail());
    }
  }
}

export function* refreshWatcher() {
  yield takeLatest(RefreshTypes.GET_REFRESH_REQUEST, refresh);
}

export default function* rootSaga() {
  yield all([
    fork(refreshWatcher),
  ]);
}
