import React, { useState, useEffect} from 'react'
import { Breadcrumb, Row, Col, Button, Input, Layout, theme, Modal, Space, Table, Drawer, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  CloseOutlined,
  LoadingOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as BrandsActions } from '../../../store/reducers/brands';
import { Creators as RefreshActions } from '../../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components
import FooterDefault from '../../../components/LayoutDefault/FooterDefault'
import Show from './actions/Show'

//Variables
const filterListSchema = yup.object().shape({
  email: yup.string()
    .min(2, 'O campo deve conter no mínimo 2 caractéres'),
});
const { Content } = Layout;

const Brands = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataBrandsList, loadingBrandsList, dataBrandsListUpdate } = useSelector((state) => (state.brands))

  //States Local
  const {
    token: { colorBgContainer, colorWarning, colorError, colorInfo },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const title = 'Marcas'
  const [openTypeContentDrawer, setOpenTypeContentDrawer] = useState({
    details: {
      status: true,
      title: `Detalhes ${title}`,
      dataShow: {}
    }
  });
  const [filters, setFilters] = useState({
    code: '',
    brand: '',
    fabric: ''
  }
  );

  //Context

  //Cicle of Life
  useEffect(() => {
    document.title = 'Marcas - Iguaçu Máquinas';
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  useEffect(() => {
    updateDataList('1')
  }, []);

  useEffect(() => {
    if (dataBrandsListUpdate) {
      updateDataList('1')
    }
  }, [dataBrandsListUpdate]);


  //Functions
  const updateDataList = (selectedPage) => {
    let payloadList = {
      page: selectedPage,
      code: filters.code,
      brand: filters.brand,
      fabric: filters.fabric,
    }
    // console.log('payloadList', payloadList)
    dispatch(BrandsActions.getBrandsListRequest(payloadList))
  }

  //---Filter
  const updateFilter = () => {
    updateDataList(1)
    setOpenModalFilter(false)
  }

  const setFieldFilter = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const resetFilters = () => {
    setFilters({
      code: '',
      brand: '',
      fabric: ''
    });
    let payloadList = {
      page: '1',
      code: '',
      brand: '',
      fabric: ''
    }
    dispatch(BrandsActions.getBrandsListRequest(payloadList))
  }

  const showModalFilter = () => {
    setOpenModalFilter(true);
  }
  const hideModalFilter = () => {
    setOpenModalFilter(false);
  }

  //---Drawer Actions Adicionar || Deletar
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setOpenTypeContentDrawer({
      details: {
        status: false,
        title: `Detalhes ${title}`,
        dataShow: {}
      }
    })
  };

  const handleChangeTypeContentDrawer = (name, dataShow = {}) => {
    if (name === 'detail') {
      setOpenTypeContentDrawer({
        details: {
          status: true,
          title: `Detalhes ${title}`,
          dataShow: dataShow
        }
      })
    }
    showDrawer()
  }

  const getTitle = () => {
    if (openTypeContentDrawer?.details?.status) {
      return openTypeContentDrawer?.details?.title
    }
  }

  //---Configurations Columns Table
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.recno}
        </div>
      ,
    },
    {
      title: 'Cód. Marca',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.code}
        </div>
      ,
    },
    {
      title: 'Marca',
      dataIndex: 'brand',
      key: 'brand',
      render: (text, record, i) =>
        <div className='row px-2'>
          <b>{record?.brand}</b>
        </div>
      ,
    },
    {
      title: 'Fábrica',
      dataIndex: 'fabric',
      key: 'fabric',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.fabric}
        </div>
      ,
    },
    {
      title: 'Ativo',
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      render: (text, record, i) =>
        <div >
          {(record?.deleted_at === null) ?
            <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
            :
            <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
          }
        </div>
      ,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        < Space size="middle" >
          <Button
            size='small' type="primary" style={{ background: colorWarning }}
            onClick={() => handleChangeTypeContentDrawer('detail', record)}
          >
            Detalhes
          </Button>
        </Space >
      ),
    },
  ];

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{
            title: 'Máquinas',
          },
          { title: title }]}
        />
        {/* <p>{JSON.stringify(dataBrandsList?.data[0])}</p> */}
        <div
          className='px-4 py-3'
          style={{ background: colorBgContainer }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={showModalFilter}
                >
                  Adicionar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    resetFilters()
                  }}
                  style={{ background: colorError }}
                  type='primary'
                >
                  Limpar Filtros
                </Button>
              </div>
              <div className='p-2'>
              </div>
            </div>
          </div>
          <div className='table-content'>
            <Table pagination={false} loading={(loadingBrandsList) && { indicator: <LoadingOutlined /> }} rowKey="id" columns={columns} dataSource={dataBrandsList?.data} />
          </div>
          {
            dataBrandsList?.last_page > 1 &&
            <div className='m-3 text-right'>
              <Pagination
                pageSize={dataBrandsList?.per_page}
                showSizeChanger={false}
                showTotal={(total, range) => `${total} itens`}
                onChange={(e) => updateDataList(e)}
                current={dataBrandsList?.current_page}
                defaultPageSize={`${dataBrandsList?.per_page}`}
                total={dataBrandsList?.total}
              />
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Filtros"
          open={openModalFilter}
          footer={null}
          onCancel={hideModalFilter}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                code: filters?.code,
                brand: filters?.brand,
                fabric: filters?.fabric
              }}
              validationSchema={filterListSchema}
              onSubmit={() => (
                updateFilter()
              )
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="code">Cód. Marca</label>
                      <Input
                        className='input-default'
                        value={filters.code}
                        onChange={(e) => setFieldFilter('code', e.target.value)}
                        size="large"
                        placeholder="Cód. Marca"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="brand">Marca</label>
                      <Input
                        className='input-default'
                        value={filters.brand}
                        onChange={(e) => setFieldFilter('brand', e.target.value)}
                        size="large"
                        placeholder="Marca"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="fabric">Fábrica</label>
                      <Input
                        className='input-default'
                        value={filters.fabric}
                        onChange={(e) => setFieldFilter('fabric', e.target.value)}
                        size="large"
                        placeholder="Fábrica"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        size='small'
                        className='m-2'
                        disabled={(loadingBrandsList) ? true : false}
                        loading={(loadingBrandsList) ? true : false}
                        onClick={() => handleSubmit(values)}
                        type="primary"
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>

        {/* Modal Actions Show */}
        <Drawer
          title={getTitle()}
          className="drawer-brands-show"
          placement={'right'}
          closable={true}
          onClose={onCloseDrawer}
          open={openDrawer}
          key={'right'}
          closeIcon={<CloseOutlined />}
          width={'30%'}
        >
          <>
            {
              (openTypeContentDrawer?.details.status) &&
              <>
                <Show dataShow={openTypeContentDrawer?.details?.dataShow}></Show>
              </>
            }
          </>
        </Drawer>
        <FooterDefault />
      </Content>
    </>
  );
};

export default Brands;