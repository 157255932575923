import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Switch, Input, theme, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';

import {
  CloseOutlined,
} from '@ant-design/icons';

import * as yup from 'yup';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { format } from 'date-fns';

//Dispatchs
import { Creators as MessagesActions } from '../../../store/reducers/messages';

//Styles And Helpers

//Contexts

//Components
const { TextArea } = Input;

//Variables
const EditSchema = yup.object().shape({
  title: yup.string()
    .min(3, 'O título deve ter pelo menos 3 caracteres')
    .required('A título é obrigatória'),
  message: yup.string()
    .min(3, 'A mensagem deve ter pelo menos 3 caracteres')
    .required('A mensagem é obrigatória'),
});

const options = [
  {
    label: 'Popup',
    value: 'popup',
  },
  {
    label: 'Fixo',
    value: 'fix',
  },
];



const Edit = ({ dataEdit }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingEdit } = useSelector((state) => (state.messages))

  //States Local
  const {
    token: { colorError },
  } = theme.useToken();
  const [startDate, setStartDate] = useState((dataEdit?.start_date) ? ajustarData(dataEdit?.start_date, 1, "adicionar") : "");
  const [endDate, setEndDate] = useState((dataEdit?.end_date) ? ajustarData(dataEdit?.end_date, 1, "adicionar") : "");

  //Context

  //Cicle of Life
  useEffect(() => {

  }, []);

  //Functions

  function ajustarData(data, dias, operacao) {
    const dataNova = new Date(data);
    if (operacao === "adicionar") {
      dataNova.setDate(dataNova.getDate() + dias);
    } else if (operacao === "subtrair") {
      dataNova.setDate(dataNova.getDate() - dias);
    } else {
      throw new Error("Operação não suportada. Use 'adicionar' ou 'subtrair'.");
    }
    return dataNova;
  }

  const submitEdit = (values) => {
    const payload = {
      ...values,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate)
    }
    // console.log('payload', payload)
    dispatch(MessagesActions.putMessagesEditRequest(payload))
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const formatDate = (date) => {
    return date ? format(date, 'yyyy-MM-dd') : '';
  };

  function CustomDatePickerInputInit({ value, onClick }) {
    return (
      <input
        className='input-default'
        type="text"
        onClick={onClick}
        value={value}
        placeholder="Data Inicial"
        style={{
          padding: '6px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          border: '1px solid #d3d3d3',
        }}
      />
    );
  }

  function CustomDatePickerInputFinal({ value, onClick }) {
    return (
      <input
        className='input-default'
        type="text"
        onClick={onClick}
        value={value}
        placeholder="Data Final"
        style={{
          padding: '6px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          border: '1px solid #d3d3d3',
        }}
      />
    );
  }

  const clearStartDate = () => {
    setStartDate(null);
  };

  const clearEndDate = () => {
    setEndDate(null);
  };

  //Render
  return (
    <>
      <div className='p-2 content-box-form'>
        <Formik
          initialValues={{
            id: dataEdit?.id,
            title: dataEdit?.title,
            message: dataEdit?.message,
            is_active: (dataEdit?.is_active === 0) ? false : true,
            type: (dataEdit?.type === 'popup') ? 'popup' : 'fix',
          }}
          validationSchema={EditSchema}
          onSubmit={(values) => (
            submitEdit(values)
          )
          }
        >
          {({ setFieldValue, handleSubmit, values, errors, touched }) => (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label className='px-2 ant-form-item-required' htmlFor="title">Título</label>
                  <Field name="title" type="text" key={'title'}>
                    {({ field }) => {
                      return <Input
                        className='input-default'
                        value={values.title}
                        {...field}
                        size="large"
                        placeholder="Titulo"
                      />
                    }}
                  </Field>
                  {errors.title && touched.title ? <div className='error my-2 px-2'>{errors.title}</div> : null}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <label className='px-2' htmlFor="message">Mensagem</label>
                  <div className='py-2'>
                    <Field name="message" type="text" key={'message'}>
                      {({ field }) => {
                        return <TextArea
                          {...field}
                          rows={4}
                          value={values.message}
                          placeholder="Mensagem"
                        />
                      }}
                    </Field>
                  </div>
                  {errors.message && touched.message ? <div className='error my-2 px-2'>{errors.message}</div> : null}
                </Col>
              </Row>
              <Row>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="type">Tipo</label>
                  <div className='my-2'>
                    <Field name="type">
                      {({ field }) => (
                        <Radio.Group
                          options={options}
                          {...field}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      )}
                    </Field>
                  </div>
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="is_active">Inativo/Ativo</label>
                  <div className='my-2'>
                    <Switch
                      onChange={(value) => setFieldValue('is_active', value)}
                      value={values.is_active}
                      checked={values.is_active}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <p className='p-2'>Período em que o a mensagem ficará mostrando.</p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label className='p-2' htmlFor="type">Data Inicial</label>
                  <DatePicker
                    customInput={<CustomDatePickerInputInit />} // Usa o componente personalizado // Usa o input personalizado
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Data de Início"
                    locale="pt-BR" // Define o locale para português do Brasil
                    dateFormat="dd/MM/yyyy"
                  />
                  {
                    (startDate) &&
                    <Button
                      size='small' className='mx-2' type="primary" style={{ background: colorError }}
                      onClick={clearStartDate}
                    >
                      <CloseOutlined />
                    </Button>
                  }
                </Col>
                <Col span={12}>
                  <label className='p-2' htmlFor="type">Data Final</label>

                  <DatePicker
                    customInput={<CustomDatePickerInputFinal />} // Usa o componente personalizado // Usa o input personalizado
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Data de Término"
                    locale="pt-BR" // Define o locale para português do Brasil
                    dateFormat="dd/MM/yyyy"
                  />
                  {
                    (endDate) &&
                    <Button
                      size='small' className='mx-2' type="primary" style={{ background: colorError }}
                      onClick={clearEndDate}
                    >
                      <CloseOutlined />
                    </Button>
                  }
                </Col>
              </Row>
              <Row>
                <Col className='my-2' span={24}>
                  <Button
                    className='m-2'
                    disabled={(loadingEdit) ? true : false}
                    loading={(loadingEdit) ? true : false}
                    onClick={() => handleSubmit(values)}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik >
      </div >
    </>
  );
};

export default Edit;