import React, { useState, useEffect } from 'react'
import { Breadcrumb, Row, Col, Button, Input, Layout, theme, Modal, Space, Table, Drawer, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  CloseOutlined,
  LoadingOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as GroupsActions } from '../../../store/reducers/groups';
import { Creators as RefreshActions } from '../../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components
import FooterDefault from '../../../components/LayoutDefault/FooterDefault'
import Show from './actions/Show'

//Variables
const filterListSchema = yup.object().shape({
  email: yup.string()
    .min(2, 'O campo deve conter no mínimo 2 caractéres'),
});
const { Content } = Layout;

const Groups = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataList, loadingList, dataListUpdate } = useSelector((state) => (state.groups))

  //States Local
  const {
    token: { colorBgContainer, colorWarning, colorError, colorInfo },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const title = 'Grupos'
  const [openTypeContentDrawer, setOpenTypeContentDrawer] = useState({
    details: {
      status: true,
      title: `Detalhes ${title}`,
      dataShow: {}
    }
  });
  const [filters, setFilters] = useState({
    name: '',
    code: ''
  }
  );

  //Context

  //Cicle of Life
  useEffect(() => {
    document.title = 'Grupos de Opcionais - Iguaçu Máquinas';
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  useEffect(() => {
    updateDataList('1')
  }, []);

  useEffect(() => {
    if (dataListUpdate) {
      updateDataList('1')
    }
  }, [dataListUpdate]);


  //Functions
  const updateDataList = (selectedPage) => {
    let payloadList = {
      page: selectedPage,
      name: filters.name,
      code: filters.code,
    }
    // console.log('payloadList', payloadList)
    dispatch(GroupsActions.getGroupsListRequest(payloadList))
  }

  //---Filter
  const updateFilter = () => {
    updateDataList(1)
    setOpenModalFilter(false)
  }

  const setFieldFilter = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const resetFilters = () => {
    setFilters({
      name: '',
      code:''
    });
    let payloadList = {
      page: '1',
      name: '',
      code:''
    }
    // console.log('payloadList', payloadList)
    dispatch(GroupsActions.getGroupsListRequest(payloadList))
  }

  const showModalFilter = () => {
    setOpenModalFilter(true);
  }
  const hideModalFilter = () => {
    setOpenModalFilter(false);
  }

  //---Drawer Actions Adicionar || Deletar
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setOpenTypeContentDrawer({
      details: {
        status: false,
        title: `Detalhes ${title}`,
        dataShow: {}
      }
    })
  };

  const handleChangeTypeContentDrawer = (name, dataShow = {}) => {
    if (name === 'detail') {
      setOpenTypeContentDrawer({
        details: {
          status: true,
          title: `Detalhes ${title}`,
          dataShow: dataShow
        }
      })
    }
    showDrawer()
  }

  const getTitle = () => {
    if (openTypeContentDrawer?.details?.status) {
      return openTypeContentDrawer?.details?.title
    }
  }

  //---Configurations Columns Table
  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.code}
        </div>
      ,
    },
    {
      title: 'Grupo',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.name}
        </div>
      ,
    },
    {
      title: 'Ativo',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text, record, i) =>
        <div >
          {(record?.deleted_at === null) ?
            <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
            :
            <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
          }
        </div>
      ,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        < Space size="middle" >
          <Button
            size='small' type="primary" style={{ background: colorWarning }}
            onClick={() => handleChangeTypeContentDrawer('detail', record)}
          >
            Detalhes
          </Button>
        </Space >
      ),
    },
  ];

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{
            title: 'Opcionais',
          },
          { title: title }]}
        />
        <div
          className='px-4 py-3'
          style={{
            background: colorBgContainer,
          }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={showModalFilter}
                >
                  Adicionar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    resetFilters()
                  }}
                  style={{
                    background: colorError,
                  }}
                  type='primary'
                >
                  Limpar Filtros
                </Button>
              </div>
              <div className='p-2'>
              </div>
            </div>
          </div>
          <div className='table-content'>
            <Table pagination={false} loading={(loadingList) && { indicator: <LoadingOutlined /> }} rowKey="id" columns={columns} dataSource={dataList?.data} />
          </div>
          {
            dataList?.last_page > 1 &&
            <div className='m-3 text-right'>
              <Pagination
                pageSize={dataList?.per_page}
                showSizeChanger={false}
                showTotal={(total, range) => `${total} itens`}
                onChange={(e) => updateDataList(e)}
                current={dataList?.current_page}
                defaultPageSize={`${dataList?.per_page}`}
                total={dataList?.total}
              />
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Filtros"
          open={openModalFilter}
          footer={null}
          onCancel={hideModalFilter}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                name: filters?.name,
                code: filters?.code
              }}
              validationSchema={filterListSchema}
              onSubmit={() => (
                updateFilter()
              )
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="code">Código</label>
                      <Input
                        className='input-default'
                        value={filters.code}
                        onChange={(e) => setFieldFilter('code', e.target.value)}
                        size="large"
                        placeholder="Código"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="name">Grupo</label>
                      <Input
                        className='input-default'
                        value={filters.name}
                        onChange={(e) => setFieldFilter('name', e.target.value)}
                        size="large"
                        placeholder="Grupo"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        size='small'
                        className='m-2'
                        disabled={(loadingList) ? true : false}
                        loading={(loadingList) ? true : false}
                        onClick={() => handleSubmit(values)}
                        type="primary"
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>

        {/* Modal Actions Show */}
        <Drawer
          title={getTitle()}
          className="drawer-groups-opcionais"
          placement={'right'}
          closable={true}
          onClose={onCloseDrawer}
          open={openDrawer}
          key={'right'}
          closeIcon={<CloseOutlined />}
          width={'45%'}
        >
          <>
            {
              (openTypeContentDrawer?.details.status) &&
              <>
                <Show dataShow={openTypeContentDrawer?.details?.dataShow}></Show>
              </>
            }
          </>
        </Drawer>
        <FooterDefault />
      </Content>
    </>
  );
};

export default Groups;