import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as CustomersActions,
  Types as CustomersTypes,
} from '../../reducers/customers';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listCustomers({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/customers?page=${payload?.page}&social_reason_or_name=${payload?.social_reason_or_name}&type=${payload?.type}&doc=${payload?.doc}&email=${payload?.email}`
    );
    yield put(CustomersActions.setUpdateCustomers(false));
    yield put(CustomersActions.getCustomersListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(CustomersActions.getCustomersListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addCustomers({ payload }) {
  try {
    yield call(api.post, '/admin/customers', payload);
    yield put(CustomersActions.setUpdateCustomers(true));
    yield put(CustomersActions.postCustomersAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(CustomersActions.postCustomersAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

  }
}

function* editCustomers({ payload }) {
  try {
    yield call(api.put, `/admin/customers/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(CustomersActions.setUpdateCustomers(true));
    yield put(CustomersActions.putCustomersEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(CustomersActions.putCustomersEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

  }
}

function* deleteCustomers({ payload }) {
  try {
    yield call(api.delete, `/admin/customers/${payload?.id}`);
    // console.log('data store', data)
    yield put(CustomersActions.setUpdateCustomers(true));
    yield put(CustomersActions.postCustomersAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(CustomersActions.deleteCustomersDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listCustomersWatcher() {
  yield takeLatest(CustomersTypes.GET_CUSTOMERS_LIST_REQUEST, listCustomers);
}

export function* addCustomersWatcher() {
  yield takeLatest(CustomersTypes.POST_CUSTOMERS_ADD_REQUEST, addCustomers);
}

export function* editCustomersWatcher() {
  yield takeLatest(CustomersTypes.PUT_CUSTOMERS_EDIT_REQUEST, editCustomers);
}

export function* deleteCustomersWatcher() {
  yield takeLatest(CustomersTypes.DELETE_CUSTOMERS_DEL_REQUEST, deleteCustomers);
}

export default function* rootSaga() {
  yield all([
    fork(listCustomersWatcher),
    fork(addCustomersWatcher),
    fork(editCustomersWatcher),
    fork(deleteCustomersWatcher),
  ]);
}
