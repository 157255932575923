export const convertDateBR = (valor) => {
  // Verificando se o valor é vazio ou inválido
  if (!valor || typeof valor !== 'string') {
    return 'Valor inválido';
  }

  // Separando a data e a hora
  const [dataPart, horaPart] = valor.split(' ');

  // Verificando se a data e a hora foram separadas corretamente
  if (!dataPart && !horaPart) {
    return 'Valor inválido';
  }

  // Verificando se a hora está vazia, caso contrário, converte a data para formato brasileiro
  if (!horaPart) {
    // Separando o ano, mês e dia da data
    const [ano, mes, dia] = dataPart.split('-');

    // Verificando se o ano, mês e dia são vazios ou inválidos
    if (!ano || !mes || !dia) {
      return 'Data inválida';
    }

    // Construindo a data no formato brasileiro
    const dataBrasileira = `${dia}/${mes}/${ano}`;

    // Retornando a data formatada em formato brasileiro
    return dataBrasileira;
  }

  // Verificando se a data está vazia, caso contrário, converte a hora para formato brasileiro
  if (!dataPart) {
    // Separando a hora, minutos e segundos da hora
    const [hora, minutos, segundos] = horaPart.split(':');

    // Verificando se a hora, minutos e segundos são vazios ou inválidos
    if (!hora || !minutos || !segundos) {
      return 'Hora inválida';
    }

    // Construindo a hora no formato brasileiro
    const horaBrasileira = `${hora}:${minutos}:${segundos}`;

    // Retornando a hora formatada em formato brasileiro
    return horaBrasileira;
  }

  // Separando o ano, mês e dia da data
  const [ano, mes, dia] = dataPart.split('-');

  // Separando a hora, minutos e segundos da hora
  const [hora, minutos, segundos] = horaPart.split(':');

  // Construindo a data e a hora no formato brasileiro
  const dataHoraBrasileira = `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;

  // Retornando a data e a hora formatadas em formato brasileiro
  return dataHoraBrasileira;
}

export const converterforReal = (value) => {
  if (!value) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);

  return `${amount}`;
}

