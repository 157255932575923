import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Input, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

//Dispatchs
import { Creators as MachineTypesActions } from '../../../../store/reducers/machine_types';
import { Creators as RefreshActions } from '../../../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components

//Variables
const EditSchema = yup.object().shape({
  first_name: yup.string()
    .min(2, 'A nome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  last_name: yup.string()
    .min(2, 'A sobrenome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  email: yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
});

const Edit = ({ dataShow }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer

  //States Local
  const [dataMachineTypes, setDataMachineTypes] = useState(null);

  //Context

  //Cicle of Life
  useEffect(() => {
    fetchGetMachineTypes()
    mountPhotos()
  }, []);

  useEffect(() => {
    mountPhotos()
  }, [dataMachineTypes?.photo_url]);

  //Functions
  async function fetchGetMachineTypes() {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/machine_types/${dataShow?.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setDataMachineTypes(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  const submitEdit = (values) => {
    const payload = values
    dispatch(MachineTypesActions.putEmployeesEditRequest(payload))
  }

  const mountPhotos = () => {
    if (dataMachineTypes?.photo_url) {
      setFileListPhotos([
        {
          uid: dataMachineTypes?.id,
          name: 'Imagem ' + dataMachineTypes?.id,
          status: 'done',
          url: dataMachineTypes?.photo_url,
          thumbUrl: dataMachineTypes?.photo_url,
        }
      ])
    }
  }

  const [loader, setLoader] = useState(null);
  const [fileListPhotos, setFileListPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('photos[]', fileListPhotos[0]);

    setUploading(true);
    const id = dataMachineTypes?.id
    const token = localStorage.getItem('iguacu:token');

    fetch(api.defaults.baseURL + `/admin/machine_types/${id}/photo`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        fetchGetMachineTypes()
        toast.success("Avatar enviado com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(() => {
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const removePhoto = () => {
    const machine_type_id = dataShow?.id

    const token = localStorage.getItem('iguacu:token');
    // You can use any AJAX library you like
    fetch(api.defaults.baseURL + `/admin/machine_types/${machine_type_id}/photo`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        fetchGetMachineTypes()
        // console.log('upload successfully.');
      })
      .catch(() => {
        // console.log('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const propsUpload = {
    listType: "picture-card",
    multiple: false,
    onRemove: async (file) => {
      const index = fileListPhotos.indexOf(file);
      removePhoto()
      const newFileList = fileListPhotos.slice();
      newFileList.splice(index, 1);
      setFileListPhotos(newFileList);
    },
    beforeUpload: async (file) => {

      const acceptedFormats = ['image/png', 'image/jpeg'];
      const isFormatValid = acceptedFormats.includes(file.type);
      const isSizeValid = file.size / 1024 / 1024 <= 2;

      if (!isFormatValid) {
        toast.error("Por favor, envie apenas arquivos PNG ou JPG!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      if (!isSizeValid) {
        toast.error("O tamanho máximo do arquivo é de 2MB!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      setFileListPhotos([file]);
      return false;
    },
    fileList: fileListPhotos,
  };

  //Render
  return (
    <>
      <div className='p-2 content-box-form'>
        {loader &&
          <div className='p-2'>
            <LoadingOutlined />
          </div>
        }
        {
          dataMachineTypes?.id &&
          <Formik
            initialValues={{
              name: dataShow?.name,
              code: dataShow?.code,
              brand: dataShow?.brand?.brand,
              deleted_at: dataShow?.deleted_at,
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => (
              submitEdit(values)
            )
            }
          >
            {({ setFieldValue, handleSubmit, values, errors, touched }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="code">Cód. Grupo Máq.</label>
                    <Input
                      className='input-default'
                      value={values.code}
                      size="Small"
                      placeholder="Cód. Grupo Máq."
                    />
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="code">Nome</label>
                    <Input
                      className='input-default'
                      value={values.name}
                      size="Small"
                      placeholder="Nome"
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={24}>
                    <label className='px-2 ant-form-item-required' htmlFor="brand">Marca</label>
                    <Input
                      className='input-default'
                      value={values.brand}
                      size="Small"
                      placeholder="Marca"
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24} className='my-2' >
                    <label className='p-2'>Foto</label>
                    <div className='my-2'>
                      <Upload {...propsUpload}>
                        <Button icon={<UploadOutlined />}></Button>
                      </Upload>
                      <Button
                        type="primary"
                        size={'small'}
                        onClick={handleUpload}
                        loading={uploading}
                        style={{
                          margin: 3,
                        }}
                      >
                        {uploading ? 'Uploading' : 'Enviar Imagem'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Formik >
        }
      </div >
    </>
  );
};

export default Edit;
