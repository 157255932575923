export const Types = {

  // Product ----------
  //Get - List
  GET_PRODUCTS_LIST_REQUEST: 'GET_PRODUCTS_LIST_REQUEST',
  GET_PRODUCTS_LIST_SUCCESS: 'GET_PRODUCTS_LIST_SUCCESS',
  GET_PRODUCTS_LIST_FAIL: 'GET_PRODUCTS_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_PRODUCTS_LIST: 'SET_UPDATE_PRODUCTS_LIST',

  //Store - Add
  POST_PRODUCTS_ADD_REQUEST: 'POST_PRODUCTS_ADD_REQUEST',
  POST_PRODUCTS_ADD_SUCCESS: 'POST_PRODUCTS_ADD_SUCCESS',
  POST_PRODUCTS_ADD_FAIL: 'POST_PRODUCTS_ADD_FAIL',

  //Update - Edit
  PUT_PRODUCTS_EDIT_REQUEST: 'PUT_PRODUCTS_EDIT_REQUEST',
  PUT_PRODUCTS_EDIT_SUCCESS: 'PUT_PRODUCTS_EDIT_SUCCESS',
  PUT_PRODUCTS_EDIT_FAIL: 'PUT_PRODUCTS_EDIT_FAIL',

  //Delete
  DELETE_PRODUCTS_DEL_REQUEST: 'DELETE_PRODUCTS_DEL_REQUEST',
  DELETE_PRODUCTS_DEL_SUCCESS: 'DELETE_PRODUCTS_DEL_SUCCESS',
  DELETE_PRODUCTS_DEL_FAIL: 'DELETE_PRODUCTS_DEL_FAIL',

  //Upload Image 
  POST_PRODUCTS_UPLOADIMAGE_REQUEST: 'POST_PRODUCTS_UPLOADIMAGE_REQUEST',
  POST_PRODUCTS_UPLOADIMAGE_SUCCESS: 'POST_PRODUCTS_UPLOADIMAGE_SUCCESS',
  POST_PRODUCTS_UPLOADIMAGE_FAIL: 'POST_PRODUCTS_UPLOADIMAGE_FAIL',

  //Delete Image 
  DELETE_PRODUCTS_DELIMAGE_REQUEST: 'DELETE_PRODUCTS_DELIMAGE_REQUEST',
  DELETE_PRODUCTS_DELIMAGE_SUCCESS: 'DELETE_PRODUCTS_DELIMAGE_SUCCESS',
  DELETE_PRODUCTS_DELIMAGE_FAIL: 'DELETE_PRODUCTS_DELIMAGE_FAIL',

  //SUBITENS 
  // PUT_PRODUCTS_SUBITENS_VISIBLE_REQUEST: 'PUT_PRODUCTS_SUBITENS_VISIBLE_REQUEST',
  // PUT_PRODUCTS_SUBITENS_VISIBLE_SUCCESS: 'PUT_PRODUCTS_SUBITENS_VISIBLE_SUCCESS',
  // PUT_PRODUCTS_SUBITENS_VISIBLE_FAIL: 'PUT_PRODUCTS_SUBITENS_VISIBLE_FAIL',


  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {

  // Products ----------
  dataProductsList: [],
  loadingProductsList: false,
  errorProductsList: false,
  errorDataProductsList: false,
  dataProductsListUpdate: false,

  loadingProductsAdd: false,
  errorProductsAdd: false,
  errorDataProductsAdd: false,

  loadingProductsEdit: false,
  errorProductsEdit: false,
  errorDataProductsEdit: false,

  loadingProductsDelete: false,
  errorProductsDelete: false,
  errorDataProductsDelete: false,

  successProductsUploadImage: false,
  loadingProductsUploadImage: false,
  errorProductsUploadImage: false,
  errorDataProductsUploadImage: false,

  successProductsDelImage: false,
  loadingProductsDelImage: false,
  errorProductsDelImage: false,
  errorDataProductsDelImage: false,

  // Products Subitens ----------
  // loadingProductsSubitensVisible: false,
  // errorProductsSubitensVisible: false,
  // errorDataProductsSubitensVisible: false,

};

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {

    // PRODUCT MODELS ----------
    //Get - List
    case Types.GET_PRODUCTS_LIST_REQUEST:
      return {
        ...state,
        loadingProductsList: true,
        errorProductsList: false,
      };
    case Types.GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        dataProductsList: action.payload,
        loadingProductsList: false,
        errorProductsList: false,
        errorDataProductsList: false
      };
    case Types.GET_PRODUCTS_LIST_FAIL:
      return {
        ...state,
        dataProductsList: false,
        loadingProductsList: false,
        errorProductsList: true,
        errorDataProductsList: action.payload,
      };

    case Types.SET_UPDATE_PRODUCTS_LIST:
      return {
        ...state,
        dataProductsListUpdate: action.payload,
      };

    //Store - Add
    case Types.POST_PRODUCTS_ADD_REQUEST:
      return {
        ...state,
        loadingProductsAdd: true,
        errorProductsAdd: false,
      };
    case Types.POST_PRODUCTS_ADD_SUCCESS:
      return {
        ...state,
        loadingProductsAdd: false,
        errorProductsAdd: false,
        errorDataProductsAdd: false,
      };
    case Types.POST_PRODUCTS_ADD_FAIL:
      return {
        ...state,
        loadingProductsAdd: false,
        errorProductsAdd: true,
        errorDataProductsAdd: action.payload,
      };

    //Update - Edit
    case Types.PUT_PRODUCTS_EDIT_REQUEST:
      return {
        ...state,
        loadingProductsEdit: true,
        errorProductsEdit: false,
      };
    case Types.PUT_PRODUCTS_EDIT_SUCCESS:
      return {
        ...state,
        loadingProductsEdit: false,
        errorProductsEdit: false,
        errorDataProductsEdit: false,
      };
    case Types.PUT_PRODUCTS_EDIT_FAIL:
      return {
        ...state,
        loadingProductsEdit: false,
        errorProductsEdit: true,
        errorDataProductsEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_PRODUCTS_DEL_REQUEST:
      return {
        ...state,
        loadingProductsDelete: true,
        errorProductsDelete: false,
      };
    case Types.DELETE_PRODUCTS_DEL_SUCCESS:
      return {
        ...state,
        loadingProductsDelete: false,
        errorProductsDelete: false,
        errorDataProductsDelete: false,
      };
    case Types.DELETE_PRODUCTS_DEL_FAIL:
      return {
        ...state,
        loadingProductsDelete: false,
        errorProductsDelete: true,
        errorDataProductsDelete: action.payload,
      };

    //Upload Images 
    case Types.POST_PRODUCTS_UPLOADIMAGE_REQUEST:
      return {
        ...state,
        loadingProductsUploadImage: true,
        errorProductsUploadImage: false,
      };
    case Types.POST_PRODUCTS_UPLOADIMAGE_SUCCESS:
      return {
        ...state,
        successProductsUploadImage: true,
        loadingProductsUploadImage: false,
        errorProductsUploadImage: false,
        errorDataProductsUploadImage: false,
      };
    case Types.POST_PRODUCTS_UPLOADIMAGE_FAIL:
      return {
        ...state,
        successProductsUploadImage: false,
        loadingProductsUploadImage: false,
        errorProductsUploadImage: true,
        errorDataProductsUploadImage: action.payload,
      };

    //Delete Images 
    case Types.DELETE_PRODUCTS_DELIMAGE_REQUEST:
      return {
        ...state,
        loadingProductsDelImage: true,
        errorProductsDelImage: false,
      };
    case Types.DELETE_PRODUCTS_DELIMAGE_SUCCESS:
      return {
        ...state,
        successProductsDelImage: true,
        loadingProductsDelImage: false,
        errorProductsDelImage: false,
        errorDataProductsDelImage: false,
      };
    case Types.DELETE_PRODUCTS_DELIMAGE_FAIL:
      return {
        ...state,
        successProductsDelImage: false,
        loadingProductsDelImage: false,
        errorProductsDelImage: true,
        errorDataProductsDelImage: action.payload,
      };

    //Product Subitens 
    // case Types.PUT_PRODUCTS_SUBITENS_VISIBLE_REQUEST:
    //   return {
    //     ...state,
    //     loadingProductsSubitensVisible: true,
    //     errorProductsSubitensVisible: false,
    //   };
    // case Types.PUT_PRODUCTS_SUBITENS_VISIBLE_SUCCESS:
    //   return {
    //     ...state,
    //     loadingProductsSubitensVisible: false,
    //     errorProductsSubitensVisible: false,
    //     errorDataProductsSubitensVisible: false,
    //   };
    // case Types.PUT_PRODUCTS_SUBITENS_VISIBLE_FAIL:
    //   return {
    //     ...state,
    //     loadingProductsSubitensVisible: false,
    //     errorProductsSubitensVisible: true,
    //     errorDataProductsSubitensVisible: action.payload,
    //   };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        // PRODUCT MODELS ----------
        dataProductsList: [],
        loadingProductsList: false,
        errorProductsList: false,
        errorDataProductsList: false,
        dataProductsListUpdate: false,

        loadingProductsAdd: false,
        errorProductsAdd: false,
        errorDataProductsAdd: false,

        loadingProductsEdit: false,
        errorProductsEdit: false,
        errorDataProductsEdit: false,

        loadingProductsDelete: false,
        errorProductsDelete: false,
        errorDataProductsDelete: false,

        successProductsUploadImage: false,
        loadingProductsUploadImage: false,
        errorProductsUploadImage: false,
        errorDataProductsUploadImage: false,

        successProductsDelImage: false,
        loadingProductsDelImage: false,
        errorProductsDelImage: false,
        errorDataProductsDelImage: false,

        // loadingProductsSubitensVisible: false,
        // errorProductsSubitensVisible: false,
        // errorDataProductsSubitensVisible: false,


      };

    default:
      return state;
  }
}

export const Creators = {

  // PRODUCT ----------
  //Get - List
  getProductsListRequest: (payload) => ({
    type: Types.GET_PRODUCTS_LIST_REQUEST,
    payload,
  }),

  getProductsListRequestSuccess: (payload) => ({
    type: Types.GET_PRODUCTS_LIST_SUCCESS,
    payload,
  }),

  getProductsListRequestFail: (payload) => ({
    type: Types.GET_PRODUCTS_LIST_FAIL,
    payload,
  }),

  setUpdateProducts: (payload) => ({
    type: Types.SET_UPDATE_PRODUCTS_LIST,
    payload,
  }),

  //Store - Add 
  postProductsAddRequest: (payload) => ({
    type: Types.POST_PRODUCTS_ADD_REQUEST,
    payload,
  }),

  postProductsAddRequestSuccess: () => ({
    type: Types.POST_PRODUCTS_ADD_SUCCESS,
  }),

  postProductsAddRequestFail: (payload) => ({
    type: Types.POST_PRODUCTS_ADD_FAIL,
    payload,
  }),

  //Update - Edit 
  putProductsEditRequest: (payload) => ({
    type: Types.PUT_PRODUCTS_EDIT_REQUEST,
    payload,
  }),

  putProductsEditRequestSuccess: () => ({
    type: Types.PUT_PRODUCTS_EDIT_SUCCESS,
  }),

  putProductsEditRequestFail: (payload) => ({
    type: Types.PUT_PRODUCTS_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteProductsDelRequest: (payload) => ({
    type: Types.DELETE_PRODUCTS_DEL_REQUEST,
    payload,
  }),

  deleteProductsDelRequestSuccess: () => ({
    type: Types.DELETE_PRODUCTS_DEL_SUCCESS,
  }),

  deleteProductsDelRequestFail: (payload) => ({
    type: Types.DELETE_PRODUCTS_DEL_FAIL,
    payload,
  }),

  //Upload Image 
  postProductsUploadImageRequest: (payload) => ({
    type: Types.POST_PRODUCTS_UPLOADIMAGE_REQUEST,
    payload,
  }),

  postProductsUploadImageRequestSuccess: () => ({
    type: Types.POST_PRODUCTS_UPLOADIMAGE_SUCCESS,
  }),

  postProductsUploadImageRequestFail: (payload) => ({
    type: Types.POST_PRODUCTS_UPLOADIMAGE_FAIL,
    payload,
  }),

  //Delete Image 
  deleteProductsDelImageRequest: (payload) => ({
    type: Types.DELETE_PRODUCTS_DELIMAGE_REQUEST,
    payload,
  }),

  deleteProductsDelImageRequestSuccess: () => ({
    type: Types.DELETE_PRODUCTS_DELIMAGE_SUCCESS,
  }),

  deleteProductsDelImageRequestFail: (payload) => ({
    type: Types.DELETE_PRODUCTS_DELIMAGE_FAIL,
    payload,
  }),

  // PRODUCT SUBITENS ----------
  // putProductsSubitensVisibleRequest: (payload) => ({
  //   type: Types.PUT_PRODUCTS_SUBITENS_VISIBLE_REQUEST,
  //   payload,
  // }),

  // putProductsSubitensVisibleRequestSuccess: () => ({
  //   type: Types.PUT_PRODUCTS_SUBITENS_VISIBLE_SUCCESS,
  // }),

  // putProductsSubitensVisibleRequestFail: (payload) => ({
  //   type: Types.PUT_PRODUCTS_SUBITENS_VISIBLE_FAIL,
  //   payload,
  // }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
