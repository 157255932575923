export const Types = {

  // DISCOUNT GROUP ----------
  //Get - List
  GET_DISCOUNTGROUP_LIST_REQUEST: 'GET_DISCOUNTGROUP_LIST_REQUEST',
  GET_DISCOUNTGROUP_LIST_SUCCESS: 'GET_DISCOUNTGROUP_LIST_SUCCESS',
  GET_DISCOUNTGROUP_LIST_FAIL: 'GET_DISCOUNTGROUP_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_DISCOUNTGROUP_LIST: 'SET_UPDATE_DISCOUNTGROUP_LIST',

  //Store - Add
  POST_DISCOUNTGROUP_ADD_REQUEST: 'POST_DISCOUNTGROUP_ADD_REQUEST',
  POST_DISCOUNTGROUP_ADD_SUCCESS: 'POST_DISCOUNTGROUP_ADD_SUCCESS',
  POST_DISCOUNTGROUP_ADD_FAIL: 'POST_DISCOUNTGROUP_ADD_FAIL',

  //Update - Edit
  PUT_DISCOUNTGROUP_EDIT_REQUEST: 'PUT_DISCOUNTGROUP_EDIT_REQUEST',
  PUT_DISCOUNTGROUP_EDIT_SUCCESS: 'PUT_DISCOUNTGROUP_EDIT_SUCCESS',
  PUT_DISCOUNTGROUP_EDIT_FAIL: 'PUT_DISCOUNTGROUP_EDIT_FAIL',

  //Delete
  DELETE_DISCOUNTGROUP_DEL_REQUEST: 'DELETE_DISCOUNTGROUP_DEL_REQUEST',
  DELETE_DISCOUNTGROUP_DEL_SUCCESS: 'DELETE_DISCOUNTGROUP_DEL_SUCCESS',
  DELETE_DISCOUNTGROUP_DEL_FAIL: 'DELETE_DISCOUNTGROUP_DEL_FAIL',

  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {

  // DISCOUNT GROUP ----------
  dataDiscountGroupList: [],
  loadingDiscountGroupList: false,
  errorDiscountGroupList: false,
  errorDataDiscountGroupList: false,
  dataDiscountGroupListUpdate: false,

  loadingDiscountGroupAdd: false,
  errorDiscountGroupAdd: false,
  errorDataDiscountGroupAdd: false,

  loadingDiscountGroupEdit: false,
  errorDiscountGroupEdit: false,
  errorDataDiscountGroupEdit: false,

  loadingDiscountGroupDelete: false,
  errorDiscountGroupDelete: false,
  errorDataDiscountGroupDelete: false,

};

export default function discount_group(state = INITIAL_STATE, action) {
  switch (action.type) {

    // DISCOUNT GROUP ----------
    //Get - List
    case Types.GET_DISCOUNTGROUP_LIST_REQUEST:
      return {
        ...state,
        loadingDiscountGroupList: true,
        errorDiscountGroupList: false,
      };
    case Types.GET_DISCOUNTGROUP_LIST_SUCCESS:
      return {
        ...state,
        dataDiscountGroupList: action.payload,
        loadingDiscountGroupList: false,
        errorDiscountGroupList: false,
        errorDataDiscountGroupList: false
      };
    case Types.GET_DISCOUNTGROUP_LIST_FAIL:
      return {
        ...state,
        dataDiscountGroupList: false,
        loadingDiscountGroupList: false,
        errorDiscountGroupList: true,
        errorDataDiscountGroupList: action.payload,
      };

    case Types.SET_UPDATE_DISCOUNTGROUP_LIST:
      return {
        ...state,
        dataDiscountGroupListUpdate: action.payload,
      };

    //Store - Add
    case Types.POST_DISCOUNTGROUP_ADD_REQUEST:
      return {
        ...state,
        loadingDiscountGroupAdd: true,
        errorDiscountGroupAdd: false,
      };
    case Types.POST_DISCOUNTGROUP_ADD_SUCCESS:
      return {
        ...state,
        loadingDiscountGroupAdd: false,
        errorDiscountGroupAdd: false,
        errorDataDiscountGroupAdd: false,
      };
    case Types.POST_DISCOUNTGROUP_ADD_FAIL:
      return {
        ...state,
        loadingDiscountGroupAdd: false,
        errorDiscountGroupAdd: true,
        errorDataDiscountGroupAdd: action.payload,
      };

    //Update - Edit
    case Types.PUT_DISCOUNTGROUP_EDIT_REQUEST:
      return {
        ...state,
        loadingDiscountGroupEdit: true,
        errorDiscountGroupEdit: false,
      };
    case Types.PUT_DISCOUNTGROUP_EDIT_SUCCESS:
      return {
        ...state,
        loadingDiscountGroupEdit: false,
        errorDiscountGroupEdit: false,
        errorDataDiscountGroupEdit: false,
      };
    case Types.PUT_DISCOUNTGROUP_EDIT_FAIL:
      return {
        ...state,
        loadingDiscountGroupEdit: false,
        errorDiscountGroupEdit: true,
        errorDataDiscountGroupEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_DISCOUNTGROUP_DEL_REQUEST:
      return {
        ...state,
        loadingDiscountGroupDelete: true,
        errorDiscountGroupDelete: false,
      };
    case Types.DELETE_DISCOUNTGROUP_DEL_SUCCESS:
      return {
        ...state,
        loadingDiscountGroupDelete: false,
        errorDiscountGroupDelete: false,
        errorDataDiscountGroupDelete: false,
      };
    case Types.DELETE_DISCOUNTGROUP_DEL_FAIL:
      return {
        ...state,
        loadingDiscountGroupDelete: false,
        errorDiscountGroupDelete: true,
        errorDataDiscountGroupDelete: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        // DISCOUNT GROUP ----------
        dataDiscountGroupList: [],
        loadingDiscountGroupList: false,
        errorDiscountGroupList: false,
        errorDataDiscountGroupList: false,
        dataDiscountGroupListUpdate: false,

        loadingDiscountGroupAdd: false,
        errorDiscountGroupAdd: false,
        errorDataDiscountGroupAdd: false,

        loadingDiscountGroupEdit: false,
        errorDiscountGroupEdit: false,
        errorDataDiscountGroupEdit: false,

        loadingDiscountGroupDelete: false,
        errorDiscountGroupDelete: false,
        errorDataDiscountGroupDelete: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  // DICOUNT GROUP ----------
  //Get - List
  getDiscountGroupListRequest: (payload) => ({
    type: Types.GET_DISCOUNTGROUP_LIST_REQUEST,
    payload,
  }),

  getDiscountGroupListRequestSuccess: (payload) => ({
    type: Types.GET_DISCOUNTGROUP_LIST_SUCCESS,
    payload,
  }),

  getDiscountGroupListRequestFail: (payload) => ({
    type: Types.GET_DISCOUNTGROUP_LIST_FAIL,
    payload,
  }),

  setUpdateDiscountGroup: (payload) => ({
    type: Types.SET_UPDATE_DISCOUNTGROUP_LIST,
    payload,
  }),

  //Store - Add 
  postDiscountGroupAddRequest: (payload) => ({
    type: Types.POST_DISCOUNTGROUP_ADD_REQUEST,
    payload,
  }),

  postDiscountGroupAddRequestSuccess: () => ({
    type: Types.POST_DISCOUNTGROUP_ADD_SUCCESS,
  }),

  postDiscountGroupAddRequestFail: (payload) => ({
    type: Types.POST_DISCOUNTGROUP_ADD_FAIL,
    payload,
  }),

  //Update - Edit 
  putDiscountGroupEditRequest: (payload) => ({
    type: Types.PUT_DISCOUNTGROUP_EDIT_REQUEST,
    payload,
  }),

  putDiscountGroupEditRequestSuccess: () => ({
    type: Types.PUT_DISCOUNTGROUP_EDIT_SUCCESS,
  }),

  putDiscountGroupEditRequestFail: (payload) => ({
    type: Types.PUT_DISCOUNTGROUP_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteDiscountGroupDelRequest: (payload) => ({
    type: Types.DELETE_DISCOUNTGROUP_DEL_REQUEST,
    payload,
  }),

  deleteDiscountGroupDelRequestSuccess: () => ({
    type: Types.DELETE_DISCOUNTGROUP_DEL_SUCCESS,
  }),

  deleteDiscountGroupDelRequestFail: (payload) => ({
    type: Types.DELETE_DISCOUNTGROUP_DEL_FAIL,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
