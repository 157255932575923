export const Types = {

  // FREIGHT ----------
  //Get - List
  GET_FREIGHT_LIST_REQUEST: 'GET_FREIGHT_LIST_REQUEST',
  GET_FREIGHT_LIST_SUCCESS: 'GET_FREIGHT_LIST_SUCCESS',
  GET_FREIGHT_LIST_FAIL: 'GET_FREIGHT_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_FREIGHT_LIST: 'SET_UPDATE_FREIGHT_LIST',

  //Store - Add
  POST_FREIGHT_ADD_REQUEST: 'POST_FREIGHT_ADD_REQUEST',
  POST_FREIGHT_ADD_SUCCESS: 'POST_FREIGHT_ADD_SUCCESS',
  POST_FREIGHT_ADD_FAIL: 'POST_FREIGHT_ADD_FAIL',

  //Update - Edit
  PUT_FREIGHT_EDIT_REQUEST: 'PUT_FREIGHT_EDIT_REQUEST',
  PUT_FREIGHT_EDIT_SUCCESS: 'PUT_FREIGHT_EDIT_SUCCESS',
  PUT_FREIGHT_EDIT_FAIL: 'PUT_FREIGHT_EDIT_FAIL',

  //Delete
  DELETE_FREIGHT_DEL_REQUEST: 'DELETE_FREIGHT_DEL_REQUEST',
  DELETE_FREIGHT_DEL_SUCCESS: 'DELETE_FREIGHT_DEL_SUCCESS',
  DELETE_FREIGHT_DEL_FAIL: 'DELETE_FREIGHT_DEL_FAIL',

  //CLear 
  CLEAR_ALL_FREIGHT_REQUEST: 'CLEAR_ALL_FREIGHT_REQUEST',
};

const INITIAL_STATE = {

  // DISCOUNT GROUP ----------
  dataFreightList: [],
  loadingFreightList: false,
  errorFreightList: false,
  errorDataFreightList: false,
  dataFreightListUpdate: false,

  loadingFreightAdd: false,
  errorFreightAdd: false,
  errorDataFreightAdd: false,

  loadingFreightEdit: false,
  errorFreightEdit: false,
  errorDataFreightEdit: false,

  loadingFreightDelete: false,
  errorFreightDelete: false,
  errorDataFreightDelete: false,

};

export default function freight(state = INITIAL_STATE, action) {
  switch (action.type) {

    // DISCOUNT GROUP ----------
    //Get - List
    case Types.GET_FREIGHT_LIST_REQUEST:
      return {
        ...state,
        loadingFreightList: true,
        errorFreightList: false,
      };
    case Types.GET_FREIGHT_LIST_SUCCESS:
      return {
        ...state,
        dataFreightList: action.payload,
        loadingFreightList: false,
        errorFreightList: false,
        errorDataFreightList: false
      };
    case Types.GET_FREIGHT_LIST_FAIL:
      return {
        ...state,
        dataFreightList: false,
        loadingFreightList: false,
        errorFreightList: true,
        errorDataFreightList: action.payload,
      };

    case Types.SET_UPDATE_FREIGHT_LIST:
      return {
        ...state,
        dataFreightListUpdate: action.payload,
      };

    //Store - Add
    case Types.POST_FREIGHT_ADD_REQUEST:
      return {
        ...state,
        loadingFreightAdd: true,
        errorFreightAdd: false,
      };
    case Types.POST_FREIGHT_ADD_SUCCESS:
      return {
        ...state,
        loadingFreightAdd: false,
        errorFreightAdd: false,
        errorDataFreightAdd: false,
      };
    case Types.POST_FREIGHT_ADD_FAIL:
      return {
        ...state,
        loadingFreightAdd: false,
        errorFreightAdd: true,
        errorDataFreightAdd: action.payload,
      };

    //Update - Edit
    case Types.PUT_FREIGHT_EDIT_REQUEST:
      return {
        ...state,
        loadingFreightEdit: true,
        errorFreightEdit: false,
      };
    case Types.PUT_FREIGHT_EDIT_SUCCESS:
      return {
        ...state,
        loadingFreightEdit: false,
        errorFreightEdit: false,
        errorDataFreightEdit: false,
      };
    case Types.PUT_FREIGHT_EDIT_FAIL:
      return {
        ...state,
        loadingFreightEdit: false,
        errorFreightEdit: true,
        errorDataFreightEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_FREIGHT_DEL_REQUEST:
      return {
        ...state,
        loadingFreightDelete: true,
        errorFreightDelete: false,
      };
    case Types.DELETE_FREIGHT_DEL_SUCCESS:
      return {
        ...state,
        loadingFreightDelete: false,
        errorFreightDelete: false,
        errorDataFreightDelete: false,
      };
    case Types.DELETE_FREIGHT_DEL_FAIL:
      return {
        ...state,
        loadingFreightDelete: false,
        errorFreightDelete: true,
        errorDataFreightDelete: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_FREIGHT_REQUEST:
      return {
        ...state,

        // DISCOUNT GROUP ----------
        dataFreightList: [],
        loadingFreightList: false,
        errorFreightList: false,
        errorDataFreightList: false,
        dataFreightListUpdate: false,

        loadingFreightAdd: false,
        errorFreightAdd: false,
        errorDataFreightAdd: false,

        loadingFreightEdit: false,
        errorFreightEdit: false,
        errorDataFreightEdit: false,

        loadingFreightDelete: false,
        errorFreightDelete: false,
        errorDataFreightDelete: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  // FREIGHT ----------
  //Get - List
  getFreightListRequest: (payload) => ({
    type: Types.GET_FREIGHT_LIST_REQUEST,
    payload,
  }),

  getFreightListRequestSuccess: (payload) => ({
    type: Types.GET_FREIGHT_LIST_SUCCESS,
    payload,
  }),

  getFreightListRequestFail: (payload) => ({
    type: Types.GET_FREIGHT_LIST_FAIL,
    payload,
  }),

  setUpdateFreight: (payload) => ({
    type: Types.SET_UPDATE_FREIGHT_LIST,
    payload,
  }),

  //Store - Add 
  postFreightAddRequest: (payload) => ({
    type: Types.POST_FREIGHT_ADD_REQUEST,
    payload,
  }),

  postFreightAddRequestSuccess: () => ({
    type: Types.POST_FREIGHT_ADD_SUCCESS,
  }),

  postFreightAddRequestFail: (payload) => ({
    type: Types.POST_FREIGHT_ADD_FAIL,
    payload,
  }),

  //Update - Edit 
  putFreightEditRequest: (payload) => ({
    type: Types.PUT_FREIGHT_EDIT_REQUEST,
    payload,
  }),

  putFreightEditRequestSuccess: () => ({
    type: Types.PUT_FREIGHT_EDIT_SUCCESS,
  }),

  putFreightEditRequestFail: (payload) => ({
    type: Types.PUT_FREIGHT_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteFreightDelRequest: (payload) => ({
    type: Types.DELETE_FREIGHT_DEL_REQUEST,
    payload,
  }),

  deleteFreightDelRequestSuccess: () => ({
    type: Types.DELETE_FREIGHT_DEL_SUCCESS,
  }),

  deleteFreightDelRequestFail: (payload) => ({
    type: Types.DELETE_FREIGHT_DEL_FAIL,
    payload,
  }),

  //Clear
  clearAllFreightRequest: () => ({
    type: Types.CLEAR_ALL_FREIGHT_REQUEST
  }),
};
