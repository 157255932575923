import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import {
  Creators as AuthActions,
  Types as AuthTypes,
} from '../../reducers/authentication';

import {
  Creators as AdministratorsActions,
} from '../../reducers/administrators';

import {
  Creators as CustomersActions,
} from '../../reducers/customers';

import {
  Creators as EmployeesActions,
} from '../../reducers/employees';

import {
  Creators as MessagesActions,
} from '../../reducers/messages';

import {
  Creators as QotationsActions,
} from '../../reducers/quotations';

import {
  Creators as SuppliersActions,
} from '../../reducers/suppliers';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

async function saveToken({ token }) {
  await AsyncStorage.multiSet([
    ['iguacu:token', token],
    ['iguacu:refresh', token],
  ]);
}

async function deleteTokens() {
  await AsyncStorage.multiRemove(['iguacu:refresh', 'iguacu:token']);
}

function* login({ payload }) {
  try {
    const {
      data,
      data: { access_token: token },
    } = yield call(api.post, '/admin/auth', payload);

    if (data?.profile?.is_active === 1) {
      yield call(saveToken, { token });
      yield put(AuthActions.loginRequestSuccess(data));
      window.location.href = '/welcome';
    } else {
      yield put(AuthActions.loginRequestFail('Seu usuário está inativo, entre em contato com a Iguaçu Máquinas'));
      toast.info("Seu usuário está inativo, entre em contato com a Iguaçu Máquinas.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

  } catch (error) {
    yield put(AuthActions.loginRequestFail(error?.response?.data));
    let messages = ""

    if (typeof loginErrorData === 'string') {

      Object.keys(error?.response?.data?.errors).map(key => (
        error?.response?.data?.errors[key].map(message => (
          messages += message
        ))
      ))

      toast.error(JSON.stringify(error?.response?.data), {
        position: toast.POSITION.BOTTOM_CENTER
      });

    } else {
      toast.error(JSON.stringify(error?.response?.data), {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* resetPassword({ payload }) {
  console.log('payload', payload)
  try {
    const {
      data
    } = yield call(api.post, '/employees/reset_password', payload);
    yield put(AuthActions.resetPasswordRequestSuccess(data));
    toast.info("Senha alterada com sucesso.", {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } catch (error) {
    yield put(AuthActions.resetPasswordRequestFail(error?.response?.data));
    toast.info(`Erro ao alterar senha, ${JSON.stringify(error?.response?.data)}`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
}

function* logout() {
  try {
    yield call(deleteTokens);
    yield put(AuthActions.clearAllRequest());
    yield put(AdministratorsActions.clearAllRequest());
    yield put(CustomersActions.clearAllRequest());
    yield put(EmployeesActions.clearAllRequest());
    yield put(MessagesActions.clearAllRequest());
    yield put(QotationsActions.clearAllRequest());
    yield put(SuppliersActions.clearAllRequest());
    yield put(RefreshActions.setAlertModalExperedLogin(false));
    yield put(RefreshActions.clearAllRequest());
    window.location.href = '/';
  } catch (error) {

  }
}

export function* loginWatcher() {
  yield takeLatest(AuthTypes.LOGIN_REQUEST, login);
}

export function* logoutWatcher() {
  yield takeLatest(AuthTypes.LOGOUT_REQUEST, logout);
}

export function* resetPasswordWatcher() {
  yield takeLatest(AuthTypes.RESETPASSWORD_REQUEST, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(loginWatcher),
    fork(logoutWatcher),
    fork(resetPasswordWatcher),
  ]);
}
