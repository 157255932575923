import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Switch, Input, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import api from '../../../services/api';
import { toast } from 'react-toastify';

//Dispatchs
import { Creators as AdministratorsActions } from '../../../store/reducers/administrators';
import { Creators as RefreshActions } from '../../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components

//Variables
const EditSchema = yup.object().shape({
  first_name: yup.string()
    .min(2, 'A nome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  last_name: yup.string()
    .min(2, 'A sobrenome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  email: yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
});

const Edit = ({ dataEdit }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingEdit } = useSelector((state) => (state.administrators))
  const { data } = useSelector((state) => (state.authentication))

  //States Local
  const [dataAdministrator, setDataAdministrator] = useState(null);

  //Context

  //Cicle of Life

  const fetchGetAdministrator = async () => {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/${dataEdit?.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setDataAdministrator(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  const mountPhotos = () => {
    if (dataAdministrator?.avatar_url) {
      setFileListPhotos([
        {
          uid: dataAdministrator?.id,
          name: 'Imagem ' + dataAdministrator?.id,
          status: 'done',
          url: dataAdministrator?.avatar_url,
          thumbUrl: dataAdministrator?.avatar_url,
        }
      ])
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchGetAdministrator()
    mountPhotos()
  }, []); 

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    mountPhotos()
  }, [dataAdministrator?.avatar_url]);
  
  //Functions
  

  const submitEdit = (values) => {
    const payload = values
    dispatch(AdministratorsActions.putAdministratorsEditRequest(payload))
  }

 

  const [loader, setLoader] = useState(null);
  const [fileListPhotos, setFileListPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('avatar', fileListPhotos[0]);

    setUploading(true);
    const id = dataEdit?.id
    const token = localStorage.getItem('iguacu:token');

    fetch(api.defaults.baseURL + `/admin/${id}/avatar`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        fetchGetAdministrator()
        toast.success("Avatar enviado com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(() => {
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const removePhoto = (id) => {
  };

  const propsUpload = {
    listType: "picture-card",
    multiple: false,
    onRemove: async (file) => {
      const index = fileListPhotos.indexOf(file);
      removePhoto(fileListPhotos[index]?.uid)
      const newFileList = fileListPhotos.slice();
      newFileList.splice(index, 1);
      setFileListPhotos(newFileList);
    },
    beforeUpload: async (file) => {

      const acceptedFormats = ['image/png', 'image/jpeg'];
      const isFormatValid = acceptedFormats.includes(file.type);
      const isSizeValid = file.size / 1024 / 1024 <= 2;

      if (!isFormatValid) {
        toast.error("Por favor, envie apenas arquivos PNG ou JPG!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      if (!isSizeValid) {
        toast.error("O tamanho máximo do arquivo é de 2MB!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      setFileListPhotos([file]);
      return false;
    },
    fileList: fileListPhotos,
  };

  //Render
  return (
    <>
      <div className='p-2 content-box-form'>
        {loader &&
          <div className='p-2'>
            <LoadingOutlined />
          </div>
        }
        {
          dataAdministrator?.id &&
          <Formik
            initialValues={{
              id: dataAdministrator?.id,
              first_name: dataAdministrator?.first_name,
              last_name: dataAdministrator?.last_name,
              email: dataAdministrator?.email,
              is_active: (dataAdministrator?.is_active === 0) ? false : true,
              id_user_logged: data?.profile?.id
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => (
              submitEdit(values)
            )
            }
          >
            {({ setFieldValue, handleSubmit, values, errors, touched }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="first_name">Nome</label>
                    <Field name="first_name" type="text" key={'first_name'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.first_name}
                          {...field}
                          size="large"
                          placeholder="Nome"
                        />
                      }}
                    </Field>
                    {errors.first_name && touched.first_name ? <div className='error my-2 px-2'>{errors.first_name}</div> : null}
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="last_name">Sobrenome</label>
                    <Field name="last_name" type="text" key={'last_name'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.last_name}
                          {...field}
                          size="large"
                          placeholder="Sobrenome"
                        />
                      }}
                    </Field>
                    {errors.last_name && touched.last_name ? <div className='error my-2 px-2'>{errors.last_name}</div> : null}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="email">Email</label>
                    <Field name="email" type="text" key={'email'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.email}
                          {...field}
                          type='email'
                          size="large"
                          placeholder="Email"
                        />
                      }}
                    </Field>
                    {errors.email && touched.email ? <div className='error my-2 px-2'>{errors.email}</div> : null}
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="last_name">Inativo/Ativo</label>
                    <div className='my-2'>
                      <Switch
                        onChange={(value) => setFieldValue('is_active', value)}
                        value={values.is_active}
                        checked={values.is_active}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='my-2' span={24}>
                    <Button
                      className='m-2'
                      disabled={(loadingEdit) ? true : false}
                      loading={(loadingEdit) ? true : false}
                      onClick={() => handleSubmit(values)}
                      type="primary"
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24} className='my-2' >
                    <label className='p-2'>Avatar</label>
                    <div className='my-2'>
                      <Upload {...propsUpload}>
                        <Button icon={<UploadOutlined />}></Button>
                      </Upload>
                      <Button
                        type="primary"
                        size={'small'}
                        onClick={handleUpload}
                        loading={uploading}
                        style={{
                          margin: 3,
                        }}
                      >
                        {uploading ? 'Uploading' : 'Enviar Avatar'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Formik >
        }
      </div >
    </>
  );
};

export default Edit;