export const Types = {
  //Get - List
  GET_REFRESH_REQUEST: 'GET_REFRESH_REQUEST',
  GET_REFRESH_SUCCESS: 'GET_REFRESH_SUCCESS',
  GET_REFRESH_FAIL: 'GET_REFRESH_FAIL',

  // Show modal alert
  SET_ALERT_MODAL_EXIPERED_LOGIN: 'SET_ALERT_MODAL_EXIPERED_LOGIN',


  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {
  dataList: [],
  loadingList: false,
  errorList: false,
  errorDataList: false,
  dataListUpdate: false,

  alertModalExpiredLogin: false,

};

export default function refresh(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Get - List
    case Types.GET_REFRESH_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorList: false,
      };
    case Types.GET_REFRESH_SUCCESS:
      return {
        ...state,
        dataList: action.payload,
        loadingList: false,
        errorList: false,
        errorDataList: false
      };
    case Types.GET_REFRESH_FAIL:
      return {
        ...state,
        dataList: false,
        loadingList: false,
        errorList: true,
        errorDataList: action.payload,
      };

    case Types.SET_ALERT_MODAL_EXIPERED_LOGIN:
      return {
        ...state,
        alertModalExpiredLogin: action.payload
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        dataList: false,
        loadingList: false,
        errorList: false,
        errorDataList: false,
        dataListUpdate: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  //Get - List
  getRefreshRequest: (payload) => ({
    type: Types.GET_REFRESH_REQUEST,
    payload,
  }),
  getRefreshSuccess: (payload) => ({
    type: Types.GET_REFRESH_SUCCESS,
    payload,
  }),
  getRefreshRequestFail: (payload) => ({
    type: Types.GET_REFRESH_FAIL,
    payload,
  }),

  setAlertModalExperedLogin: (payload) => ({
    type: Types.SET_ALERT_MODAL_EXIPERED_LOGIN,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
