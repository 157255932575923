export const Types = {

  //Get User
  GET_USERPROFILE_REQUEST: 'GET_USERPROFILE_REQUEST',
  GET_USERPROFILE_SUCCESS: 'GET_USERPROFILE_SUCCESS',
  GET_USERPROFILE_FAIL: 'GET_USERPROFILE_FAIL',

  //Get - List

  //Set for Update list
  SET_UPDATE_USERPROFILE: 'SET_UPDATE_USERPROFILE',

  POST_USER_UPDATEPASSWORD_REQUEST: 'POST_USER_UPDATEPASSWORD_REQUEST',
  POST_USER_UPDATEPASSWORD_SUCCESS: 'POST_USER_UPDATEPASSWORD_SUCCESS',
  POST_USER_UPDATEPASSWORD_FAIL: 'POST_USER_UPDATEPASSWORD_FAIL',

  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {

  dataUserProfile: [],
  loadingUserProfile: false,
  errorUserProfile: false,
  errorDataUserProfile: false,

  dataUpdateUserProfile: false,

  dataUpdatePassword: [],
  loadingUpdatePassword: false,
  errorUpdatePassword: false,
  errorDataUpdatePassword: false,

};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    //
    case Types.GET_USERPROFILE_REQUEST:
      return {
        ...state,
        loadingUserProfile: true,
        errorUserProfile: false,
      };
    case Types.GET_USERPROFILE_SUCCESS:
      return {
        ...state,
        dataUserProfile: action.payload,
        loadingUserProfile: false,
        errorUserProfile: false,
        errorDataUserProfile: false
      };
    case Types.GET_USERPROFILE_FAIL:
      return {
        ...state,
        dataUserProfile: false,
        loadingUserProfile: false,
        errorUserProfile: true,
        errorDataUserProfile: action.payload,
      };

    case Types.SET_UPDATE_USERPROFILE:
      return {
        ...state,
        dataUpdateUserProfile: action.payload,
      };

    //POST UPDATE PASSWORD
    case Types.POST_USER_UPDATEPASSWORD_REQUEST:
      return {
        ...state,
        loadingUpdatePassword: true,
        errorUpdatePassword: false,
      };
    case Types.POST_USER_UPDATEPASSWORD_SUCCESS:
      return {
        ...state,
        dataUpdatePassword: action.payload,
        loadingUpdatePassword: false,
        errorUpdatePassword: false,
        errorDataUpdatePassword: false
      };
    case Types.POST_USER_UPDATEPASSWORD_FAIL:
      return {
        ...state,
        dataUpdatePassword: false,
        loadingUpdatePassword: false,
        errorUpdatePassword: true,
        errorDataUpdatePassword: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        dataUserProfile: false,
        loadingUserProfile: false,
        errorUserProfile: false,
        errorDataUserProfile: false,
        dataUpdateUserProfile: false,

        dataUpdatePassword: false,
        loadingUpdatePassword: false,
        errorUpdatePassword: false,
        errorDataUpdatePassword: false,
        dataListUpdateUpdatePassword: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  //Get - List
  getUserProfileRequest: (payload) => ({
    type: Types.GET_USERPROFILE_REQUEST,
    payload,
  }),
  getUserProfileRequestSuccess: (payload) => ({
    type: Types.GET_USERPROFILE_SUCCESS,
    payload,
  }),
  getUserProfileRequestFail: (payload) => ({
    type: Types.GET_USERPROFILE_FAIL,
    payload,
  }),

  setUpdateUserProfile: (payload) => ({
    type: Types.SET_UPDATE_USERPROFILE,
    payload,
  }),

  //Update Password
  postUpdatePasswordRequest: (payload) => ({
    type: Types.POST_USER_UPDATEPASSWORD_REQUEST,
    payload,
  }),
  postUpdatePasswordRequestSuccess: (payload) => ({
    type: Types.POST_USER_UPDATEPASSWORD_SUCCESS,
    payload,
  }),
  postUpdatePasswordRequestFail: (payload) => ({
    type: Types.POST_USER_UPDATEPASSWORD_FAIL,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
