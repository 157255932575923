export const Types = {

  // BRANDS ----------
  //Get - List
  GET_BRANDS_LIST_REQUEST: 'GET_BRANDS_LIST_REQUEST',
  GET_BRANDS_LIST_SUCCESS: 'GET_BRANDS_LIST_SUCCESS',
  GET_BRANDS_LIST_FAIL: 'GET_BRANDS_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_BRANDS_LIST: 'SET_UPDATE_BRANDS_LIST',

  //Store - Add
  POST_BRANDS_ADD_REQUEST: 'POST_BRANDS_ADD_REQUEST',
  POST_BRANDS_ADD_SUCCESS: 'POST_BRANDS_ADD_SUCCESS',
  POST_BRANDS_ADD_FAIL: 'POST_BRANDS_ADD_FAIL',

  //Update - Edit
  PUT_BRANDS_EDIT_REQUEST: 'PUT_BRANDS_EDIT_REQUEST',
  PUT_BRANDS_EDIT_SUCCESS: 'PUT_BRANDS_EDIT_SUCCESS',
  PUT_BRANDS_EDIT_FAIL: 'PUT_BRANDS_EDIT_FAIL',

  //Delete
  DELETE_BRANDS_DEL_REQUEST: 'DELETE_BRANDS_DEL_REQUEST',
  DELETE_BRANDS_DEL_SUCCESS: 'DELETE_BRANDS_DEL_SUCCESS',
  DELETE_BRANDS_DEL_FAIL: 'DELETE_BRANDS_DEL_FAIL',

  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {

  // BRANDS ----------
  dataBrandsList: [],
  loadingBrandsList: false,
  errorBrandsList: false,
  errorDataBrandsList: false,
  dataBrandsListUpdate: false,

  loadingBrandsAdd: false,
  errorBrandsAdd: false,
  errorDataBrandsAdd: false,

  loadingBrandsEdit: false,
  errorBrandsEdit: false,
  errorDataBrandsEdit: false,

  loadingBrandsDelete: false,
  errorBrandsDelete: false,
  errorDataBrandsDelete: false,

};

export default function brands(state = INITIAL_STATE, action) {
  switch (action.type) {

    // BRANDS ----------
    //Get - List
    case Types.GET_BRANDS_LIST_REQUEST:
      return {
        ...state,
        loadingBrandsList: true,
        errorBrandsList: false,
      };
    case Types.GET_BRANDS_LIST_SUCCESS:
      return {
        ...state,
        dataBrandsList: action.payload,
        loadingBrandsList: false,
        errorBrandsList: false,
        errorDataBrandsList: false
      };
    case Types.GET_BRANDS_LIST_FAIL:
      return {
        ...state,
        dataBrandsList: false,
        loadingBrandsList: false,
        errorBrandsList: true,
        errorDataBrandsList: action.payload,
      };

    case Types.SET_UPDATE_BRANDS_LIST:
      return {
        ...state,
        dataBrandsListUpdate: action.payload,
      };

    //Store - Add
    case Types.POST_BRANDS_ADD_REQUEST:
      return {
        ...state,
        loadingBrandsAdd: true,
        errorBrandsAdd: false,
      };
    case Types.POST_BRANDS_ADD_SUCCESS:
      return {
        ...state,
        loadingBrandsAdd: false,
        errorBrandsAdd: false,
        errorDataBrandsAdd: false,
      };
    case Types.POST_BRANDS_ADD_FAIL:
      return {
        ...state,
        loadingBrandsAdd: false,
        errorBrandsAdd: true,
        errorDataBrandsAdd: action.payload,
      };

    //Update - Edit
    case Types.PUT_BRANDS_EDIT_REQUEST:
      return {
        ...state,
        loadingBrandsEdit: true,
        errorBrandsEdit: false,
      };
    case Types.PUT_BRANDS_EDIT_SUCCESS:
      return {
        ...state,
        loadingBrandsEdit: false,
        errorBrandsEdit: false,
        errorDataBrandsEdit: false,
      };
    case Types.PUT_BRANDS_EDIT_FAIL:
      return {
        ...state,
        loadingBrandsEdit: false,
        errorBrandsEdit: true,
        errorDataBrandsEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_BRANDS_DEL_REQUEST:
      return {
        ...state,
        loadingBrandsDelete: true,
        errorBrandsDelete: false,
      };
    case Types.DELETE_BRANDS_DEL_SUCCESS:
      return {
        ...state,
        loadingBrandsDelete: false,
        errorBrandsDelete: false,
        errorDataBrandsDelete: false,
      };
    case Types.DELETE_BRANDS_DEL_FAIL:
      return {
        ...state,
        loadingBrandsDelete: false,
        errorBrandsDelete: true,
        errorDataBrandsDelete: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        // BRANDS ----------
        dataBrandsList: false,
        loadingBrandsList: false,
        errorBrandsList: false,
        errorDataBrandsList: false,
        dataBrandsListUpdate: false,

        loadingBrandsAdd: false,
        errorBrandsAdd: false,
        errorDataBrandsAdd: false,

        loadingBrandsEdit: false,
        errorBrandsEdit: false,
        errorDataBrandsEdit: false,

        loadingBrandsDelete: false,
        errorBrandsDelete: false,
        errorDataBrandsDelete: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  // BRANDS ----------
  //Get - List
  getBrandsListRequest: (payload) => ({
    type: Types.GET_BRANDS_LIST_REQUEST,
    payload,
  }),

  getBrandsListRequestSuccess: (payload) => ({
    type: Types.GET_BRANDS_LIST_SUCCESS,
    payload,
  }),

  getBrandsListRequestFail: (payload) => ({
    type: Types.GET_BRANDS_LIST_FAIL,
    payload,
  }),

  setUpdateBrands: (payload) => ({
    type: Types.SET_UPDATE_BRANDS_LIST,
    payload,
  }),

  //Store - Add 
  postBrandsAddRequest: (payload) => ({
    type: Types.POST_BRANDS_ADD_REQUEST,
    payload,
  }),

  postBrandsAddRequestSuccess: () => ({
    type: Types.POST_BRANDS_ADD_SUCCESS,
  }),

  postBrandsAddRequestFail: (payload) => ({
    type: Types.POST_BRANDS_ADD_FAIL,
    payload,
  }),

  //Update - Edit 
  putBrandsEditRequest: (payload) => ({
    type: Types.PUT_BRANDS_EDIT_REQUEST,
    payload,
  }),

  putBrandsEditRequestSuccess: () => ({
    type: Types.PUT_BRANDS_EDIT_SUCCESS,
  }),

  putBrandsEditRequestFail: (payload) => ({
    type: Types.PUT_BRANDS_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteBrandsDelRequest: (payload) => ({
    type: Types.DELETE_BRANDS_DEL_REQUEST,
    payload,
  }),

  deleteBrandsDelRequestSuccess: () => ({
    type: Types.DELETE_BRANDS_DEL_SUCCESS,
  }),

  deleteBrandsDelRequestFail: (payload) => ({
    type: Types.DELETE_BRANDS_DEL_FAIL,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
