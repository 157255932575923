import React, { useState } from "react";

export const ProductsContext = React.createContext();

const ProductsProvider = ({ children }) => {

  //States Local
  const [productsContext, setProductsContext] = useState({
    show: {
      status: false,
      title: `Detalhes`,
      data: {},
    },
    groups: {
      status: false,
      title: `Grupos `,
      data: {},
    },
    subitens: {
      status: false,
      title: `Subitens `,
      data: {},
    },
    dependeces: {
      status: false,
      title: `Dependeces `,
      data: {},
    },
    restrictions: {
      status: false,
      title: `Detalhes `,
      data: {},
    },

  })

  //Functions

  const updateModal = (key, updatedProps) => {
    setProductsContext(prevState => ({
      ...prevState,
      [key]: {
        ...updatedProps,
      }
    }));
  };

  //Render
  return (
    <ProductsContext.Provider
      value={
        {
          productsContext,
          updateModal,
        }
      }
    >
      {children}
    </ProductsContext.Provider>
  );

};

export default ProductsProvider;