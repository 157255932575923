import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as DiscountProductActions,
  Types as DiscountProductTypes,
} from '../../reducers/discount_product';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listDiscountProduct({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/discount_product?page=${payload?.page}&discount=${payload?.discount}&name=${payload?.name}`
    );
    yield put(DiscountProductActions.setUpdateDiscountProduct(false));
    yield put(DiscountProductActions.getDiscountProductListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(DiscountProductActions.getDiscountProductListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addDiscountProduct({ payload }) {
  try {
    yield call(api.post, '/admin/discount_product', payload);
    yield put(DiscountProductActions.setUpdateDiscountProduct(true));
    yield put(DiscountProductActions.postDiscountProductAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(DiscountProductActions.postDiscountProductAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editDiscountProduct({ payload }) {
  try {
    yield call(api.put, `/admin/discount_product/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(DiscountProductActions.setUpdateDiscountProduct(true));
    yield put(DiscountProductActions.putDiscountProductEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(DiscountProductActions.putDiscountProductEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteDiscountProduct({ payload }) {
  try {
    yield call(api.delete, `/admin/discount_product/${payload?.id}`);
    // console.log('data store', data)
    yield put(DiscountProductActions.setUpdateDiscountProduct(true));
    yield put(DiscountProductActions.postDiscountProductAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(DiscountProductActions.deleteDiscountProductDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listDiscountProductWatcher() {
  yield takeLatest(DiscountProductTypes.GET_DISCOUNTPRODUCT_LIST_REQUEST, listDiscountProduct);
}

export function* addDiscountProductWatcher() {
  yield takeLatest(DiscountProductTypes.POST_DISCOUNTPRODUCT_ADD_REQUEST, addDiscountProduct);
}

export function* editDiscountProductWatcher() {
  yield takeLatest(DiscountProductTypes.PUT_DISCOUNTPRODUCT_EDIT_REQUEST, editDiscountProduct);
}

export function* deleteDiscountProductWatcher() {
  yield takeLatest(DiscountProductTypes.DELETE_DISCOUNTPRODUCT_DEL_REQUEST, deleteDiscountProduct);
}

export default function* rootSaga() {
  yield all([
    fork(listDiscountProductWatcher),
    fork(addDiscountProductWatcher),
    fork(editDiscountProductWatcher),
    fork(deleteDiscountProductWatcher),
  ]);
}
