import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import {
  Creators as ProductsActions,
  Types as Products
} from '../../reducers/products'

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

//PRODUCT MODELS
function* listProducts({ payload }) {
  try {
    const queryParams = {
      page: payload?.page,
      serie: payload?.serie,
      isSugestions: payload?.isSugestions,
      price: payload?.price,
      basecode: payload?.basecode,
      model_code: payload?.model_code,
      model_name: payload?.model_name,
      machine_type_name: payload?.machine_type_name,
    };

    const queryString = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const { data } = yield call(api.get, `/admin/products?${queryString}`);
    yield put(ProductsActions.setUpdateProducts(false));
    yield put(ProductsActions.getProductsListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductsActions.getProductsListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addProducts({ payload }) {
  try {
    yield call(api.post, '/admin/products', payload);
    yield put(ProductsActions.setUpdateProducts(true));
    yield put(ProductsActions.postProductsAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductsActions.postProductsAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editProducts({ payload }) {
  try {
    yield call(api.put, `/admin/products/${payload?.id}`, payload);
    yield put(ProductsActions.setUpdateProducts(true));
    yield put(ProductsActions.putProductsEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductsActions.putProductsEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteProducts({ payload }) {
  try {
    yield call(api.delete, `/admin/products/${payload?.id}`);
    yield put(ProductsActions.setUpdateProducts(true));
    yield put(ProductsActions.postProductsAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductsActions.deleteProductsDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* uploadImageProducts({ payload }) {
  try {
    yield call(api.post, `/admin/products/${payload?.id}/photo`, payload?.formData);
    yield put(ProductsActions.setUpdateProducts(true));
    yield put(ProductsActions.postProductsUploadImageRequestSuccess());
    toast.success("Imagem salva com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductsActions.postProductsUploadImageRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteImageProducts({ payload }) {
  try {
    yield call(api.delete, `/admin/products/${payload?.id}/photo`, payload?.formData);
    yield put(ProductsActions.setUpdateProducts(true));
    yield put(ProductsActions.deleteProductsDelImageRequestSuccess());
    toast.success("Imagem deletada com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(ProductsActions.deleteProductsDelImageRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

//PRODUCT
export function* listProductsWatcher() {
  yield takeLatest(Products.GET_PRODUCTS_LIST_REQUEST, listProducts);
}

export function* addProductsWatcher() {
  yield takeLatest(Products.POST_PRODUCTS_ADD_REQUEST, addProducts);
}

export function* editProductsWatcher() {
  yield takeLatest(Products.PUT_PRODUCTS_EDIT_REQUEST, editProducts);
}

export function* deleteProductsWatcher() {
  yield takeLatest(Products.DELETE_PRODUCTS_DEL_REQUEST, deleteProducts);
}

export function* uploadImageProductsWatcher() {
  yield takeLatest(Products.POST_PRODUCTS_UPLOADIMAGE_REQUEST, uploadImageProducts);
}

export function* deleteImageProductsWatcher() {
  yield takeLatest(Products.DELETE_PRODUCTS_DELIMAGE_REQUEST, deleteImageProducts);
}

//PRODUCTS SUBITENS
// export function* visibleProductsSubitensWatcher() {
//   yield takeLatest(Products.PUT_PRODUCTS_SUBITENS_VISIBLE_REQUEST, visibleProductsSubtens);
// }

export default function* rootSaga() {
  yield all([

    fork(listProductsWatcher),
    fork(addProductsWatcher),
    fork(editProductsWatcher),
    fork(deleteProductsWatcher),
    fork(uploadImageProductsWatcher),
    fork(deleteImageProductsWatcher),

    // fork(visibleProductsSubitensWatcher),

  ]);
}
