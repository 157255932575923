import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as GroupsActions,
  Types as GroupsTypes,
} from '../../reducers/groups';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listGroups({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/sub_item_groups?page=${payload?.page}&name=${payload?.name}&code=${payload?.code}`
    );
    yield put(GroupsActions.setUpdateGroups(false));
    yield put(GroupsActions.getGroupsListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(GroupsActions.getGroupsListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addGroups({ payload }) {
  try {
    yield call(api.post, '/admin/sub_item_groups', payload);
    yield put(GroupsActions.setUpdateGroups(true));
    yield put(GroupsActions.postGroupsAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(GroupsActions.postGroupsAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

  }
}

function* editGroups({ payload }) {
  try {
    yield call(api.put, `/admin/sub_item_groups/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(GroupsActions.setUpdateGroups(true));
    yield put(GroupsActions.putGroupsEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(GroupsActions.putGroupsEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

  }
}

function* deleteGroups({ payload }) {
  try {
    yield call(api.delete, `/admin/sub_item_groups/${payload?.id}`);
    // console.log('data store', data)
    yield put(GroupsActions.setUpdateGroups(true));
    yield put(GroupsActions.postGroupsAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(GroupsActions.deleteGroupsDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listGroupsWatcher() {
  yield takeLatest(GroupsTypes.GET_GROUPS_LIST_REQUEST, listGroups);
}

export function* addGroupsWatcher() {
  yield takeLatest(GroupsTypes.POST_GROUPS_ADD_REQUEST, addGroups);
}

export function* editGroupsWatcher() {
  yield takeLatest(GroupsTypes.PUT_GROUPS_EDIT_REQUEST, editGroups);
}

export function* deleteGroupsWatcher() {
  yield takeLatest(GroupsTypes.DELETE_GROUPS_DEL_REQUEST, deleteGroups);
}

export default function* rootSaga() {
  yield all([
    fork(listGroupsWatcher),
    fork(addGroupsWatcher),
    fork(editGroupsWatcher),
    fork(deleteGroupsWatcher),
  ]);
}
