export const Types = {
  //Get - List
  GET_MESSAGES_LIST_REQUEST: 'GET_MESSAGES_LIST_REQUEST',
  GET_MESSAGES_LIST_SUCCESS: 'GET_MESSAGES_LIST_SUCCESS',
  GET_MESSAGES_LIST_FAIL: 'GET_MESSAGES_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_MESSAGES_LIST: 'SET_UPDATE_MESSAGES_LIST',

  //Store - Add
  POST_MESSAGES_ADD_REQUEST: 'POST_MESSAGES_ADD_REQUEST',
  POST_MESSAGES_ADD_SUCCESS: 'POST_MESSAGES_ADD_SUCCESS',
  POST_MESSAGES_ADD_FAIL: 'POST_MESSAGES_ADD_FAIL',

  //Update - Edit
  PUT_MESSAGES_EDIT_REQUEST: 'PUT_MESSAGES_EDIT_REQUEST',
  PUT_MESSAGES_EDIT_SUCCESS: 'PUT_MESSAGES_EDIT_SUCCESS',
  PUT_MESSAGES_EDIT_FAIL: 'PUT_MESSAGES_EDIT_FAIL',

  //Delete
  DELETE_MESSAGES_DEL_REQUEST: 'DELETE_MESSAGES_DEL_REQUEST',
  DELETE_MESSAGES_DEL_SUCCESS: 'DELETE_MESSAGES_DEL_SUCCESS',
  DELETE_MESSAGES_DEL_FAIL: 'DELETE_MESSAGES_DEL_FAIL',


  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {
  dataList: [],
  loadingList: false,
  errorList: false,
  errorDataList: false,
  dataListUpdate: false,

  loadingAdd: false,
  errorAdd: false,
  errorDataAdd: false,

  loadingEdit: false,
  errorEdit: false,
  errorDataEdit: false,

  loadingDelete: false,
  errorDelete: false,
  errorDataDelete: false,

};

export default function messages(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Get - List
    case Types.GET_MESSAGES_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorList: false,
      };
    case Types.GET_MESSAGES_LIST_SUCCESS:
      return {
        ...state,
        dataList: action.payload,
        loadingList: false,
        errorList: false,
        errorDataList: false
      };
    case Types.GET_MESSAGES_LIST_FAIL:
      return {
        ...state,
        dataList: false,
        loadingList: false,
        errorList: true,
        errorDataList: action.payload,
      };

    case Types.SET_UPDATE_MESSAGES_LIST:
      return {
        ...state,
        dataListUpdate: action.payload,
      };

    //Store - Add
    case Types.POST_MESSAGES_ADD_REQUEST:
      return {
        ...state,
        loadingAdd: true,
        errorAdd: false,
      };
    case Types.POST_MESSAGES_ADD_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        errorAdd: false,
        errorDataAdd: false,
      };
    case Types.POST_MESSAGES_ADD_FAIL:
      return {
        ...state,
        loadingAdd: false,
        errorAdd: true,
        errorDataAdd: action.payload,
      };

    //Update - Edit
    case Types.PUT_MESSAGES_EDIT_REQUEST:
      return {
        ...state,
        loadingEdit: true,
        errorEdit: false,
      };
    case Types.PUT_MESSAGES_EDIT_SUCCESS:
      return {
        ...state,
        loadingEdit: false,
        errorEdit: false,
        errorDataEdit: false,
      };
    case Types.PUT_MESSAGES_EDIT_FAIL:
      return {
        ...state,
        loadingEdit: false,
        errorEdit: true,
        errorDataEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_MESSAGES_DEL_REQUEST:
      return {
        ...state,
        loadingDelete: true,
        errorDelete: false,
      };
    case Types.DELETE_MESSAGES_DEL_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: false,
        errorDataDelete: false,
      };
    case Types.DELETE_MESSAGES_DEL_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: true,
        errorDataDelete: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        dataList: false,
        loadingList: false,
        errorList: false,
        errorDataList: false,
        dataListUpdate: false,

        loadingAdd: false,
        errorAdd: false,
        errorDataAdd: false,

        loadingEdit: false,
        errorEdit: false,
        errorDataEdit: false,

        loadingDelete: false,
        errorDelete: false,
        errorDataDelete: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  //Get - List
  getMessagesListRequest: (payload) => ({
    type: Types.GET_MESSAGES_LIST_REQUEST,
    payload,
  }),
  getMessagesListRequestSuccess: (payload) => ({
    type: Types.GET_MESSAGES_LIST_SUCCESS,
    payload,
  }),
  getMessagesListRequestFail: (payload) => ({
    type: Types.GET_MESSAGES_LIST_FAIL,
    payload,
  }),

  setUpdateMessages: (payload) => ({
    type: Types.SET_UPDATE_MESSAGES_LIST,
    payload,
  }),


  //Store - Add 
  postMessagesAddRequest: (payload) => ({
    type: Types.POST_MESSAGES_ADD_REQUEST,
    payload,
  }),
  postMessagesAddRequestSuccess: () => ({
    type: Types.POST_MESSAGES_ADD_SUCCESS,
  }),
  postMessagesAddRequestFail: (payload) => ({
    type: Types.POST_MESSAGES_ADD_FAIL,
    payload,
  }),

  //Update - Edit 
  putMessagesEditRequest: (payload) => ({
    type: Types.PUT_MESSAGES_EDIT_REQUEST,
    payload,
  }),
  putMessagesEditRequestSuccess: () => ({
    type: Types.PUT_MESSAGES_EDIT_SUCCESS,
  }),
  putMessagesEditRequestFail: (payload) => ({
    type: Types.PUT_MESSAGES_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteMessagesDelRequest: (payload) => ({
    type: Types.DELETE_MESSAGES_DEL_REQUEST,
    payload,
  }),
  deleteMessagesDelRequestSuccess: () => ({
    type: Types.DELETE_MESSAGES_DEL_SUCCESS,
  }),
  deleteMessagesDelRequestFail: (payload) => ({
    type: Types.DELETE_MESSAGES_DEL_FAIL,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
