import React, { useState, useEffect } from 'react'
import { Col, Row, Input, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import api from '../../../../services/api';

import * as yup from 'yup';

//Dispatchs
import { Creators as ProductModelsActions } from '../../../../store/reducers/product_models';
import { Creators as RefreshActions } from '../../../../store/reducers/refresh';
//Styles And Helpers
import { convertDateBR } from '../../../../helpers';

//Contexts

//Components

//Variables
const ShowSchema = yup.object().shape({

});

const Show = ({ dataShow }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingProductModelsList } = useSelector((state) => (state.products_models))

  //States Local
  const [dataDiscountProduct, setDataDiscountProduct] = useState(null);
  const [loader, setLoader] = useState(null);
  const [selectedDiscountProduct, setSelectedDiscountProduct] = useState(
    {
      value: (dataShow?.discount_product?.id == null) ? null : String(dataShow?.discount_product?.id),
      label: (dataShow?.discount_product?.id == null) ? "_SEM DESCONTO_" : dataShow?.discount_product?.id,
    }
  );

  //Context

  //Cicle of Life

  useEffect(() => {
    fetchGetDiscountProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  //Functions

  async function fetchGetDiscountProduct() {

    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/discount_product`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {

        setDataDiscountProduct(mountSelectDiscountProduct(response?.data))
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        console.log('akidasd')
        setLoader(false)
      });
  }

  const mountSelectDiscountProduct = (data) => {
    let arrayDisountGroup = [{
      value: null,
      label: '_SEM DESCONTO_',
    }]
    data.forEach(item => {
      arrayDisountGroup.push(
        {
          value: String(item.id),
          label: item.name,
        }
      )

    });

    return arrayDisountGroup;
  }

  const submitEdit = (values) => {
    const payload = values
    dispatch(ProductModelsActions.putProductModelsEditRequest(payload))
  }

  const handleSelectChangeDiscountProduct = (value) => {
    // Encontre a opção selecionada com base no valor
    const selectedOption = dataDiscountProduct.find((option) => option.value === value);
    setSelectedDiscountProduct(selectedOption); // Atualize o estado com a nova opção selecionada
  };

  //Render
  return (
    <>
      <div className='p-3 content-box-form'>
        <Formik
          initialValues={{
            id: dataShow?.id,
            name: dataShow?.name,
            freight: dataShow?.freight,
            code: dataShow?.code,
            machine_type_id: dataShow?.machine_type_id,
            deleted_at: dataShow?.deleted_at,
            discount_product_id: (dataShow?.discount_product_id == null) ? null : dataShow.discount_product_id,
          }}
          validationSchema={ShowSchema}
          onSubmit={(values) => (
            submitEdit(values)
          )
          }
        >
          {({ setFieldValue, handleSubmit, values, errors, touched }) => (
            <>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={24}>
                  <label className='px-2 ant-form-item-required' htmlFor="name">Nome</label>
                  <Input
                    className='input-default'
                    value={values.name}
                    size="Small"
                    placeholder="Nome"
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2 ant-form-item-required' htmlFor="name">Frete<b className='text-danger'>* config. no Protheus</b></label>
                  <Input
                    className='input-default'
                    value={values.freight}
                    size="Small"
                    placeholder="Frete"
                  />
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="discount_product">Desconto Máquina</label>
                  <div className='my-1'>
                    <Select
                      showSearch
                      size="medium"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Selecione o Grupo de Desconto"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      value={selectedDiscountProduct.value !== null ? selectedDiscountProduct.value : null}
                      options={dataDiscountProduct}
                      onChange={(e) => (
                        setFieldValue('discount_product_id', parseInt(e)),
                        handleSelectChangeDiscountProduct(e)
                      )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='my-2' span={24}>
                  <Button
                    className='m-2'
                    disabled={(loadingProductModelsList) ? true : false}
                    loading={(loadingProductModelsList) ? true : false}
                    onClick={() => handleSubmit(values)}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
              {
                values.deleted_at !== null &&
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={24}>
                    <label className='px-2 ant-form-item-required' htmlFor="deleted_at">Deletado em</label>
                    <Input
                      className='input-default'
                      value={convertDateBR(values.deleted_at)}
                      size="Small"
                      placeholder="Data do Delete"
                    />
                  </Col>
                </Row>
              }
            </>
          )}
        </Formik >
      </div >
    </>
  );
};

export default Show;