import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as MessagesActions,
  Types as MessagesTypes,
} from '../../reducers/messages';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listMessages({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/message?page=${payload?.page}
      &title=${payload?.title}
      &message=${payload?.message}
      &type=${payload?.type}
      ${(payload?.start_date && payload?.end_date) &&
      `&start_date=${payload?.start_date}&end_date=${payload?.end_date}`
      }
      `
    );
    yield put(MessagesActions.setUpdateMessages(false));
    yield put(MessagesActions.getMessagesListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MessagesActions.getMessagesListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addMessages({ payload }) {
  try {
    yield call(api.post, '/admin/message', payload);
    yield put(MessagesActions.setUpdateMessages(true));
    yield put(MessagesActions.postMessagesAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MessagesActions.postMessagesAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editMessages({ payload }) {
  try {
    yield call(api.put, `/admin/message/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(MessagesActions.setUpdateMessages(true));
    yield put(MessagesActions.putMessagesEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MessagesActions.putMessagesEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteMessages({ payload }) {
  try {
    yield call(api.delete, `/admin/message/${payload?.id}`);
    // console.log('data store', data)
    yield put(MessagesActions.setUpdateMessages(true));
    yield put(MessagesActions.postMessagesAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MessagesActions.deleteMessagesDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listMessagesWatcher() {
  yield takeLatest(MessagesTypes.GET_MESSAGES_LIST_REQUEST, listMessages);
}

export function* addMessagesWatcher() {
  yield takeLatest(MessagesTypes.POST_MESSAGES_ADD_REQUEST, addMessages);
}

export function* editMessagesWatcher() {
  yield takeLatest(MessagesTypes.PUT_MESSAGES_EDIT_REQUEST, editMessages);
}

export function* deleteMessagesWatcher() {
  yield takeLatest(MessagesTypes.DELETE_MESSAGES_DEL_REQUEST, deleteMessages);
}

export default function* rootSaga() {
  yield all([
    fork(listMessagesWatcher),
    fork(addMessagesWatcher),
    fork(editMessagesWatcher),
    fork(deleteMessagesWatcher),
  ]);
}
