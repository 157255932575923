import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import {
  Creators as UserActions,
  Types as UserTypes,
} from '../../reducers/user';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* getUser() {
  try {
    const { data } = yield call(
      api.get,
      '/admin/profile'
    );
    yield put(UserActions.getUserProfileRequestSuccess(data));
    // toast.success("User Obtido com sucesso", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(UserActions.getUserProfileRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* updatePasswordUser({ payload }) {
  try {
    const { data } = yield call(
      api.post,
      '/admin/change_password',
      payload
    );
    yield put(UserActions.postUpdatePasswordRequestSuccess(data));
    toast.success("Senha atualizada com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(UserActions.postUpdatePasswordRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* getUserWatcher() {
  yield takeLatest(UserTypes.GET_USERPROFILE_REQUEST, getUser);
}

export function* updatePasswordUserWatcher() {
  yield takeLatest(UserTypes.POST_USER_UPDATEPASSWORD_REQUEST, updatePasswordUser);
}

export default function* rootSaga() {
  yield all([
    fork(getUserWatcher),
    fork(updatePasswordUserWatcher),
  ]);
}
