import React, { useState, useEffect } from 'react'
import { Breadcrumb, Row, Col, Button, Input, Layout, theme, Modal, Space, Table, Drawer, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import api from '../../services/api';
import { toast } from 'react-toastify';

import {
  CloseOutlined,
  LoadingOutlined,
  ExclamationCircleFilled,
  CheckOutlined
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as EmployeesActions } from '../../store/reducers/employees';
import { Creators as RefreshActions } from '../../store/reducers/refresh';

//Styles And Helpers
import { convertDateBR } from '../../helpers/index'

//Contexts

//Components
import FooterDefault from '../../components/LayoutDefault/FooterDefault'
import Edit from './actions/Edit';

//Variables
const filterListSchema = yup.object().shape({
  email: yup.string()
    .min(2, 'O campo deve conter no mínimo 2 caractéres'),
});
const { Content } = Layout;
const { confirm } = Modal;

const Employees = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { forgetErrorDataForgetPassword, dataForgetPassword, loadingForgetPassword, dataList, loadingList, dataListUpdate } = useSelector((state) => (state.employees))

  //States Local
  const {
    token: { colorBgContainer, colorError, colorInfo },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const title = 'Vendedores'
  const [openTypeContentDrawer, setOpenTypeContentDrawer] = useState({
    edit: {
      status: true,
      title: `Editar ${title}`,
      dataEdit: {}
    }
  });
  const [filters, setFilters] = useState({ first_name: '', last_name: '', email: '' });

  //Context

  //Cicle of Life
  useEffect(() => {
    document.title = 'Vendedores - Iguaçu Máquinas';
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  useEffect(() => {
    updateDataList('1')
  }, []);

  useEffect(() => {
    if (dataListUpdate) {
      updateDataList('1')
    }
  }, [dataListUpdate]);

  useEffect(() => {
    if (forgetErrorDataForgetPassword) {
      toast.error(`Erros ${JSON.stringify(forgetErrorDataForgetPassword)}`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      dispatch(EmployeesActions.clearForguetPasswordRequest())
    }
  }, [forgetErrorDataForgetPassword]);

  useEffect(() => {
    if (dataForgetPassword) {
      toast.success(`${dataForgetPassword?.message}`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      dispatch(EmployeesActions.clearForguetPasswordRequest())
      
    }
  }, [dataForgetPassword]);

  //Functions

  // async function logoutAllDevices() {

  //   const token = localStorage.getItem('iguacu:token');
  //   return fetch(`${api.defaults.baseURL}/admin/logout-all-devices`, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${token}`
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       toast.success("Todos os usuários do aplicativos foram deslogados", {
  //         position: toast.POSITION.BOTTOM_RIGHT
  //       });
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status === 401) {
  //         dispatch(RefreshActions.setAlertModalExperedLogin(true));
  //       }
  //     })
  //     .finally(() => {

  //     });
  // }

  const updateDataList = (selectedPage) => {
    let payloadList = {
      page: selectedPage,
      first_name: filters.first_name,
      last_name: filters.last_name,
      email: filters.email
    }
    // console.log('payloadList', payloadList)
    dispatch(EmployeesActions.getEmployeesListRequest(payloadList))
  }

  //---Filter
  const updateFilter = () => {
    updateDataList(1)
    setOpenModalFilter(false)
  }

  const setFieldFilter = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const resetFilters = () => {
    setFilters({ first_name: '', last_name: '', email: '' });
    let payloadList = {
      page: '1',
      first_name: '',
      last_name: '',
      email: ''
    }

    // console.log('payloadList', payloadList)
    dispatch(EmployeesActions.getEmployeesListRequest(payloadList))
  }

  const showModalFilter = () => {
    setOpenModalFilter(true);
  }
  const hideModalFilter = () => {
    setOpenModalFilter(false);
  }

  // const showDeleteConfirm = (data) => {
  //   confirm({
  //     title: `Tem certeza que deseja deletar ?`,
  //     icon: <ExclamationCircleFilled />,
  //     okText: 'SIM',
  //     okType: '',
  //     cancelText: 'NÃO',
  //     onOk() {
  //       // console.log('OK');
  //       dispatch(EmployeesActions.deleteEmployeesDelRequest({ id: data?.id }))
  //     },
  //   });
  // };

  const sendForquetPassword = (email) => {
    confirm({
      title: `Tem certeza que deseja enviar email de recuperação de senha para ${email} ?`,
      icon: <ExclamationCircleFilled />,
      okText: 'SIM',
      okType: '',
      cancelText: 'NÃO',
      onOk() {
        // Email validation regex pattern
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Set isValid state based on email validity
        if (email && emailRegex.test(email)) {
          const payload = {
            email: email
          }
          dispatch(EmployeesActions.forguetPasswordRequest(payload))
        } else {
          toast.error("Email digitado está incorreto", {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
      },
    });
  };

  //---Drawer Actions Adicionar || Deletar
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setOpenTypeContentDrawer({
      edit: {
        status: false,
        title: `Editar ${title}`,
        dataEdit: {}
      }
    })
  };

  const handleChangeTypeContentDrawer = (name, dataEdit = {}) => {
    if (name === 'add') {
      setOpenTypeContentDrawer({
        edit: {
          status: false,
          title: `Editar ${title}`,
          dataEdit: {}
        }
      })
    } else if (name === 'edit') {
      setOpenTypeContentDrawer({
        edit: {
          status: true,
          title: `Editar ${title}`,
          dataEdit: dataEdit
        }
      })
    }
    showDrawer()
  }

  const getTitle = () => {
    if (openTypeContentDrawer?.add?.status) {
      return openTypeContentDrawer?.add?.title
    } else if (openTypeContentDrawer?.edit?.status) {
      return openTypeContentDrawer?.edit?.title
    }
  }

  //---Configurations Columns Table
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Sobrenome',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Alçada Desconto',
      dataIndex: 'discount_group',
      key: 'discount_group',
      render: (text, record, index) =>
        <div >
          {record.discount_group.name}
        </div>
      ,
    },
    {
      title: 'Filial',
      dataIndex: 'city',
      key: 'city',
      render: (text, record, index) =>
        <>
          <div>
            {record?.freight?.city}
          </div>
          {/* <div>
            {converterforReal(record?.freight?.freight)}
          </div>
          <div>
            {converterforReal(record?.freight?.fees_boarding)}
          </div> */}
        </>
      ,
    },
    {
      title: 'Ativo',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text, i) =>
        <div >
          {(text === 1) ?
            <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
            :
            <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
          }
        </div>
      ,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        <Space size="middle" >
          {
            record?.deleted_at !== null ?
              <div className='flex-column justify-content-center '>
                <div className='align-items-center'>
                  {`Deletado em:`}
                </div>
                <div className='justify-content-center'>
                  {`${convertDateBR(record?.deleted_at)}`}
                </div>
              </div>
              :
              <>
                <Button
                  size='small' type="primary" style={{ background: colorInfo }}
                  onClick={() => handleChangeTypeContentDrawer('edit', record)}
                >
                  Editar
                </Button>
                <Button
                  size='small' type="primary" style={{ background: colorInfo }}
                  onClick={() => sendForquetPassword(record?.email)}
                >
                  {
                    (loadingForgetPassword) ?
                      <LoadingOutlined />
                      :
                      'Reset Senha'
                  }
                </Button>
                {/* <Button
                  size='small' type="primary" style={{ background: colorError }}
                  onClick={() => showDeleteConfirm(record)}
                >
                  Deletar
                </Button> */}
              </>
          }
        </Space >
      ),
    },
  ];

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{ title: 'Vendedores' }]}
        />
        <div
          className='px-4 py-3'
          style={{
            background: colorBgContainer,
          }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={showModalFilter}
                >
                  Adicionar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    resetFilters()
                  }}
                  style={{
                    background: colorError,
                  }}
                  type='primary'
                >
                  Limpar Filtros
                </Button>

              </div>
              <div className='p-2'>
                {/* <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={() => logoutAllDevices()}
                >
                  Deslogar todos vendedores
                </Button> */}
              </div>
            </div>
          </div>
          <div className='table-content'>
            <Table
              rowClassName={(record, index) => record?.deleted_at !== null ? 'table-row-alerted' : ''}
              pagination={false} loading={(loadingList) && { indicator: <LoadingOutlined /> }} rowKey="id" columns={columns} dataSource={dataList?.data} />
          </div>
          {
            dataList?.last_page > 1 &&
            <div className='m-3 text-right'>
              <Pagination
                pageSize={dataList?.per_page}
                showSizeChanger={false}
                showTotal={(total, range) => `${total} itens`}
                onChange={(e) => updateDataList(e)}
                current={dataList?.current_page}
                defaultPageSize={`${dataList?.per_page}`}
                total={dataList?.total}
              />
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Filtros"
          open={openModalFilter}
          footer={null}
          onCancel={hideModalFilter}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                first_name: filters?.first_name,
                last_name: filters?.last_name,
                email: filters?.email
              }}
              validationSchema={filterListSchema}
              onSubmit={() => (
                updateFilter()
              )
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="first_name">Nome</label>
                      <Input
                        className='input-default'
                        value={filters.first_name}
                        onChange={(e) => setFieldFilter('first_name', e.target.value)}
                        size="large"
                        placeholder="Nome"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="last_name">Sobrenome</label>
                      <Input
                        className='input-default'
                        value={filters.last_name}
                        onChange={(e) => setFieldFilter('last_name', e.target.value)}
                        size="large"
                        placeholder="Sobrenome"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='p-2' htmlFor="email">Email</label>
                      <Input
                        className='input-default'
                        value={filters.email}
                        onChange={(e) => setFieldFilter('email', e.target.value)}
                        type='email'
                        size="large"
                        placeholder="Email"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        size='small'
                        className='m-2'
                        disabled={(loadingList) ? true : false}
                        loading={(loadingList) ? true : false}
                        onClick={() => handleSubmit(values)}
                        type="primary"
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>

        {/* Modal Actions Edit | Add */}
        <Drawer
          title={getTitle()}
          placement={'right'}
          closable={true}
          onClose={onCloseDrawer}
          open={openDrawer}
          key={'right'}
          closeIcon={<CloseOutlined />}
          width={'40%'}
        >
          <>
            {
              (openTypeContentDrawer?.edit.status) &&
              <>
                <Edit dataEdit={openTypeContentDrawer?.edit?.dataEdit}></Edit>
              </>
            }
          </>
        </Drawer>

        <FooterDefault />
      </Content>
    </>
  );
};

export default Employees;