import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Row, Col, Input, Layout, theme, Modal, Space, Table, Drawer, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Formik } from 'formik';

import {
  CloseOutlined,
  LoadingOutlined,
  ExclamationCircleFilled,
  CheckOutlined
} from '@ant-design/icons';
import * as yup from 'yup';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { format } from 'date-fns';

//Dispatchs
import { Creators as MessagesActions } from '../../store/reducers/messages';
import { Creators as RefreshActions } from '../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components
import FooterDefault from '../../components/LayoutDefault/FooterDefault'
import Add from './actions/Add';
import Edit from './actions/Edit';
import { convertDateBR } from '../../helpers';

//Variables
const filterListSchema = yup.object().shape({
  email: yup.string()
    .min(2, 'O campo deve conter no mínimo 2 caractéres'),
});

const { Content } = Layout;
const { TextArea } = Input;
const { confirm } = Modal;

const Messages = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataList, loadingList, dataListUpdate } = useSelector((state) => (state.messages))

  //States Local
  const {
    token: { colorBgContainer, colorSuccess, colorError, colorInfo },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const title = 'Mensagens aos Vendedores'
  const [openTypeContentDrawer, setOpenTypeContentDrawer] = useState({
    edit: {
      status: true,
      title: `Editar ${title}`,
      dataEdit: {}
    },
    add: {
      status: false,
      title: `Adicionar ${title}`
    },
  });
  const [filters, setFilters] = useState({ title: '', message: '', type: '', start_date: '', end_date: '' });

  //Context

  //Cicle of Life
  useEffect(() => {
    document.title = 'Mensagens - Iguaçu Máquinas';
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  useEffect(() => {
    updateDataList('1')
  }, []);

  useEffect(() => {
    if (dataListUpdate) {
      updateDataList('1')
    }
  }, [dataListUpdate]);

  //Functions
  const updateDataList = (selectedPage) => {
    let payloadList = {
      page: selectedPage,
      title: filters?.title,
      message: filters?.message,
      type: filters?.type === "fixo" ? 'fix' : filters?.type,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate)
    }
    dispatch(MessagesActions.getMessagesListRequest(payloadList))
  }

  //---Filter
  const resetFilters = () => {
    setStartDate('')
    setEndDate('')
    setFilters({ title: '', message: '', type: '', start_date: '', end_date: '' });
    let payloadList = {
      page: '1',
      title: '',
      message: '',
      type: '',
      start_date: '',
      end_date: ''
    }

    // console.log('payloadList', payloadList)
    dispatch(MessagesActions.getMessagesListRequest(payloadList))
  }

  const updateFilter = () => {
    updateDataList(1)
    setOpenModalFilter(false)
  }

  const setFieldFilter = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }
  const showDeleteConfirm = (data) => {
    confirm({
      title: `Tem certeza que deseja deletar ?`,
      icon: <ExclamationCircleFilled />,
      content: ` ${data?.title}`,
      okText: 'SIM',
      okType: '',
      cancelText: 'NÃO',
      onOk() {
        dispatch(MessagesActions.deleteMessagesDelRequest({ id: data?.id }))
      },
    });
  };

  //---Drawer Actions Adicionar || Deletar
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setOpenTypeContentDrawer({
      edit: {
        status: false,
        title: `Editar ${title}`,
        dataEdit: {}
      },
      add: {
        status: false,
        title: `Adicionar ${title}`
      },
    })
  };

  const handleChangeTypeContentDrawer = (name, dataEdit = {}) => {
    if (name === 'add') {
      setOpenTypeContentDrawer({
        edit: {
          status: false,
          title: `Editar ${title}`,
          dataEdit: {}
        },
        add: {
          status: true,
          title: `Adicionar ${title}`
        },
      })
    } else if (name === 'edit') {
      setOpenTypeContentDrawer({
        edit: {
          status: true,
          title: `Editar ${title}`,
          dataEdit: dataEdit
        },
        add: {
          status: false,
          title: `Adicionar ${title}`
        },
      })
    }
    showDrawer()
  }

  const showModalFilter = () => {
    setOpenModalFilter(true);
  }
  const hideModalFilter = () => {
    setOpenModalFilter(false);
  }

  const getTitle = () => {
    if (openTypeContentDrawer?.add?.status) {
      return openTypeContentDrawer?.add?.title
    } else if (openTypeContentDrawer?.edit?.status) {
      return openTypeContentDrawer?.edit?.title
    }
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const formatDate = (date) => {
    return date ? format(date, 'yyyy-MM-dd') : '';
  };

  function CustomDatePickerInputInit({ value, onClick }) {
    return (
      <input
        className='input-default'
        type="text"
        onClick={onClick}
        value={value}
        placeholder="Data Inicial"
        // readOnly
        style={{
          padding: '6px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          border: '1px solid #d3d3d3',
        }}
      />
    );
  }

  function CustomDatePickerInputFinal({ value, onClick }) {
    return (
      <input
        className='input-default'
        type="text"
        onClick={onClick}
        value={value}
        placeholder="Data Final"
        // readOnly
        style={{
          padding: '6px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          border: '1px solid #d3d3d3',
        }}
      />
    );
  }

  //---Configurations Columns Table
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.title}
        </div>
      ,
    },
    {
      title: 'Mensagem',
      dataIndex: 'message',
      key: 'message',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.message}
        </div>
      ,
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.type === "popup" ? 'popup' : 'fixo'}
        </div>
      ,
    },
    {
      title: 'Período Vigente',
      dataIndex: 'vigentDate',
      key: 'vigentDate',
      render: (text, record, i) =>
        <>
          {
            (record?.start_date && record?.end_date) &&
            <div className='row px-2'>
              De {(record?.start_date) && convertDateBR(record?.start_date)} a {(record?.end_date) && convertDateBR(record?.end_date)}
            </div>
          }
        </>
      ,
    },
    {
      title: 'Data Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record, i) =>
        <div className='row px-2'>
          {convertDateBR(record?.created_at)}
        </div>
      ,
    },
    {
      title: 'Ativo',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text, i) =>
        <div >
          {(text === 1) ?
            <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
            :
            <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
          }
        </div>
      ,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        <Space size="middle" >
          {
            record?.deleted_at !== null ?
              <div className='flex-column justify-content-center '>
                <div className='align-items-center'>
                  {`Deletado em:`}
                </div>
                <div className='justify-content-center'>
                  {`${convertDateBR(record?.deleted_at)}`}
                </div>
              </div>
              :
              <>
                <Button
                  size='small' type="primary" style={{ background: colorInfo }}
                  onClick={() => handleChangeTypeContentDrawer('edit', record)}
                >
                  editar
                </Button>
                <Button
                  size='small' type="primary" style={{ background: colorError }}
                  onClick={() => showDeleteConfirm(record)}
                >
                  Deletar
                </Button>
              </>
          }

        </Space >
      ),
    },
  ];

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{ title: title }]}
        />
        <div
          className='px-4 py-3'
          style={{
            background: colorBgContainer,
          }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={showModalFilter}
                >
                  Adicionar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    resetFilters()
                  }}
                  style={{
                    background: colorError,
                  }}
                  type='primary'
                >
                  Limpar Filtros
                </Button>

              </div>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    handleChangeTypeContentDrawer('add', true)
                  }}
                  style={{
                    background: colorSuccess,
                  }}
                  type='primary'
                >
                  Adicionar
                </Button>
              </div>
            </div>
          </div>
          <div className='table-content'>
            <Table
              rowClassName={(record, index) => record?.deleted_at !== null ? 'table-row-alerted' : ''}
              pagination={false} loading={(loadingList) && { indicator: <LoadingOutlined /> }} rowKey="id" columns={columns} dataSource={dataList?.data}
            />
          </div>
          {
            dataList?.last_page > 1 &&
            <div className='m-3 text-right'>
              <Pagination
                pageSize={dataList?.per_page}
                showSizeChanger={false}
                showTotal={(total, range) => `${total} itens`}
                onChange={(e) => updateDataList(e)}
                current={dataList?.current_page}
                defaultPageSize={`${dataList?.per_page}`}
                total={dataList?.total}
              />
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Filtros"
          open={openModalFilter}
          footer={null}
          onCancel={hideModalFilter}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                title: filters?.title,
                message: filters?.message,
                type: filters?.type
              }}
              validationSchema={filterListSchema}
              onSubmit={() => (
                updateFilter()
              )
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="title">Title</label>
                      <Input
                        className='input-default'
                        value={filters.title}
                        onChange={(e) => setFieldFilter('title', e.target.value)}
                        size="large"
                        placeholder="Titulo"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='p-2' htmlFor="message">Mensagem</label>
                      <TextArea
                        className='input-default'
                        rows={4}
                        onChange={(e) => setFieldFilter('message', e.target.value)}
                        value={filters.message}
                        placeholder="Mensagem"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="type">Tipo</label>
                      <Input
                        className='input-default'
                        value={filters.type}
                        onChange={(e) => setFieldFilter('type', e.target.value)}
                        size="large"
                        placeholder="Tipo"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="type">Data Inicial / Data Final</label>
                      <DatePicker
                        customInput={<CustomDatePickerInputInit />} // Usa o componente personalizado // Usa o input personalizado
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Data de Início"
                        locale="pt-BR" // Define o locale para português do Brasil
                        dateFormat="dd/MM/yyyy"
                      />
                      <DatePicker
                        customInput={<CustomDatePickerInputFinal />} // Usa o componente personalizado // Usa o input personalizado
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="Data de Término"
                        locale="pt-BR" // Define o locale para português do Brasil
                        dateFormat="dd/MM/yyyy"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        size='small'
                        className='m-2'
                        disabled={(loadingList) ? true : false}
                        loading={(loadingList) ? true : false}
                        onClick={() => handleSubmit(values)}
                        type="primary"
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>

        {/* Modal Actions Edit | Add */}
        <Drawer
          title={getTitle()}
          className="drawer-messages-show"
          placement={'right'}
          closable={true}
          onClose={onCloseDrawer}
          open={openDrawer}
          key={'right'}
          closeIcon={<CloseOutlined />}
          width={'40%'}
        >
          <>
            {
              (openTypeContentDrawer?.edit.status) &&
              <>
                <Edit dataEdit={openTypeContentDrawer?.edit?.dataEdit}></Edit>
              </>
            }

            {
              (openTypeContentDrawer?.add.status) &&
              <>
                <Add ></Add>
              </>
            }
          </>
        </Drawer>

        <FooterDefault />
      </Content>
    </>
  );
};

export default Messages;