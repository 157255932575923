import React, { useState, useContext, useEffect, useMemo, useRef } from 'react'
import { Popconfirm, Row, Col, Select, Space, Button, Popover, theme } from 'antd';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import api from '../../../../services/api';

import { PlusOutlined, LoadingOutlined, EyeOutlined, EyeInvisibleOutlined, CloseOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

//Dispatchs
import { Creators as RefreshActions } from '../../../../store/reducers/refresh';

//Styles And Helpers
import { converterforReal } from '../../../../helpers';

//Contexts
import { ProductsContext } from '../../../../contexts/ProductsContext';
import { ProductsWrapper } from '../../../../styles/globalStyles';

//Components

//Variables

const { Option, OptGroup } = Select;

const Groups = () => {

  //Dispatchs
  const dispatch = useDispatch();
  //States Reducer

  //States Local
  const {
    token: { colorError, colorInfo },
  } = theme.useToken();
  const [loader, setLoader] = useState(false);

  //Context
  const {
    productsContext,
  } = useContext(ProductsContext)

  //Cicle of Life
  useEffect(() => {
    fetchGroupList()
  }, []);

  //Functions

  async function fetchGroupList() {

    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/products/${productsContext?.groups?.data?.GroupsId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setlistGroupsProducts(response)
        // console.log('response', response)
        mountArraySubitens(response)
        setBoxSubItems(response?.packs)
      })
      .catch((error) => {
        // console.log('teste', error)
        if (error.response && error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  async function fetchAddDependeces(payload) {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)

    return fetch(`${api.defaults.baseURL}/admin/products-packs-dependencies`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((response) => {
        fetchGroupList()
        // console.log('response', response)
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
        setLoader(false)
      });
  }

  async function fetchDeleteDependece(id) {
    const token = localStorage.getItem('iguacu:token');
    // setLoader(true)

    return fetch(`${api.defaults.baseURL}/admin/products-packs-dependencies/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        // 'Content-type': 'application/json; charset=UTF-8'
      },
      body: ""
    })
      .then((response) => {
        fetchGroupList()
        // console.log('response', response)
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
        // setLoader(false)
      });
  }

  async function fetchAddRestrictions(payload) {

    const token = localStorage.getItem('iguacu:token');
    setLoader(true)

    return fetch(`${api.defaults.baseURL}/admin/products-packs-restrictions`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((response) => {
        fetchGroupList()
        // console.log('response', response)
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
        setLoader(false)
      });
  }

  async function fetchDeleteRestrictions(id) {
    const token = localStorage.getItem('iguacu:token');
    // setLoader(true)

    return fetch(`${api.defaults.baseURL}/admin/products-packs-restrictions/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        // 'Content-type': 'application/json; charset=UTF-8'
      },
      body: ""
    })
      .then((response) => {
        fetchGroupList()
        // console.log('response', response)
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
        // setLoader(false)
      });
  }

  const mountArraySubitens = (data) => {
    const extractedData = [];
    data?.packs?.forEach((item, i) => {
      let gr = null
      if (item.sub_items_groups) {
        gr = {
          product_pack_id: item?.id,
          group: {
            indice: i,
            id: item?.sub_items_groups?.id,
            name: item?.sub_items_groups?.name,
            code: item?.sub_items_groups?.code,
          },
          subItems: [

          ],
          data: item,
        }
      }
      if (item.sub_items_groups && item.sub_items_groups.sub_items) {
        item.sub_items_groups?.sub_items.forEach((subItem, i) => {
          gr.subItems[i] = {
            indice: i,
            id: subItem?.id,
            name: subItem?.name,
            code: subItem?.code,
            idjvj: subItem?.idjvj,
          }
        });
      }
      extractedData.push(gr);
      return
    })
    setBoxDataSerach(extractedData)
    return
  };

  function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <LoadingOutlined /> : null}
        {...props}
        options={options}
      />
    );
  }

  async function fetchGroupListProduct(text) {
    const token = localStorage.getItem('iguacu:token');
    return fetch(`${api.defaults.baseURL}/admin/sub_item_groups?name=${text}&type=search`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        // 'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then((response) => response.json())
      .then((response) => {
        return response.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
      })
  }

  async function fetchGroupAdd(id_subitem) {
    const token = localStorage.getItem('iguacu:token');

    const payload = { "product_id": productsContext?.groups?.data?.GroupsId, "sub_items_groups": [id_subitem] }
    return fetch(`${api.defaults.baseURL}/admin/products-packs`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then(() => {
        toast.success("Adicionado com sucesso", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchGroupList()
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
      });
  }

  const confirmDelete = (id) => {
    fetchGroupDelete(id)
  };

  const cancelDelete = () => { };

  async function fetchGroupDelete(id_subitem) {
    const token = localStorage.getItem('iguacu:token');
    // setLoader(true)

    return fetch(`${api.defaults.baseURL}/admin/products-packs/${id_subitem}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: ''
    })
      .then(() => {
        toast.success("Deletado com sucesso", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchGroupList()
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
        // setLoader(false)
      });
  }

  async function fetchSubitensVisible(id) {

    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/products-packs/visibility/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        toast.success("Alterado com sucesso", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchGroupList()
      }
      ).finally(() => {
        setLoader(false)
      })
  }

  const [selectedValue, setSelectedValue] = useState([]);
  const [listGroupsProducts, setlistGroupsProducts] = useState([]);
  const [boxSubItems, setBoxSubItems] = useState([]);
  const [boxDataSearch, setBoxDataSerach] = useState([]);

  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const clickSelectDependecesAdd = (selectedValue, data, arrayValidate) => {
    let message = false
    let message_machGroup = false

    //Bloqueia o usuário de adicionar mais de uma dependência por grupo
    data?.data?.arrayItensSelected?.map((itemSelected) => {
      // console.log('itemSelected?.selected_subitem?.group?.id', itemSelected?.selected_subitem?.group?.id)
      if (itemSelected?.selected_subitem?.group?.id === data?.data?.group_id_selected) {
        message_machGroup = true
      }

      return true
    })

    //Não paermitir adicionar dependência do mesmo grupo
    // if (message_machGroup) {
    //   toast.error("Não é possísvel adicionar mais de uma dependência do mesmo grupo.", {
    //     position: toast.POSITION.BOTTOM_RIGHT
    //   });
    //   return false;
    // }

    //Percorre o array de itens em restrições para saber se já não foi adicionado a restrição
    arrayValidate?.map((item) => {
      if (selectedValue.value === item?.selected_subitem?.id) {
        message = true
      }
      return true
    })

    if (message) {
      toast.error("Este item está adicionado em 'Não pode estar jundo de', por favor remova-o antes de adicionar.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      return false;
    }


    const payload = {
      "product_pack_id": data?.data?.product_pack_id,
      "selected_sub_item_id": selectedValue.value,
      "sub_items": [data?.data?.sub_item_id]
    }

    fetchAddDependeces(payload)
  };
  const clickSelectRestrictionsAdd = (selectedValue, data, arrayValidate) => {
    let message = false
    //Percorre o array de itens em restrições para saber se já não foi adicionado e restrição
    arrayValidate?.map((item) => {
      if (selectedValue.value === item?.selected_subitem?.id) {
        message = true
      }
      return true
    })

    if (message) {
      toast.error("Este item está adicionado em 'Acompanhado de', por favor remova-o antes de adicionar.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      return false;
    }

    // console.log('Valor do product_pack_id', data?.data?.product_pack_id)
    // console.log('Valor do selected_subitem_id', selectedValue?.value)
    // console.log('Valor do sub_item_id', data?.data?.sub_item_id)

    const payload = {
      "product_pack_id": data?.data?.product_pack_id,
      "selected_sub_item_id": selectedValue.value,
      "sub_items": [data?.data?.sub_item_id]
    }
    fetchAddRestrictions(payload)
  };

  const filteredOptions = boxDataSearch.filter((optionGroup) =>
    optionGroup.subItems.some((subItem) => {
      return subItem.name.toLowerCase().includes(searchValue.toLowerCase())
    }
    )
  );

  const content = (data, product_pack_id, type_click, arrayItensSelected, arrayValidate) => {
    return (
      <div>
        <Select
          autoFocus={true}
          labelInValue
          style={{ width: 340 }}
          showSearch
          placeholder={'Buscar...'}
          filterOption={false}
          loading={loader}
          onSearch={handleSearch}
          onChange={(e, option) => (type_click === 'dependeces') ? clickSelectDependecesAdd(e, option, arrayValidate) : clickSelectRestrictionsAdd(e, option, arrayValidate)}
          value={null} // Clear the selected value
        >
          {filteredOptions.map((optionGroup, i) => (
            <OptGroup key={i} label={`#${optionGroup?.group?.code} - ${optionGroup?.group?.name}`}>
              {optionGroup?.subItems.map((subItem) => (
                <Option
                  key={subItem.id}
                  disabled={(data?.group?.id === optionGroup?.group?.id) ? true : false}
                  value={subItem.id}
                  data={{
                    'product_pack_id': product_pack_id,
                    'sub_item_id': data?.id,
                    'group_id_selected': optionGroup?.group?.id,
                    'arrayItensSelected': arrayItensSelected,
                    'data_validator': arrayValidate
                  }}>
                  <b style={{ fontSize: 12 }}>#{subItem?.idjvj} - {subItem.name} </b>
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </div>
    );
  }

  //Render
  return (
    <>
      <ProductsWrapper>
        <h2 className='mx-3'><b>{listGroupsProducts?.model?.code} - {listGroupsProducts?.serie} - ({listGroupsProducts?.basecode})</b></h2>
        <div className='p-3 content-box-form container-optionals'>
          <h2>Grupos</h2>
          <p>Visualizar grupos de subitens que farão parte deste pacote. OBS: Vindos do Robô no Importador Protheus </p>
          {/* <Row gutter={[16, 16]}>
            <Col className='my-2' span={12}>
              <div className='flex-row'>
                <DebounceSelect
                  allowClear={true}
                  autoClearSearchValue={true}
                  showSearch={true}
                  value={selectedValue}
                  placeholder="Buscar Grupos..."
                  fetchOptions={fetchGroupListProduct}
                  onChange={(newValue) => {
                    setSelectedValue(newValue);
                  }}
                  style={{ width: '85%' }}
                />
                <Button
                  style={{ margin: 10 }}
                  onClick={() => fetchGroupAdd(selectedValue.value)}
                  title='Adicionar o Grupo de Opcional' type="primary"
                  icon={<PlusOutlined />}
                  size={'small'}
                />
              </div>
            </Col>
          </Row> */}
          <table className="zebra-table">
            <thead>
              <tr>
                <th >Grupo de Itens</th>
                <th style={{ color: colorInfo }}>Acompanhado de</th>
                <th style={{ color: colorError }}>Não pode estar jundo de</th>
                {/* <th>Ações</th> */}
              </tr>
            </thead>
            <tbody>
              {loader &&
                <tr>
                  <td colSpan="4" style={{ width: '20%' }}>
                    <div className='p-2'>
                      <LoadingOutlined />
                    </div>
                  </td>
                </tr>
              }
              {
                listGroupsProducts?.packs?.map((itemPack, i) => (
                  <tr key={i} className={i % 2 === 0 ? 'even' : 'odd'} >
                    <td style={{ width: '20%' }}>
                      <div className='box-group'>
                        {
                          itemPack?.sub_items_groups?.sub_items.length > 0 &&
                          itemPack?.sub_items_groups?.sub_items.map((subitem, index) => (
                            <div key={index} >
                              {
                                index === 0 &&
                                <>
                                  <div className='groups-title'>
                                    #{itemPack?.sub_items_groups?.code}
                                  </div>
                                  <div className='groups-title'>
                                    {itemPack?.sub_items_groups?.name}
                                  </div>
                                </>
                              }
                            </div>
                          ))
                        }
                      </div>
                    </td>
                    <td style={{ width: '37.5%' }}>
                      <div className='box-dependecies-subitens'>
                        {
                          itemPack?.sub_items_groups?.sub_items.length > 0 &&
                          itemPack?.sub_items_groups?.sub_items.map((subitemDependences, index) => (
                            <div key={index + 'subitensd'} className='box-subitens'>
                              <div className='box-subitens-content' style={(subitemDependences?.visibility === 1) ? {} : { background: '#ffc4ca' }} >
                                <div className='subitens-title'>
                                  <div>
                                    #{subitemDependences?.idjvj} - {subitemDependences?.name}
                                  </div>
                                  <div>
                                    {converterforReal(subitemDependences?.price)}
                                  </div>
                                </div>
                                {/* <div className='box-actions-subitens'>
                                  <div className='m-1'>
                                    <Button
                                      type="primary"
                                      onClick={() => fetchSubitensVisible(subitemDependences?.id)}
                                      icon={(subitemDependences?.visibility === 1) ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                      size={'small'}
                                      style={(subitemDependences?.visibility === 1) ? { fontSize: 10, width: 18, height: 18, background: colorInfo } : { fontSize: 10, width: 18, height: 18, background: colorError }}
                                      title='Alterar visibilidade'
                                    />
                                  </div>
                                  <div className='m-1'>
                                    <Popover className='' content={content(subitemDependences, itemPack?.id, 'dependeces', boxSubItems[i].dependencies, boxSubItems[i]?.restrictions)} title="Buscar Opcionais" trigger="click">
                                      <Button
                                        title="Adicionar Acompanhamento"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        size={'small'}
                                        style={{ fontSize: 10, width: 18, height: 18 }}
                                      />
                                    </Popover>
                                  </div>
                                </div> */}
                              </div>
                              <div className='box-dependecies'>
                                <div>
                                  <ul key={i + 'dependeces'}>
                                    {boxSubItems[i].dependencies
                                      ?.sort((a, b) => {
                                        const nameA = a.selected_subitem?.group?.name.toUpperCase() || "";
                                        const nameB = b.selected_subitem?.group?.name.toUpperCase() || "";
                                        return nameA.localeCompare(nameB);
                                      })
                                      .map((dependece, i) => (
                                        dependece?.sub_item_id === subitemDependences?.id &&
                                        <li key={i + 'restictionli'}>
                                          <div key={i + 'd'} className="box">
                                            <div>
                                              <div className='group-subitem-title'>
                                                #{dependece?.selected_subitem?.group?.code} - {dependece?.selected_subitem?.group?.name}
                                              </div>
                                              <b>#{dependece?.selected_subitem?.idjvj} - {dependece?.selected_subitem?.name}</b>
                                            </div>
                                            {/* <div>
                                              <Popconfirm
                                                title="Tem certeza que deseja excluir ?"
                                                onConfirm={() => {
                                                  fetchDeleteDependece(dependece?.id)
                                                }}
                                                // onCancel={cancelDelete}
                                                okText="Sim"
                                                cancelText="Não"
                                              >
                                                <Button
                                                  type="primary"
                                                  icon={<CloseOutlined />}
                                                  size={'small'}
                                                  style={{ fontSize: 8, background: colorError, width: 15, height: 15 }}
                                                  title='Excluir'
                                                />
                                              </Popconfirm>
                                            </div> */}
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </td>

                    <td style={{ width: '37.5%' }}>
                      <div className='box-restrictions-subitens'>
                        {
                          itemPack?.sub_items_groups?.sub_items.length > 0 &&
                          itemPack?.sub_items_groups?.sub_items.map((subitemRestriction, index) => (
                            <div key={index + 'subitense'} className='box-subitens'>
                              <div className='box-subitens-content' style={(subitemRestriction?.visibility === 1) ? {} : { background: '#ffc4ca' }} >
                                <div className='subitens-title'>
                                  <div>
                                    #{subitemRestriction?.idjvj} - {subitemRestriction?.name}
                                  </div>
                                  <div>
                                    {converterforReal(subitemRestriction?.price)}
                                  </div>
                                </div>
                                {/* <div className='box-actions-subitens'>
                                  <div className='m-1'>
                                    <Button
                                      type="primary"
                                      onClick={() => fetchSubitensVisible(subitemRestriction?.id)}
                                      icon={(subitemRestriction?.visibility === 1) ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                      size={'small'}
                                      style={(subitemRestriction?.visibility === 1) ? { fontSize: 10, width: 18, height: 18, background: colorInfo } : { fontSize: 10, width: 18, height: 18, background: colorError }}
                                      title='Alterar visibilidade'
                                    />
                                  </div>
                                  <div className='m-1'>
                                    <Popover className='' content={content(subitemRestriction, itemPack?.id, 'restrictions', boxSubItems[i].restrictions, boxSubItems[i]?.dependencies)} title="Buscar Opcionais" trigger="click">
                                      <Button
                                        title='Adiconar Restrição'
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        size={'small'}
                                        style={{ fontSize: 10, width: 18, height: 18 }}
                                      />
                                    </Popover>
                                  </div>
                                </div> */}
                              </div>
                              <div className='box-restrictions'>
                                <div>
                                  <ul key={i + 'restiction'} >
                                    {boxSubItems[i].restrictions
                                      ?.sort((a, b) => {
                                        const nameA = a.selected_subitem?.group?.name.toUpperCase() || "";
                                        const nameB = b.selected_subitem?.group?.name.toUpperCase() || "";
                                        return nameA.localeCompare(nameB);
                                      })
                                      .map((restiction, i) => (
                                        restiction?.sub_item_id === subitemRestriction?.id &&
                                        <li key={i + 'restictionli'}>
                                          <div key={i + 'd'} className="box">
                                            <div className='p-1'>
                                              <div className='group-subitem-title'>
                                                #{restiction?.selected_subitem?.group?.code} - {restiction?.selected_subitem?.group?.name}
                                              </div>
                                              <b>#{restiction?.selected_subitem?.idjvj} - {restiction?.selected_subitem?.name}</b>
                                            </div>
                                            {/* <div className='p-1'>
                                              <Popconfirm
                                                title="Tem certeza que deseja excluir ?"
                                                onConfirm={() => fetchDeleteRestrictions(restiction?.id)}
                                                // onCancel={cancelDelete}
                                                okText="Sim"
                                                cancelText="Não"
                                              >
                                                <Button
                                                  type="primary"
                                                  icon={<CloseOutlined />}
                                                  size={'small'}
                                                  style={{ fontSize: 8, background: colorError, width: 15, height: 15 }}
                                                  title='Excluir'
                                                />
                                              </Popconfirm>
                                            </div> */}
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </td>

                    {/* <td style={{ width: '5%' }}>
                      <Space size="small" >
                        <Popconfirm
                          title="Tem certeza que deseja excluir ?"
                          onConfirm={() => confirmDelete(itemPack?.id)}
                          onCancel={cancelDelete}
                          okText="Sim"
                          cancelText="Não"
                        >
                          <Button
                            type="primary"
                            icon={<CloseOutlined />}
                            size={'small'}
                            title='Excluir'
                            style={{ fontSize: 10, width: 18, height: 18, background: colorError }}
                          />
                        </Popconfirm>
                      </Space >
                    </td> */}
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div >
      </ProductsWrapper>
    </>
  );
};

export default Groups;