import React, { useEffect } from 'react'
import { Col, Row, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import * as yup from 'yup';

//Dispatchs
import { Creators as BrandsActions } from '../../../../store/reducers/brands';
import { convertDateBR } from '../../../../helpers';

//Styles And Helpers

//Contexts

//Components

//Variables
const ShowSchema = yup.object().shape({

});

const Show = ({ dataShow }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer

  //States Local

  //Context

  //Cicle of Life
  useEffect(() => {

  }, []);

  //Functions
  const submitEdit = (values) => {
    const payload = values
    dispatch(BrandsActions.putAdministratorsEditRequest(payload))
  }

  //Render
  return (
    <>
      <div className='p-3 content-box-form'>
        {/* <p>{JSON.stringify(dataShow)}</p> */}
        <Formik
          initialValues={{
            code: dataShow?.code,
            brand: dataShow?.brand,
            fabric: dataShow?.fabric,
            deleted_at: dataShow?.deleted_at,
          }}
          validationSchema={ShowSchema}
          onSubmit={(values) => (
            submitEdit(values)
          )
          }
        >
          {({ setFieldValue, handleSubmit, values, errors, touched }) => (
            <>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={24}>
                  <label className='px-2 ant-form-item-required' htmlFor="code">Código</label>
                  <Input
                    className='input-default'
                    value={values.code}
                    size="Small"
                    placeholder="Código"
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={24}>
                  <label className='px-2 ant-form-item-required' htmlFor="brand">Marca</label>
                  <Input
                    className='input-default'
                    value={values.brand}
                    size="Small"
                    placeholder="Marca"
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={24}>
                  <label className='px-2 ant-form-item-required' htmlFor="fabric">Fábrica</label>
                  <Input
                    className='input-default'
                    value={values.fabric}
                    size="Small"
                    placeholder="Fábrica"
                  />
                </Col>
              </Row>
              {
                values.deleted_at !== null &&
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={24}>
                    <label className='px-2 ant-form-item-required' htmlFor="deleted_at">Deletado em</label>
                    <Input
                      className='input-default'
                      value={convertDateBR(values.deleted_at)}
                      size="Small"
                      placeholder="Data do Delete"
                    />
                  </Col>
                </Row>
              }
            </>
          )}
        </Formik >
      </div >
    </>
  );
};

export default Show;