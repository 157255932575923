import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as SuppliersActions,
  Types as SuppliersTypes,
} from '../../reducers/suppliers';

import {
  Creators as RefreshActions
} from '../../reducers/refresh';

function* listSuppliers({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/suppliers?page=${payload?.page}&social_reason=${payload?.social_reason}&fantasy_name=${payload?.fantasy_name}&cnpj=${payload?.cnpj}&email=${payload?.email}`
    );
    yield put(SuppliersActions.setUpdateSuppliers(false));
    yield put(SuppliersActions.getSuppliersListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(SuppliersActions.getSuppliersListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addSuppliers({ payload }) {
  try {
    yield call(api.post, '/admin/suppliers', payload);
    yield put(SuppliersActions.setUpdateSuppliers(true));
    yield put(SuppliersActions.postSuppliersAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(SuppliersActions.postSuppliersAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editSuppliers({ payload }) {
  try {
    yield call(api.put, `/admin/suppliers/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(SuppliersActions.setUpdateSuppliers(true));
    yield put(SuppliersActions.putSuppliersEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(SuppliersActions.putSuppliersEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteSuppliers({ payload }) {
  try {
    yield call(api.delete, `/admin/suppliers/${payload?.id}`);
    // console.log('data store', data)
    yield put(SuppliersActions.setUpdateSuppliers(true));
    yield put(SuppliersActions.postSuppliersAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(SuppliersActions.deleteSuppliersDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listSuppliersWatcher() {
  yield takeLatest(SuppliersTypes.GET_SUPPLIERS_LIST_REQUEST, listSuppliers);
}

export function* addSuppliersWatcher() {
  yield takeLatest(SuppliersTypes.POST_SUPPLIERS_ADD_REQUEST, addSuppliers);
}

export function* editSuppliersWatcher() {
  yield takeLatest(SuppliersTypes.PUT_SUPPLIERS_EDIT_REQUEST, editSuppliers);
}

export function* deleteSuppliersWatcher() {
  yield takeLatest(SuppliersTypes.DELETE_SUPPLIERS_DEL_REQUEST, deleteSuppliers);
}

export default function* rootSaga() {
  yield all([
    fork(listSuppliersWatcher),
    fork(addSuppliersWatcher),
    fork(editSuppliersWatcher),
    fork(deleteSuppliersWatcher),
  ]);
}
