import React, { useState, useEffect } from 'react'
import { Breadcrumb, Row, Col, Button, Input, Layout, theme, Modal, Space, Table, Drawer, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  CloseOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as SuppliersActions } from '../../store/reducers/suppliers';
import { Creators as RefreshActions } from '../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components
import FooterDefault from '../../components/LayoutDefault/FooterDefault'
import Show from './actions/Show'

//Variables
const filterListSchema = yup.object().shape({
  email: yup.string()
    .min(2, 'O campo deve conter no mínimo 2 caractéres'),
});
const { Content } = Layout;


const Suppliers = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataList, loadingList, dataListUpdate } = useSelector((state) => (state.suppliers))

  //States Local
  const {
    token: { colorBgContainer, colorError, colorWarning, colorInfo },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const title = 'Fornecedores'
  const [openTypeContentDrawer, setOpenTypeContentDrawer] = useState({
    details: {
      status: true,
      title: `Detalhes ${title}`,
      dataShow: {}
    }
  });
  const [filters, setFilters] = useState({
    social_reason: '',
    fantasy_name: '',
    cnpj: '',
    email: '',
  });

  //Context

  //Cicle of Life
  useEffect(() => {
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  useEffect(() => {
    updateDataList('1')
  }, []);

  useEffect(() => {
    if (dataListUpdate) {
      updateDataList('1')
    }
  }, [dataListUpdate]);


  //Functions
  const updateDataList = (selectedPage) => {

    let payloadList = {
      page: selectedPage,
      social_reason: filters.social_reason,
      fantasy_name: filters.fantasy_name,
      cnpj: filters.cnpj,
      email: filters.email,
    }
    // console.log('payloadList', payloadList)
    dispatch(SuppliersActions.getSuppliersListRequest(payloadList))
  }

  //---Filter
  const updateFilter = () => {
    updateDataList(1)
    setOpenModalFilter(false)
  }

  const setFieldFilter = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const resetFilters = () => {
    setFilters({
      social_reason: '',
      fantasy_name: '',
      cnpj: '',
      email: '',
    });
    let payloadList = {
      page: '1',
      social_reason: '',
      fantasy_name: '',
      cnpj: '',
      email: '',
    }
    // console.log('payloadList', payloadList)
    dispatch(SuppliersActions.getSuppliersListRequest(payloadList))
  }

  const showModalFilter = () => {
    setOpenModalFilter(true);
  }
  const hideModalFilter = () => {
    setOpenModalFilter(false);
  }

  //---Drawer Actions Adicionar || Deletar
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setOpenTypeContentDrawer({
      details: {
        status: false,
        title: `Detalhes ${title}`,
        dataShow: {}
      }
    })
  };

  const handleChangeTypeContentDrawer = (name, dataShow = {}) => {
    if (name === 'detail') {
      setOpenTypeContentDrawer({
        details: {
          status: true,
          title: `Detalhes ${title}`,
          dataShow: dataShow
        }
      })
    }
    showDrawer()
  }

  const getTitle = () => {
    if (openTypeContentDrawer?.details?.status) {
      return openTypeContentDrawer?.details?.title
    }
  }

  //---Configurations Columns Table
  const columns = [
    {
      title: 'Razão Social',
      dataIndex: 'social_reason',
      key: 'social_reason',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.social_reason}
        </div>
      ,
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'fantasy_name',
      key: 'fantasy_name',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.fantasy_name}
        </div>
      ,
    },
    {
      title: 'CPF/CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.cnpj}
        </div>
      ,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.email}
        </div>
      ,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        < Space size="middle" >
          <Button
            size='small' type="primary" style={{ background: colorWarning }}
            onClick={() => handleChangeTypeContentDrawer('detail', record)}
          >
            Detalhes
          </Button>
        </Space >
      ),
    },
  ];

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{ title: 'Fornecedores' }]}
        />
        {/* <p>{JSON.stringify(dataList?.data[0])}</p> */}
        <div
          className='px-4 py-3'
          style={{
            background: colorBgContainer,
          }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={showModalFilter}
                >
                  Adicionar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    resetFilters()
                  }}
                  style={{
                    background: colorError,
                  }}
                  type='primary'
                >
                  Limpar Filtros
                </Button>
              </div>
              <div className='p-2'>
              </div>
            </div>
          </div>
          <div className='table-content'>
            <Table pagination={false} loading={(loadingList) && { indicator: <LoadingOutlined /> }} rowKey="id" columns={columns} dataSource={dataList?.data} />
          </div>
          {
            dataList?.last_page > 1 &&
            <div className='m-3 text-right'>
              <Pagination
                pageSize={dataList?.per_page}
                showSizeChanger={false}
                showTotal={(total, range) => `${total} itens`}
                onChange={(e) => updateDataList(e)}
                current={dataList?.current_page}
                defaultPageSize={`${dataList?.per_page}`}
                total={dataList?.total}
              />
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Filtros"
          open={openModalFilter}
          footer={null}
          onCancel={hideModalFilter}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                social_reason: filters?.social_reason,
                fantasy_name: filters?.fantasy_name,
                cnpj: filters?.cnpj,
                email: filters?.email,

              }}
              validationSchema={filterListSchema}
              onSubmit={() => (
                updateFilter()
              )
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="social_reason">Razão Social</label>
                      <Input
                        className='input-default'
                        value={filters.social_reason}
                        onChange={(e) => setFieldFilter('social_reason', e.target.value)}
                        size="large"
                        placeholder="Razão Social"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="fantasy_name">Nome Fantasia</label>
                      <Input
                        className='input-default'
                        value={filters.fantasy_name}
                        onChange={(e) => setFieldFilter('fantasy_name', e.target.value)}
                        size="large"
                        placeholder="Nome Fantasia"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="cnpj">CPF/CNPJ</label>
                      <Input
                        className='input-default'
                        value={filters.cnpj}
                        onChange={(e) => setFieldFilter('cnpj', e.target.value)}
                        size="large"
                        placeholder="CPF/CNPJ"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="email">Email</label>
                      <Input
                        className='input-default'
                        value={filters.email}
                        type='email'
                        onChange={(e) => setFieldFilter('email', e.target.value)}
                        size="large"
                        placeholder="Email"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        size='small'
                        className='m-2'
                        disabled={(loadingList) ? true : false}
                        loading={(loadingList) ? true : false}
                        onClick={() => handleSubmit(values)}
                        type="primary"
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>

        {/* Modal Actions Edit | Add */}
        <Drawer
          title={getTitle()}
          className="drawer-suppliers-show"
          placement={'right'}
          closable={true}
          onClose={onCloseDrawer}
          open={openDrawer}
          key={'right'}
          closeIcon={<CloseOutlined />}
          width={'40%'}
        >
          <>
            {
              (openTypeContentDrawer?.details.status) &&
              <>
                <Show dataShow={openTypeContentDrawer?.details?.dataShow}></Show>
              </>
            }
          </>
        </Drawer>

        <FooterDefault />
      </Content>
    </>
  );
};

export default Suppliers;