import Navigation from './navigation/Routes';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

//Dispatchs

//Styles And Helpers
import './App.css';
import 'antd/dist/reset.css';

//Contexts

//Components
//Variables


function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
          <Navigation />
      </PersistGate>
    </Provider>
  );
}

export default App;
