import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Switch, Input, Upload, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import api from '../../../services/api';
import { toast } from 'react-toastify';

//Dispatchs
import { Creators as EmployeesActions } from '../../../store/reducers/employees';
import { Creators as RefreshActions } from '../../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components

//Variables
const EditSchema = yup.object().shape({
  first_name: yup.string()
    .min(2, 'A nome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  last_name: yup.string()
    .min(2, 'A sobrenome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  email: yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
});

const Edit = ({ dataEdit }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingEdit } = useSelector((state) => (state.employees))
  const { data } = useSelector((state) => (state.authentication))

  //States Local
  const [dataEmployees, setDataEmployees] = useState(null);
  const [dataDiscountGroup, setDataDiscountGroup] = useState(null);
  const [selectedDiscountGroup, setSelectedDiscountGroup] = useState({
    value: String(dataEdit?.discount_group?.id),
    label: dataEdit?.discount_group?.id,
  });

  //Context

  //Cicle of Life
  useEffect(() => {
    fetchGetEmployees()
    fetchGetDiscountGroup()
    mountPhotos()
  }, []);

  useEffect(() => {
    mountPhotos()
  }, [dataEmployees?.avatar_url]);

  //Functions

  async function fetchGetDiscountGroup() {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/discount_group?type=select`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setDataDiscountGroup(mountSelectDiscountGroup(response))
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  async function fetchGetEmployees() {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/employees/${dataEdit?.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setDataEmployees(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  const submitEdit = (values) => {
    const payload = values
    console.log('values', values)
    dispatch(EmployeesActions.putEmployeesEditRequest(payload))
  }

  const mountPhotos = () => {
    if (dataEmployees?.avatar_url) {
      setFileListPhotos([
        {
          uid: dataEmployees?.id,
          name: 'Imagem ' + dataEmployees?.id,
          status: 'done',
          url: dataEmployees?.avatar_url,
          thumbUrl: dataEmployees?.avatar_url,
        }
      ])
    }
  }

  const [loader, setLoader] = useState(null);
  const [fileListPhotos, setFileListPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('avatar', fileListPhotos[0]);

    setUploading(true);
    const id = dataEdit?.id
    const token = localStorage.getItem('iguacu:token');

    fetch(api.defaults.baseURL + `/admin/employees/${id}/avatar`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        fetchGetEmployees()
        toast.success("Avatar enviado com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(() => {
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const removePhoto = (id) => {

    const token = localStorage.getItem('iguacu:token');
    // You can use any AJAX library you like
    fetch(api.defaults.baseURL + `/admin/employees/${id}/avatar`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then(() => {
        fetchGetEmployees()
        // console.log('upload successfully.');
      })
      .catch(() => {
        // console.log('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const propsUpload = {
    listType: "picture-card",
    multiple: false,
    onRemove: async (file) => {
      const index = fileListPhotos.indexOf(file);
      removePhoto(fileListPhotos[index]?.uid)
      const newFileList = fileListPhotos.slice();
      newFileList.splice(index, 1);
      setFileListPhotos(newFileList);
    },
    beforeUpload: async (file) => {

      const acceptedFormats = ['image/png', 'image/jpeg'];
      const isFormatValid = acceptedFormats.includes(file.type);
      const isSizeValid = file.size / 1024 / 1024 <= 2;

      if (!isFormatValid) {
        toast.error("Por favor, envie apenas arquivos PNG ou JPG!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      if (!isSizeValid) {
        toast.error("O tamanho máximo do arquivo é de 2MB!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      setFileListPhotos([file]);
      return false;
    },
    fileList: fileListPhotos,
  };

  const mountSelectDiscountGroup = (data) => {
    let arrayDisountGroup = []
    data.forEach(item => {
      arrayDisountGroup.push(
        {
          value: String(item.id),
          label: item.name,
        }
      )

    });

    return arrayDisountGroup;
  }

  const handleSelectChangeDiscountGroup = (value) => {
    // Encontre a opção selecionada com base no valor
    const selectedOption = dataDiscountGroup.find((option) => option.value === value);

    setSelectedDiscountGroup(selectedOption); // Atualize o estado com a nova opção selecionada
  };

  //Render
  return (
    <>
      <div className='p-2 content-box-form'>
        {loader &&
          <div className='p-2'>
            <LoadingOutlined />
          </div>
        }
        {
          dataEmployees?.id &&
          <Formik
            initialValues={{
              id: dataEmployees?.id,
              discount_group_id: dataEmployees?.discount_group?.id,
              first_name: dataEmployees?.first_name,
              last_name: dataEmployees?.last_name,
              email: dataEmployees?.email,
              is_active: (dataEmployees?.is_active === 0) ? false : true,
              id_user_logged: data?.profile?.id
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => (
              submitEdit(values)
            )
            }
          >
            {({ setFieldValue, handleSubmit, values, errors, touched }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="first_name">Nome</label>
                    <Field name="first_name" type="text" key={'first_name'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.first_name}
                          {...field}
                          size="large"
                          placeholder="Nome"
                        />
                      }}
                    </Field>
                    {errors.first_name && touched.first_name ? <div className='error my-2 px-2'>{errors.first_name}</div> : null}
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="last_name">Sobrenome</label>
                    <Field name="last_name" type="text" key={'last_name'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.last_name}
                          {...field}
                          size="large"
                          placeholder="Sobrenome"
                        />
                      }}
                    </Field>
                    {errors.last_name && touched.last_name ? <div className='error my-2 px-2'>{errors.last_name}</div> : null}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="email">Email</label>
                    <Field name="email" type="text" key={'email'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.email}
                          {...field}
                          type='email'
                          size="large"
                          placeholder="Email"
                        />
                      }}
                    </Field>
                    {errors.email && touched.email ? <div className='error my-2 px-2'>{errors.email}</div> : null}
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="last_name">Grupo de Desconto</label>
                    <div className='my-1'>
                      <Select
                        showSearch
                        size="large"
                        style={{
                          width: '100%',
                        }}
                        placeholder="Selecione o Grupo de Desconto"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        value={selectedDiscountGroup?.value}
                        options={dataDiscountGroup}
                        onChange={(e) => (
                          setFieldValue('discount_group_id', parseInt(e)),
                          handleSelectChangeDiscountGroup(e)
                        )
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="last_name">Inativo/Ativo</label>
                    <div className='my-2'>
                      <Switch
                        onChange={(value) => setFieldValue('is_active', value)}
                        value={values.is_active}
                        checked={values.is_active}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='my-2' span={24}>
                    <Button
                      className='m-2'
                      disabled={(loadingEdit) ? true : false}
                      loading={(loadingEdit) ? true : false}
                      onClick={() => handleSubmit(values)}
                      type="primary"
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24} className='my-2' >
                    <label className='p-2'>Avatar</label>
                    <div className='my-2'>
                      <Upload {...propsUpload}>
                        <Button icon={<UploadOutlined />}></Button>
                      </Upload>
                      <Button
                        type="primary"
                        size={'small'}
                        onClick={handleUpload}
                        loading={uploading}
                        style={{
                          margin: 3,
                        }}
                      >
                        {uploading ? 'Uploading' : 'Enviar Imagem'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Formik >
        }
      </div >
    </>
  );
};

export default Edit;