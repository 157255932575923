import React, { useEffect } from 'react'
import { Breadcrumb, Layout, theme } from 'antd';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';

//Dispatchs
import { Creators as RefreshActions } from '../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components
import FooterDefault from '../../components/LayoutDefault/FooterDefault'
import { WelcomeWrapper } from '../../styles/globalStyles';

//Variables
const { Content } = Layout;

const Welcome = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer

  //States Local
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  //Context

  //Cicle of Life
  useEffect(() => {
    document.title = 'Dashboard - Iguaçu Máquinas';
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  //Functions

  //Render
  return (
    <WelcomeWrapper>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{ title: 'Dashboard' }]}
        />
        <div
          className='px-4 py-3'
          style={{ background: colorBgContainer }}
        >
          <p>Olá e bem-vindo ao nosso painel administrativo!</p>
          <p>Aqui você encontrará todas as ferramentas e recursos necessários para gerenciar o aplicativo ConfIG.</p>
          <p>Lembre-se de que este painel é um ambiente de trabalho profissional e, como tal, pedimos que mantenha a confidencialidade das informações que acessar e que utilize as ferramentas disponíveis de maneira responsável e ética.</p>
          <p>Em caso de dúvidas ou problemas, não hesite em contatar nossa equipe de suporte. Desejamos a você uma ótima experiência em nosso painel administrativo e estamos ansiosos para trabalhar juntos!</p>
        </div>

        <FooterDefault />
      </Content>
    </WelcomeWrapper >
  );
};

export default Welcome;