import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as FreightActions,
  Types as FreightTypes,
} from '../../reducers/freight';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listFreight({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/freight?page=${payload?.page}&filial=${payload?.filial}&freight=${payload?.freight}&fees_boarding=${payload?.fees_boarding}&city=${payload?.city}`
    );
    yield put(FreightActions.setUpdateFreight(false));
    yield put(FreightActions.getFreightListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(FreightActions.getFreightListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addFreight({ payload }) {
  try {
    yield call(api.post, '/admin/freight', payload);
    yield put(FreightActions.setUpdateFreight(true));
    yield put(FreightActions.postFreightAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(FreightActions.postFreightAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editFreight({ payload }) {
  try {
    yield call(api.put, `/admin/freight/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(FreightActions.setUpdateFreight(true));
    yield put(FreightActions.putFreightEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(FreightActions.putFreightEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteFreight({ payload }) {
  try {
    yield call(api.delete, `/admin/freight/${payload?.id}`);
    // console.log('data store', data)
    yield put(FreightActions.setUpdateFreight(true));
    yield put(FreightActions.postFreightAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(FreightActions.deleteFreightDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listFreightWatcher() {
  yield takeLatest(FreightTypes.GET_FREIGHT_LIST_REQUEST, listFreight);
}

export function* addFreightWatcher() {
  yield takeLatest(FreightTypes.POST_FREIGHT_ADD_REQUEST, addFreight);
}

export function* editFreightWatcher() {
  yield takeLatest(FreightTypes.PUT_FREIGHT_EDIT_REQUEST, editFreight);
}

export function* deleteFreightWatcher() {
  yield takeLatest(FreightTypes.DELETE_FREIGHT_DEL_REQUEST, deleteFreight);
}

export default function* rootSaga() {
  yield all([
    fork(listFreightWatcher),
    fork(addFreightWatcher),
    fork(editFreightWatcher),
    fork(deleteFreightWatcher),
  ]);
}
