export const Types = {

  // Product Models ----------
  //Get - List
  GET_PRODUCTMODELS_LIST_REQUEST: 'GET_PRODUCTMODELS_LIST_REQUEST',
  GET_PRODUCTMODELS_LIST_SUCCESS: 'GET_PRODUCTMODELS_LIST_SUCCESS',
  GET_PRODUCTMODELS_LIST_FAIL: 'GET_PRODUCTMODELS_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_PRODUCTMODELS_LIST: 'SET_UPDATE_PRODUCTMODELS_LIST',

  //Store - Add
  POST_PRODUCTMODELS_ADD_REQUEST: 'POST_PRODUCTMODELS_ADD_REQUEST',
  POST_PRODUCTMODELS_ADD_SUCCESS: 'POST_PRODUCTMODELS_ADD_SUCCESS',
  POST_PRODUCTMODELS_ADD_FAIL: 'POST_PRODUCTMODELS_ADD_FAIL',

  //Update - Edit
  PUT_PRODUCTMODELS_EDIT_REQUEST: 'PUT_PRODUCTMODELS_EDIT_REQUEST',
  PUT_PRODUCTMODELS_EDIT_SUCCESS: 'PUT_PRODUCTMODELS_EDIT_SUCCESS',
  PUT_PRODUCTMODELS_EDIT_FAIL: 'PUT_PRODUCTMODELS_EDIT_FAIL',

  //Delete
  DELETE_PRODUCTMODELS_DEL_REQUEST: 'DELETE_PRODUCTMODELS_DEL_REQUEST',
  DELETE_PRODUCTMODELS_DEL_SUCCESS: 'DELETE_PRODUCTMODELS_DEL_SUCCESS',
  DELETE_PRODUCTMODELS_DEL_FAIL: 'DELETE_PRODUCTMODELS_DEL_FAIL',

  //Upload Image 
  POST_PRODUCTMODELS_UPLOADIMAGE_REQUEST: 'POST_PRODUCTMODELS_UPLOADIMAGE_REQUEST',
  POST_PRODUCTMODELS_UPLOADIMAGE_SUCCESS: 'POST_PRODUCTMODELS_UPLOADIMAGE_SUCCESS',
  POST_PRODUCTMODELS_UPLOADIMAGE_FAIL: 'POST_PRODUCTMODELS_UPLOADIMAGE_FAIL',

  //Delete Image 
  DELETE_PRODUCTMODELS_DELIMAGE_REQUEST: 'DELETE_PRODUCTMODELS_DELIMAGE_REQUEST',
  DELETE_PRODUCTMODELS_DELIMAGE_SUCCESS: 'DELETE_PRODUCTMODELS_DELIMAGE_SUCCESS',
  DELETE_PRODUCTMODELS_DELIMAGE_FAIL: 'DELETE_PRODUCTMODELS_DELIMAGE_FAIL',

  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {

  // PRODUCTS MODELS ----------
  dataProductModelsList: [],
  loadingProductModelsList: false,
  errorProductModelsList: false,
  errorDataProductModelsList: false,
  dataProductModelsListUpdate: false,

  loadingProductModelsAdd: false,
  errorProductModelsAdd: false,
  errorDataProductModelsAdd: false,

  loadingProductModelsEdit: false,
  errorProductModelsEdit: false,
  errorDataProductModelsEdit: false,

  loadingProductModelsDelete: false,
  errorProductModelsDelete: false,
  errorDataProductModelsDelete: false,

  successProductModelsUploadImage: false,
  loadingProductModelsUploadImage: false,
  errorProductModelsUploadImage: false,
  errorDataProductModelsUploadImage: false,

  successProductModelsDelImage: false,
  loadingProductModelsDelImage: false,
  errorProductModelsDelImage: false,
  errorDataProductModelsDelImage: false,

};

export default function products_models(state = INITIAL_STATE, action) {
  switch (action.type) {

    // PRODUCT MODELS ----------
    //Get - List
    case Types.GET_PRODUCTMODELS_LIST_REQUEST:
      return {
        ...state,
        loadingProductModelsList: true,
        errorProductModelsList: false,
      };
    case Types.GET_PRODUCTMODELS_LIST_SUCCESS:
      return {
        ...state,
        dataProductModelsList: action.payload,
        loadingProductModelsList: false,
        errorProductModelsList: false,
        errorDataProductModelsList: false
      };
    case Types.GET_PRODUCTMODELS_LIST_FAIL:
      return {
        ...state,
        dataProductModelsList: false,
        loadingProductModelsList: false,
        errorProductModelsList: true,
        errorDataProductModelsList: action.payload,
      };

    case Types.SET_UPDATE_PRODUCTMODELS_LIST:
      return {
        ...state,
        dataProductModelsListUpdate: action.payload,
      };

    //Store - Add
    case Types.POST_PRODUCTMODELS_ADD_REQUEST:
      return {
        ...state,
        loadingProductModelsAdd: true,
        errorProductModelsAdd: false,
      };
    case Types.POST_PRODUCTMODELS_ADD_SUCCESS:
      return {
        ...state,
        loadingProductModelsAdd: false,
        errorProductModelsAdd: false,
        errorDataProductModelsAdd: false,
      };
    case Types.POST_PRODUCTMODELS_ADD_FAIL:
      return {
        ...state,
        loadingProductModelsAdd: false,
        errorProductModelsAdd: true,
        errorDataProductModelsAdd: action.payload,
      };

    //Update - Edit
    case Types.PUT_PRODUCTMODELS_EDIT_REQUEST:
      return {
        ...state,
        loadingProductModelsEdit: true,
        errorProductModelsEdit: false,
      };
    case Types.PUT_PRODUCTMODELS_EDIT_SUCCESS:
      return {
        ...state,
        loadingProductModelsEdit: false,
        errorProductModelsEdit: false,
        errorDataProductModelsEdit: false,
      };
    case Types.PUT_PRODUCTMODELS_EDIT_FAIL:
      return {
        ...state,
        loadingProductModelsEdit: false,
        errorProductModelsEdit: true,
        errorDataProductModelsEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_PRODUCTMODELS_DEL_REQUEST:
      return {
        ...state,
        loadingProductModelsDelete: true,
        errorProductModelsDelete: false,
      };
    case Types.DELETE_PRODUCTMODELS_DEL_SUCCESS:
      return {
        ...state,
        loadingProductModelsDelete: false,
        errorProductModelsDelete: false,
        errorDataProductModelsDelete: false,
      };
    case Types.DELETE_PRODUCTMODELS_DEL_FAIL:
      return {
        ...state,
        loadingProductModelsDelete: false,
        errorProductModelsDelete: true,
        errorDataProductModelsDelete: action.payload,
      };

    //Upload Images 
    case Types.POST_PRODUCTMODELS_UPLOADIMAGE_REQUEST:
      return {
        ...state,
        loadingProductModelsUploadImage: true,
        errorProductModelsUploadImage: false,
      };
    case Types.POST_PRODUCTMODELS_UPLOADIMAGE_SUCCESS:
      return {
        ...state,
        successProductModelsUploadImage:true,
        loadingProductModelsUploadImage: false,
        errorProductModelsUploadImage: false,
        errorDataProductModelsUploadImage: false,
      };
    case Types.POST_PRODUCTMODELS_UPLOADIMAGE_FAIL:
      return {
        ...state,
        successProductModelsUploadImage:false,
        loadingProductModelsUploadImage: false,
        errorProductModelsUploadImage: true,
        errorDataProductModelsUploadImage: action.payload,
      };

    //Delete Images 
    case Types.DELETE_PRODUCTMODELS_DELIMAGE_REQUEST:
      return {
        ...state,
        loadingProductModelsDelImage: true,
        errorProductModelsDelImage: false,
      };
    case Types.DELETE_PRODUCTMODELS_DELIMAGE_SUCCESS:
      return {
        ...state,
        successProductModelsDelImage:true,
        loadingProductModelsDelImage: false,
        errorProductModelsDelImage: false,
        errorDataProductModelsDelImage: false,
      };
    case Types.DELETE_PRODUCTMODELS_DELIMAGE_FAIL:
      return {
        ...state,
        successProductModelsDelImage:false,
        loadingProductModelsDelImage: false,
        errorProductModelsDelImage: true,
        errorDataProductModelsDelImage: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        // PRODUCT MODELS ----------
        dataProductModelsList: [],
        loadingProductModelsList: false,
        errorProductModelsList: false,
        errorDataProductModelsList: false,
        dataProductModelsListUpdate: false,

        loadingProductModelsAdd: false,
        errorProductModelsAdd: false,
        errorDataProductModelsAdd: false,

        loadingProductModelsEdit: false,
        errorProductModelsEdit: false,
        errorDataProductModelsEdit: false,

        loadingProductModelsDelete: false,
        errorProductModelsDelete: false,
        errorDataProductModelsDelete: false,

        successProductModelsUploadImage: false,
        loadingProductModelsUploadImage: false,
        errorProductModelsUploadImage: false,
        errorDataProductModelsUploadImage: false,

        successProductModelsDelImage: false,
        loadingProductModelsDelImage: false,
        errorProductModelsDelImage: false,
        errorDataProductModelsDelImage: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  // PRODUCT MODELS ----------
  //Get - List
  getProductModelsListRequest: (payload) => ({
    type: Types.GET_PRODUCTMODELS_LIST_REQUEST,
    payload,
  }),

  getProductModelsListRequestSuccess: (payload) => ({
    type: Types.GET_PRODUCTMODELS_LIST_SUCCESS,
    payload,
  }),

  getProductModelsListRequestFail: (payload) => ({
    type: Types.GET_PRODUCTMODELS_LIST_FAIL,
    payload,
  }),

  setUpdateProductModels: (payload) => ({
    type: Types.SET_UPDATE_PRODUCTMODELS_LIST,
    payload,
  }),

  //Store - Add 
  postProductModelsAddRequest: (payload) => ({
    type: Types.POST_PRODUCTMODELS_ADD_REQUEST,
    payload,
  }),

  postProductModelsAddRequestSuccess: () => ({
    type: Types.POST_PRODUCTMODELS_ADD_SUCCESS,
  }),

  postProductModelsAddRequestFail: (payload) => ({
    type: Types.POST_PRODUCTMODELS_ADD_FAIL,
    payload,
  }),

  //Update - Edit 
  putProductModelsEditRequest: (payload) => ({
    type: Types.PUT_PRODUCTMODELS_EDIT_REQUEST,
    payload,
  }),

  putProductModelsEditRequestSuccess: () => ({
    type: Types.PUT_PRODUCTMODELS_EDIT_SUCCESS,
  }),

  putProductModelsEditRequestFail: (payload) => ({
    type: Types.PUT_PRODUCTMODELS_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteProductModelsDelRequest: (payload) => ({
    type: Types.DELETE_PRODUCTMODELS_DEL_REQUEST,
    payload,
  }),

  deleteProductModelsDelRequestSuccess: () => ({
    type: Types.DELETE_PRODUCTMODELS_DEL_SUCCESS,
  }),

  deleteProductModelsDelRequestFail: (payload) => ({
    type: Types.DELETE_PRODUCTMODELS_DEL_FAIL,
    payload,
  }),

  //Upload Image 
  postProductModelsUploadImageRequest: (payload) => ({
    type: Types.POST_PRODUCTMODELS_UPLOADIMAGE_REQUEST,
    payload,
  }),

  postProductModelsUploadImageRequestSuccess: () => ({
    type: Types.POST_PRODUCTMODELS_UPLOADIMAGE_SUCCESS,
  }),

  postProductModelsUploadImageRequestFail: (payload) => ({
    type: Types.POST_PRODUCTMODELS_UPLOADIMAGE_FAIL,
    payload,
  }),

  //Delete Image 
  deleteProductModelsDelImageRequest: (payload) => ({
    type: Types.DELETE_PRODUCTMODELS_DELIMAGE_REQUEST,
    payload,
  }),

  deleteProductModelsDelImageRequestSuccess: () => ({
    type: Types.DELETE_PRODUCTMODELS_DELIMAGE_SUCCESS,
  }),

  deleteProductModelsDelImageRequestFail: (payload) => ({
    type: Types.DELETE_PRODUCTMODELS_DELIMAGE_FAIL,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
