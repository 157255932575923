import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SmileOutlined,
  UnlockOutlined,
  ShoppingCartOutlined,
  CalendarOutlined,
  UnorderedListOutlined,
  SlidersOutlined,
  ApartmentOutlined,
  BuildOutlined,
  GiftOutlined,
  BlockOutlined,
  DownOutlined,
  LogoutOutlined
} from '@ant-design/icons';

import { Dropdown, Space, Button, Layout, Menu, ConfigProvider, Modal, theme } from 'antd';

//Dispatchs
import { Creators as RefreshActions } from '../../store/reducers/refresh';
import { Creators as AuthActions } from '../../store/reducers/authentication';

//Styles And Helpers

//Contexts

//Components
import Logo from '../../assets/images/logo_iguacumaq.png';
import { LayoutWrapper } from '../../styles/globalStyles';

//Variables
const { Header, Sider } = Layout;
const { Item, SubMenu } = Menu;

const LayoutDefault = ({ children }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { alertModalExpiredLogin } = useSelector((state) => (state.refresh))
  const { data } = useSelector((state) => (state.authentication))

  //States Local
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  //Context

  //Cicle of Life

  //Functions
  function mountMenu() {
    return (
      <Menu key="menu" theme="light" mode="inline"
        defaultOpenKeys={
          (
            ((location.pathname === "/products/brands") && ['sub1']) ||
            ((location.pathname === "/machine-types") && ['sub1']) ||
            ((location.pathname === "/product-models") && ['sub1']) ||
            ((location.pathname === "/products/produtos") && ['sub1'])
          ) ||
          (
            ((location.pathname === "/sub-items/groups") && ['sub2']) ||
            ((location.pathname === "/sub-items/subitens") && ['sub2'])
          )
        }
      >
        <Item key="1" className={(location.pathname === "/welcome") && "ant-menu-item-selected"}>
          <SmileOutlined />
          <span>Bem Vindo !</span>
          <Link to="/welcome" />
        </Item>
        <Item key="2" className={(location.pathname === "/administrators") && "ant-menu-item-selected"}>
          <UnlockOutlined />
          <span>Usuários Administrativos</span>
          <Link to="/administrators" />
        </Item>
        <Item key="3" className={(location.pathname === "/quotations") && "ant-menu-item-selected"}>
          <ShoppingCartOutlined />
          <span>Simulações Realizadas</span>
          <Link to="/quotations" />
        </Item>
        <Item key="4" className={(location.pathname === "/employees") && "ant-menu-item-selected"}>
          <UserOutlined />
          <span>Vendedores</span>
          <Link to="/employees" />
        </Item>
        <Item key="5" className={(location.pathname === "/customers") && "ant-menu-item-selected"}>
          <ShoppingCartOutlined />
          <span>Clientes</span>
          <Link to="/customers" />
        </Item>
        <Item key="6" className={(location.pathname === "/discount/discount-group") && "ant-menu-item-selected"}>
          <CalendarOutlined />
          <span>Alçada de Desconto</span>
          <Link to="/discount/discount-group" />
        </Item>
        <Item key="7" className={(location.pathname === "/discount/discount-product") && "ant-menu-item-selected"}>
          <CalendarOutlined />
          <span>Desconto Máquina</span>
          <Link to="/discount/discount-product" />
        </Item>
        <Item key="8" className={(location.pathname === "/freight") && "ant-menu-item-selected"}>
          <CalendarOutlined />
          <span>Frete</span>
          <Link to="/freight" />
        </Item>
        {/* <Item key="7">
          <TeamOutlined />
          <span>Fornecedores</span>
          <Link to="/suppliers" />
        </Item> */}
        <SubMenu
          key="sub1" title="Máquinas" icon={<UnorderedListOutlined />}
        >
          <Item key="9" className={(location.pathname === "/products/brands") && "ant-menu-item-selected"}>
            <SlidersOutlined />
            <span>Marcas</span>
            <Link to="/products/brands" />
          </Item>
          <Item key="10" className={(location.pathname === "/products/machine-types") && "ant-menu-item-selected"}>
            <ApartmentOutlined />
            <span>Grupo de Máquinas</span>
            <Link to="/products/machine-types" />
          </Item>
          <Item key="11" className={(location.pathname === "/products/product-models") && "ant-menu-item-selected"}>
            <BuildOutlined />
            <span>Modelos</span>
            <Link to="/products/product-models" />
          </Item>
          <Item key="12" className={(location.pathname === "/products/serie") && "ant-menu-item-selected"}>
            <GiftOutlined />
            <span>Config. Base</span>
            <Link to="/products/products" />
          </Item>
        </SubMenu>
        <SubMenu key="sub2" title="Opcionais" icon={<UnorderedListOutlined />} >
          <Item key="13" className={(location.pathname === "/sub-items/groups") && "ant-menu-item-selected"}>
            <BlockOutlined />
            <span>Grupos</span>
            <Link to="/sub-items/groups" />
          </Item>
          <Item key="14" className={(location.pathname === "/sub-items/subitens") && "ant-menu-item-selected"}>
            <UnorderedListOutlined />
            <span>Opcionais</span>
            <Link to="/sub-items/subitens" />
          </Item>
        </SubMenu>
        <Item key="15" className={(location.pathname === "/messages") && "ant-menu-item-selected"}>
          <ApartmentOutlined />
          <span>Lembretes aos Vendedores</span>
          <Link to="/messages" />
        </Item>
      </Menu>
    )
  }

  const items = [
    {
      label: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              marginRight: '5px',
              marginLeft: '5px'
            }}
          >
            <UserOutlined />
          </div>
          <Link to="/acount" >Minha Conta</Link>
        </div>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              marginRight: '5px',
              marginLeft: '5px'
            }}
          >
            <LogoutOutlined />
          </div>
          <a
            onClick={() => dispatch(AuthActions.logoutRequest())}
            rel="noopener noreferrer">
            Sair
          </a>
        </div>
      ),
      key: '1',
    },
  ];

  const handleOk = () => {
    dispatch(AuthActions.logoutRequest())
  };
  const handleCancel = () => {
    dispatch(RefreshActions.setAlertModalExperedLogin(false))
  };

  //Render
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#6a874d',
          colorSecundary: '#000',
          colorSuccess: '#6a874d',
          colorInfo: '#17a2b8',
          colorWarning: '#ffc107',
          colorError: '#dc3545'
        }
      }}
    >
      <LayoutWrapper>
        <Layout className='layout' >
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            width={'256px'}
            className='slider-menu'
          >
            <div className='content-logo my-2'>
              <img src={Logo} alt="Iguaçu Máquinas" />
              {
                (!collapsed) &&
                <h1 className='m-2'>Iguaçu Máquinas</h1>
              }
            </div>
            {mountMenu()}
          </Sider>
          <Layout className="nav-container">
            <Header
              className='header-panel'
              style={{
                background: colorBgContainer,
              }}
            >
              <div className='content-header-panel px-1'>
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                  }}
                />
                <Dropdown menu={{ items }} >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {data?.profile?.first_name}
                      <DownOutlined className='color-default' color='#69b1ff' />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </Header>
            {children}
          </Layout>
        </Layout>
      </LayoutWrapper>

      {/* Modal Alert */}
      <Modal title="Login Expirou" open={alertModalExpiredLogin} onOk={() => handleOk()} onCancel={() => handleCancel()}>
        <p>Seu login expirou, faça o login novamente !</p>
      </Modal>

    </ConfigProvider>
  );
};
export default LayoutDefault;