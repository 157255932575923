import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

//Dispatchs

//Styles And Helpers

//Contexts
import ProductsProvider from '../contexts/ProductsContext';

//Components
import Welcome from '../pages/Welcome/Welcome';
import Perfil from '../pages/Perfil/Perfil';
import NoFoundPage from '../pages/404';
import Administrators from '../pages/Administrators/Administrators';
import Quotations from '../pages/Quotations/Quotations'
import Employees from '../pages/Employees/Employees'
import Customers from '../pages/Customers/Customers'
import Suppliers from '../pages/Suppliers/Suppliers'

import Brands from '../pages/Products/Brands/Brands';
import MachineTypes from '../pages/Products/MachinesTypes/MachineTypes';
import ProductModels from '../pages/Products/ProductModels/ProductsModels';
import Products from '../pages/Products/Products/Products';

import Groups from '../pages/Subitens/Groups/Groups';
import Subitens from '../pages/Subitens/Subitens/Subitens';

import DiscountGroup from '../pages/Discount/DiscountGroup/DiscountGroup';
import DiscountProduct from '../pages/Discount/DiscountProduct/DiscountProduct';

import Messages from '../pages/Messages/Messages'
import LayoutDefault from '../components/LayoutDefault/LayoutDefault';
import Login from './../pages/Auth/Login/Login'
import NewPassword from '../pages/Auth/NewPassword/NewPassword';

import Freight from '../pages/Freight/Freight';

import PrivacyPolicies from '../pages/PrivacyPolicies/PrivacyPolicies';

import { GlobalStyle } from '../styles/globalStyles';

//Variables

const Navigation = () => {

  //Dispatchs

  //States Reducer

  //States Local

  //Context
  const { data } = useSelector((state) => (state.authentication))


  //Cicle of Life
  useEffect(() => {
    // console.log('Routes')
  }, []);

  //Functions

  const ComponentProducts = () => (
    <ProductsProvider>
      <Products />
    </ProductsProvider>
  )

  const PrivateRoute = ({ element }) => {
    return (data?.access_token) ? (
      <LayoutDefault>
        {element}
      </LayoutDefault>
    ) : (
      <Navigate to="/" />
    );
  };

  //Render
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route index element={<Login />} />
        <Route exact path="login" element={<Login />} />
        <Route path="/privacypolicies" element={<PrivacyPolicies />} />
        <Route exact path="/administrators"
          element={<PrivateRoute element={<Administrators />} />}
        />
        <Route exact path="/welcome"
          element={<PrivateRoute element={<Welcome />} />}
        />
        <Route exact path="/user/reset-password"
          element={<NewPassword />}
        />
        <Route exact path="/quotations"
          element={<PrivateRoute element={<Quotations />} />}
        />
        <Route exact path="/employees"
          element={<PrivateRoute element={<Employees />} />}
        />
        <Route exact path="/customers"
          element={<PrivateRoute element={<Customers />} />}
        />
        <Route exact path="/suppliers"
          element={<PrivateRoute element={<Suppliers />} />}
        />
        <Route exact path="/availability_dates"
          element={<PrivateRoute element={<Welcome />} />}
        />
        <Route exact path="/discount/discount-group"
          element={<PrivateRoute element={<DiscountGroup />} />}
        />
        <Route exact path="/discount/discount-product"
          element={<PrivateRoute element={<DiscountProduct />} />}
        />
        <Route exact path="/products/brands"
          element={<PrivateRoute element={<Brands />} />}
        />
        <Route exact path="/products/machine-types"
          element={<PrivateRoute element={<MachineTypes />} />}
        />
        <Route exact path="/products/product-models"
          element={<PrivateRoute element={<ProductModels />} />}
        />
        <Route exact path="/products/products"
          element={<PrivateRoute element={<ComponentProducts />} />}
        />
        <Route exact path="/sub-items/groups"
          element={<PrivateRoute element={<Groups />} />}
        />
        <Route exact path="/sub-items/subitens"
          element={<PrivateRoute element={<Subitens />} />}
        />
        <Route exact path="/freight"
          element={<PrivateRoute element={<Freight />} />}
        />
        <Route exact path="/messages"
          element={<PrivateRoute element={<Messages />} />}
        />
        <Route exact path="/acount"
          element={<PrivateRoute element={<Perfil />} />}
        />
        <Route exact path="*" element={<NoFoundPage />} />
      </Routes>
      <ToastContainer />
    </Router>
  )
};

export default Navigation;
