import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as QuotationsActions,
  Types as QuotationsTypes,
} from '../../reducers/quotations';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listQuotations({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/orders?page=${payload?.page}&id=${payload?.id}&subitems_code=${payload?.subitems_code}&employee_email=${payload?.employee_email}&doc=${payload?.doc}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
    yield put(QuotationsActions.setUpdateQuotations(false));
    yield put(QuotationsActions.getQuotationsListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(QuotationsActions.getQuotationsListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteQuotations({ payload }) {
  try {
    yield call(api.delete, `/admin/orders/${payload?.id}`);
    // console.log('data store', data)
    yield put(QuotationsActions.setUpdateQuotations(true));
    yield put(QuotationsActions.deleteQuotationsDelRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(QuotationsActions.deleteQuotationsDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* getQuotationPdf({ payload }) {
  try {
    const { data } = yield call(api.get, `/admin/orders/${payload?.id}/pdf`, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `simulacao${payload?.id}.pdf` // Pode personalizar o nome do arquivo aqui
    document.body.appendChild(a);
    a.click();
    a.remove(); // Limpa o elemento criado

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listQuotationsWatcher() {
  yield takeLatest(QuotationsTypes.GET_QUOTATIONS_LIST_REQUEST, listQuotations);
}

export function* deleteQuotationsWatcher() {
  yield takeLatest(QuotationsTypes.DELETE_QUOTATIONS_DEL_REQUEST, deleteQuotations);
}

export function* getQuotationPdfWatcher() {
  yield takeLatest(QuotationsTypes.GET_QUOTATIONS_PDF, getQuotationPdf);
}

export default function* rootSaga() {
  yield all([
    fork(listQuotationsWatcher),
    fork(deleteQuotationsWatcher),
    fork(getQuotationPdfWatcher),
  ]);
}
