export const Types = {

  // Machine Types ----------
  //Get - List
  GET_MACHINETYPES_LIST_REQUEST: 'GET_MACHINETYPES_LIST_REQUEST',
  GET_MACHINETYPES_LIST_SUCCESS: 'GET_MACHINETYPES_LIST_SUCCESS',
  GET_MACHINETYPES_LIST_FAIL: 'GET_MACHINETYPES_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_MACHINETYPES_LIST: 'SET_UPDATE_MACHINETYPES_LIST',

  //Store - Add
  POST_MACHINETYPES_ADD_REQUEST: 'POST_MACHINETYPES_ADD_REQUEST',
  POST_MACHINETYPES_ADD_SUCCESS: 'POST_MACHINETYPES_ADD_SUCCESS',
  POST_MACHINETYPES_ADD_FAIL: 'POST_MACHINETYPES_ADD_FAIL',

  //Update - Edit
  PUT_MACHINETYPES_EDIT_REQUEST: 'PUT_MACHINETYPES_EDIT_REQUEST',
  PUT_MACHINETYPES_EDIT_SUCCESS: 'PUT_MACHINETYPES_EDIT_SUCCESS',
  PUT_MACHINETYPES_EDIT_FAIL: 'PUT_MACHINETYPES_EDIT_FAIL',

  //Delete
  DELETE_MACHINETYPES_DEL_REQUEST: 'DELETE_MACHINETYPES_DEL_REQUEST',
  DELETE_MACHINETYPES_DEL_SUCCESS: 'DELETE_MACHINETYPES_DEL_SUCCESS',
  DELETE_MACHINETYPES_DEL_FAIL: 'DELETE_MACHINETYPES_DEL_FAIL',

  //Upload Image 
  POST_MACHINETYPES_UPLOADIMAGE_REQUEST: 'POST_MACHINETYPES_UPLOADIMAGE_REQUEST',
  POST_MACHINETYPES_UPLOADIMAGE_SUCCESS: 'POST_MACHINETYPES_UPLOADIMAGE_SUCCESS',
  POST_MACHINETYPES_UPLOADIMAGE_FAIL: 'POST_MACHINETYPES_UPLOADIMAGE_FAIL',

  //Delete Image 
  DELETE_MACHINETYPES_DELIMAGE_REQUEST: 'DELETE_MACHINETYPES_DELIMAGE_REQUEST',
  DELETE_MACHINETYPES_DELIMAGE_SUCCESS: 'DELETE_MACHINETYPES_DELIMAGE_SUCCESS',
  DELETE_MACHINETYPES_DELIMAGE_FAIL: 'DELETE_MACHINETYPES_DELIMAGE_FAIL',

  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {

  // MACHINE TYPES ----------
  dataMachineTypesList: [],
  loadingMachineTypesList: false,
  errorMachineTypesList: false,
  errorDataMachineTypesList: false,
  dataMachineTypesListUpdate: false,

  loadingMachineTypesAdd: false,
  errorMachineTypesAdd: false,
  errorDataMachineTypesAdd: false,

  loadingMachineTypesEdit: false,
  errorMachineTypesEdit: false,
  errorDataMachineTypesEdit: false,

  loadingMachineTypesDelete: false,
  errorMachineTypesDelete: false,
  errorDataMachineTypesDelete: false,

  loadingMachineTypesUploadImage: false,
  errorMachineTypesUploadImage: false,
  errorDataMachineTypesUploadImage: false,

  loadingMachineTypesDelImage: false,
  errorMachineTypesDelImage: false,
  errorDataMachineTypesDelImage: false,

};

export default function machine_types(state = INITIAL_STATE, action) {
  switch (action.type) {

    // MACHINE TYPES ----------
    //Get - List
    case Types.GET_MACHINETYPES_LIST_REQUEST:
      return {
        ...state,
        loadingMachineTypesList: true,
        errorMachineTypesList: false,
      };
    case Types.GET_MACHINETYPES_LIST_SUCCESS:
      return {
        ...state,
        dataMachineTypesList: action.payload,
        loadingMachineTypesList: false,
        errorMachineTypesList: false,
        errorDataMachineTypesList: false
      };
    case Types.GET_MACHINETYPES_LIST_FAIL:
      return {
        ...state,
        dataMachineTypesList: false,
        loadingMachineTypesList: false,
        errorMachineTypesList: true,
        errorDataMachineTypesList: action.payload,
      };

    case Types.SET_UPDATE_MACHINETYPES_LIST:
      return {
        ...state,
        dataMachineTypesListUpdate: action.payload,
      };

    //Store - Add
    case Types.POST_MACHINETYPES_ADD_REQUEST:
      return {
        ...state,
        loadingMachineTypesAdd: true,
        errorMachineTypesAdd: false,
      };
    case Types.POST_MACHINETYPES_ADD_SUCCESS:
      return {
        ...state,
        loadingMachineTypesAdd: false,
        errorMachineTypesAdd: false,
        errorDataMachineTypesAdd: false,
      };
    case Types.POST_MACHINETYPES_ADD_FAIL:
      return {
        ...state,
        loadingMachineTypesAdd: false,
        errorMachineTypesAdd: true,
        errorDataMachineTypesAdd: action.payload,
      };

    //Update - Edit
    case Types.PUT_MACHINETYPES_EDIT_REQUEST:
      return {
        ...state,
        loadingMachineTypesEdit: true,
        errorMachineTypesEdit: false,
      };
    case Types.PUT_MACHINETYPES_EDIT_SUCCESS:
      return {
        ...state,
        loadingMachineTypesEdit: false,
        errorMachineTypesEdit: false,
        errorDataMachineTypesEdit: false,
      };
    case Types.PUT_MACHINETYPES_EDIT_FAIL:
      return {
        ...state,
        loadingMachineTypesEdit: false,
        errorMachineTypesEdit: true,
        errorDataMachineTypesEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_MACHINETYPES_DEL_REQUEST:
      return {
        ...state,
        loadingMachineTypesDelete: true,
        errorMachineTypesDelete: false,
      };
    case Types.DELETE_MACHINETYPES_DEL_SUCCESS:
      return {
        ...state,
        loadingMachineTypesDelete: false,
        errorMachineTypesDelete: false,
        errorDataMachineTypesDelete: false,
      };
    case Types.DELETE_MACHINETYPES_DEL_FAIL:
      return {
        ...state,
        loadingMachineTypesDelete: false,
        errorMachineTypesDelete: true,
        errorDataMachineTypesDelete: action.payload,
      };

    //Upload Images 
    case Types.POST_MACHINETYPES_UPLOADIMAGE_REQUEST:
      return {
        ...state,
        loadingMachineTypesUploadImage: true,
        errorMachineTypesUploadImage: false,
      };
    case Types.POST_MACHINETYPES_UPLOADIMAGE_SUCCESS:
      return {
        ...state,
        loadingMachineTypesUploadImage: false,
        errorMachineTypesUploadImage: false,
        errorDataMachineTypesUploadImage: false,
      };
    case Types.POST_MACHINETYPES_UPLOADIMAGE_FAIL:
      return {
        ...state,
        loadingMachineTypesUploadImage: false,
        errorMachineTypesUploadImage: true,
        errorDataMachineTypesUploadImage: action.payload,
      };

    //Delete Images 
    case Types.DELETE_MACHINETYPES_DELIMAGE_REQUEST:
      return {
        ...state,
        loadingMachineTypesDelImage: true,
        errorMachineTypesDelImage: false,
      };
    case Types.DELETE_MACHINETYPES_DELIMAGE_SUCCESS:
      return {
        ...state,
        loadingMachineTypesDelImage: false,
        errorMachineTypesDelImage: false,
        errorDataMachineTypesDelImage: false,
      };
    case Types.DELETE_MACHINETYPES_DELIMAGE_FAIL:
      return {
        ...state,
        loadingMachineTypesDelImage: false,
        errorMachineTypesDelImage: true,
        errorDataMachineTypesDelImage: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        // MACHINE TYPES ----------
        dataMachineTypesList: [],
        loadingMachineTypesList: false,
        errorMachineTypesList: false,
        errorDataMachineTypesList: false,
        dataMachineTypesListUpdate: false,

        loadingMachineTypesAdd: false,
        errorMachineTypesAdd: false,
        errorDataMachineTypesAdd: false,

        loadingMachineTypesEdit: false,
        errorMachineTypesEdit: false,
        errorDataMachineTypesEdit: false,

        loadingMachineTypesDelete: false,
        errorMachineTypesDelete: false,
        errorDataMachineTypesDelete: false,

        loadingMachineTypesUploadImage: false,
        errorMachineTypesUploadImage: false,
        errorDataMachineTypesUploadImage: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  // MACHINE TYPES ----------
  //Get - List
  getMachineTypesListRequest: (payload) => ({
    type: Types.GET_MACHINETYPES_LIST_REQUEST,
    payload,
  }),

  getMachineTypesListRequestSuccess: (payload) => ({
    type: Types.GET_MACHINETYPES_LIST_SUCCESS,
    payload,
  }),

  getMachineTypesListRequestFail: (payload) => ({
    type: Types.GET_MACHINETYPES_LIST_FAIL,
    payload,
  }),

  setUpdateMachineTypes: (payload) => ({
    type: Types.SET_UPDATE_MACHINETYPES_LIST,
    payload,
  }),

  //Store - Add 
  postMachineTypesAddRequest: (payload) => ({
    type: Types.POST_MACHINETYPES_ADD_REQUEST,
    payload,
  }),

  postMachineTypesAddRequestSuccess: () => ({
    type: Types.POST_MACHINETYPES_ADD_SUCCESS,
  }),

  postMachineTypesAddRequestFail: (payload) => ({
    type: Types.POST_MACHINETYPES_ADD_FAIL,
    payload,
  }),

  //Update - Edit 
  putMachineTypesEditRequest: (payload) => ({
    type: Types.PUT_MACHINETYPES_EDIT_REQUEST,
    payload,
  }),

  putMachineTypesEditRequestSuccess: () => ({
    type: Types.PUT_MACHINETYPES_EDIT_SUCCESS,
  }),

  putMachineTypesEditRequestFail: (payload) => ({
    type: Types.PUT_MACHINETYPES_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteMachineTypesDelRequest: (payload) => ({
    type: Types.DELETE_MACHINETYPES_DEL_REQUEST,
    payload,
  }),

  deleteMachineTypesDelRequestSuccess: () => ({
    type: Types.DELETE_MACHINETYPES_DEL_SUCCESS,
  }),

  deleteMachineTypesDelRequestFail: (payload) => ({
    type: Types.DELETE_MACHINETYPES_DEL_FAIL,
    payload,
  }),

  //Upload Image 
  postMachineTypesUploadImageRequest: (payload) => ({
    type: Types.POST_MACHINETYPES_UPLOADIMAGE_REQUEST,
    payload,
  }),

  postMachineTypesUploadImageRequestSuccess: () => ({
    type: Types.POST_MACHINETYPES_UPLOADIMAGE_SUCCESS,
  }),

  postMachineTypesUploadImageRequestFail: (payload) => ({
    type: Types.POST_MACHINETYPES_UPLOADIMAGE_FAIL,
    payload,
  }),

  //Delete Image 
  deleteMachineTypesDelImageRequest: (payload) => ({
    type: Types.DELETE_MACHINETYPES_DELIMAGE_REQUEST,
    payload,
  }),

  deleteMachineTypesDelImageRequestSuccess: () => ({
    type: Types.DELETE_MACHINETYPES_DELIMAGE_SUCCESS,
  }),

  deleteMachineTypesDelImageRequestFail: (payload) => ({
    type: Types.DELETE_MACHINETYPES_DELIMAGE_FAIL,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
