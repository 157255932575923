import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';

import { Button, Input } from 'antd';

import {
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as AuthActions } from '../../../store/reducers/authentication';

//Styles And Helpers
import 'react-toastify/dist/ReactToastify.css';

//Contexts
import { LoginWrapper } from '../../../styles/globalStyles';

//Components
import Logo from '../../../assets/images/logo_iguacumaq.png';

//Variables

const loginSchema = yup.object().shape({
  email: yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
  password: yup.string()
    .min(5, 'A senha deve ter pelo menos 4 caracteres')
    .required('A senha é obrigatória'),
});

const Login = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loading } = useSelector((state) => (state.authentication))

  //States Local

  //Context

  //Cicle of Life

  //Functions
  const login = (values) => {
    const payload = values
    dispatch(AuthActions.loginRequest(payload));
  }

  //Render
  return (
    <>
      <LoginWrapper>
        <div className='container-login'>
          <div>
            <div className='content-logo'>
              <img src={Logo} alt="Iguaçu Máquinas" />
              <h1>Iguaçu Máquinas</h1>
            </div>
            <p className='sub-title' >Painel administrativo</p>

            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={loginSchema}
              onSubmit={values => (login(values))}
            >
              {({ handleSubmit, values, errors, touched }) => (
                <>
                  <div className='my-2'>
                    <Field className='input-default' name="email" type="email">
                      {({ field }) => {
                        return <Input
                          value={values.email}
                          {...field}
                          size="large"
                          placeholder="Email"
                          prefix={<UserOutlined />}
                        />
                      }}
                    </Field>
                    {errors.email && touched.email ? <div className='error my-2 px-2'>{errors.email}</div> : null}
                  </div>
                  <div className='my-2'>
                    <Field name="password" type="password">
                      {({ field }) => {
                        return <Input
                          type='password'
                          value={values.password}
                          {...field}
                          size="large"
                          placeholder="Senha"
                          prefix={<LockOutlined />}
                        />
                      }}
                    </Field>
                    {errors.password && touched.password ? <div className='error my-2 px-2'>{errors.password}</div> : null}
                  </div>
                  <div className='my-2'>
                    <Button
                      disabled={(loading) ? true : false}
                      loading={(loading) ? true : false}
                      onClick={() => handleSubmit()}
                      type="primary"
                      block
                    >
                      Entrar
                    </Button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </LoginWrapper>
    </>
  );
};

export default Login;