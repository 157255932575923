import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as AdministratorsActions,
  Types as AdministratorsTypes,
} from '../../reducers/administrators';

import {
  Creators as AuthActions,
} from '../../reducers/authentication';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listAdministrators({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin?page=${payload?.page}&first_name=${payload?.first_name}&last_name=${payload?.last_name}&email=${payload?.email}`
    );
    yield put(AdministratorsActions.setUpdateAdministrators(false));
    yield put(AdministratorsActions.getAdministratorsListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(AdministratorsActions.getAdministratorsListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

  }
}

function* addAdministrators({ payload }) {
  try {
    yield call(api.post, '/admin', payload);
    yield put(AdministratorsActions.setUpdateAdministrators(true));
    yield put(AdministratorsActions.postAdministratorsAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(AdministratorsActions.postAdministratorsAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editAdministrators({ payload }) {
  try {
    yield call(api.put, `/admin/${payload?.id}`, payload);

    if (payload?.id_user_logged === payload?.id) {
      yield put(AuthActions.logoutRequest());
    }


    yield put(AdministratorsActions.setUpdateAdministrators(true));
    yield put(AdministratorsActions.putAdministratorsEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(AdministratorsActions.putAdministratorsEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteAdministrators({ payload }) {
  try {
    yield call(api.delete, `/admin/${payload?.id}`);
    // console.log('data store', data)
    yield put(AdministratorsActions.setUpdateAdministrators(true));
    yield put(AdministratorsActions.postAdministratorsAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(AdministratorsActions.deleteAdministratorsDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listAdministratorsWatcher() {
  yield takeLatest(AdministratorsTypes.GET_ADMINISTRATORS_LIST_REQUEST, listAdministrators);
}

export function* addAdministratorsWatcher() {
  yield takeLatest(AdministratorsTypes.POST_ADMINISTRATORS_ADD_REQUEST, addAdministrators);
}

export function* editAdministratorsWatcher() {
  yield takeLatest(AdministratorsTypes.PUT_ADMINISTRATORS_EDIT_REQUEST, editAdministrators);
}

export function* deleteAdministratorsWatcher() {
  yield takeLatest(AdministratorsTypes.DELETE_ADMINISTRATORS_DEL_REQUEST, deleteAdministrators);
}

export default function* rootSaga() {
  yield all([
    fork(listAdministratorsWatcher),
    fork(addAdministratorsWatcher),
    fork(editAdministratorsWatcher),
    fork(deleteAdministratorsWatcher),
  ]);
}
