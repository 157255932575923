import React, { useState, useEffect } from 'react'
import { Breadcrumb, Row, Col, Button, Input, Layout, theme, Modal, Space, Table, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { convertDateBR } from './../../helpers/index' 
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios'
import api from './../../services/api';
import { toast } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

import {
  LoadingOutlined,
  ExclamationCircleFilled
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as QuotationsActions } from '../../store/reducers/quotations';
import { Creators as RefreshActions } from '../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components
import FooterDefault from '../../components/LayoutDefault/FooterDefault'

//Variables
const filterListSchema = yup.object().shape({
  email: yup.string()
    .min(2, 'O campo deve conter no mínimo 2 caractéres'),
});
const { Content } = Layout;
const { confirm } = Modal;

const Quotations = () => {

  // Registra o locale do date-fns para português do Brasil
  registerLocale('pt-BR', ptBR);

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataList, loadingList, dataListUpdate } = useSelector((state) => (state.quotations))

  //States Local
  const {
    token: { colorBgContainer, colorError, colorInfo, colorWarning },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // page 
  // id 
  // subitems_code
  // employee_email
  // doc
  // created_at

  const [filters, setFilters] = useState({
    id: '',
    subitems_code: '',
    employee_email: '',
    doc: '',
    created_at: '',
  });

  //Context

  //Cicle of Life
  useEffect(() => {
    document.title = 'Simulações - Iguaçu Máquinas';
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  useEffect(() => {
    updateDataList('1')
  }, []);

  useEffect(() => {
    if (dataListUpdate) {
      updateDataList('1')
    }
  }, [dataListUpdate]);


  //Functions
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const getQuotationPdf = (id) => {
    // setLoadingPdf(id);
    dispatch(QuotationsActions.getQuotationsPdfRequest({ id: id }))
  }

  const getQuotationsExportXLSID = async (id, social_reason_or_name) => {
    try {
      const token = localStorage.getItem('iguacu:token');
      const response = await axios.get(`${api.defaults.baseURL}/admin/orders/export/xlsx/` + id, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'blob', // Importante definir responseType como 'blob' para receber um arquivo binário
      });

      // Cria uma URL temporária para o arquivo blob
      const blob = new Blob([response.data], { type: 'application/csv' });
      const url = window.URL.createObjectURL(blob);

      // Cria um link para iniciar automaticamente o download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'simulacao' + social_reason_or_name + '.csv';
      link.click();

      // Limpa a URL temporária e o link
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Erro ao baixar o arquivo", {
        position: toast.POSITION.BOTTOM_RIGHT
      });

    }
  };

  const getQuotationsExportXLSALL = async () => {
    try {

      const dataAtual = new Date();
      const dia = dataAtual.getDate();
      const mes = dataAtual.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro é 0, fevereiro é 1, etc.)
      const ano = dataAtual.getFullYear();

      const token = localStorage.getItem('iguacu:token');
      const response = await axios.get(`${api.defaults.baseURL}/admin/orders/export/xlsx/dates`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'blob', // Importante definir responseType como 'blob' para receber um arquivo binário
      });

      // Cria uma URL temporária para o arquivo blob
      const blob = new Blob([response.data], { type: 'application/csv' });
      const url = window.URL.createObjectURL(blob);

      // Cria um link para iniciar automaticamente o download
      const link = document.createElement('a');
      link.href = url;
      link.download = `simulacoes ${dia}_${mes}_${ano}.csv`;
      link.click();

      // Limpa a URL temporária e o link
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Erro ao baixar o arquivo", {
        position: toast.POSITION.BOTTOM_RIGHT
      });

    }
  };

  const updateDataList = (selectedPage) => {
    let payloadList = {
      page: selectedPage,
      id: filters.id,
      subitems_code: filters.subitems_code,
      employee_email: filters.employee_email,
      doc: filters.doc,
      startDate: formatDate(startDate),
      endDate:  formatDate(endDate),
    }
    dispatch(QuotationsActions.getQuotationsListRequest(payloadList))
  }

  //---Filter
  const updateFilter = () => {
    updateDataList(1)
    setOpenModalFilter(false)
  }

  const setFieldFilter = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const resetFilters = () => {
    setFilters({
      id: '',
      subitems_code: '',
      employee_email: '',
      doc: '',
      startDate: '',
      endDate: '',
    });
    setStartDate("");
    setEndDate("");

    let payloadList = {
      page: '1',
      id: '',
      subitems_code: '',
      employee_email: '',
      doc: '',
      startDate: '',
      endDate: '',
    }
    dispatch(QuotationsActions.getQuotationsListRequest(payloadList))
  }

  const showModalFilter = () => {
    setOpenModalFilter(true);
  }
  const hideModalFilter = () => {
    setOpenModalFilter(false);
  }

  const showDeleteConfirm = (data) => {
    confirm({
      title: `Tem certeza que deseja deletar ?`,
      icon: <ExclamationCircleFilled />,
      okText: 'SIM',
      okType: '',
      cancelText: 'NÃO',
      onOk() {
        dispatch(QuotationsActions.deleteQuotationsDelRequest({ id: data?.id }))
      },
    });
  };

  //---Configurations Columns Table
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      responsive: ['sm'],
      render: (text, record, i) =>
        <div className='row px-2 align-center'>
          {text}
        </div>
      ,
    },
    {
      title: 'PDF',
      dataIndex: 'first_name',
      key: 'first_name',
      responsive: ['sm'],
      render: (text, record, i) =>
        <div className='row px-2'>
          <Button
            size='small'
            className='m-2'
            onClick={() => {
              getQuotationPdf(record?.id)
            }}
            style={{
              background: colorWarning,
            }}
            type='primary'
          >
            PDF
          </Button>
          <Button
            size='small'
            className='m-2'
            onClick={() => {
              getQuotationsExportXLSID(record?.id, record?.customer?.codigo + record?.customer?.store + record?.customer?.social_reason_or_name)
            }}
            style={{
              background: colorWarning,
            }}
            type='primary'
          >
            Exportar
          </Button>
        </div>
      ,
    },
    {
      title: 'Email Vendedor',
      dataIndex: 'emailVendedor',
      key: 'emailVendedor',
      responsive: ['sm'],
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.employee?.email}
        </div>
      ,
    },
    {
      title: 'Código da Simulação',
      dataIndex: 'subitems_code',
      key: 'subitems_code',
      responsive: ['sm'],
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.subitems_code}
        </div>
      ,
    },
    {
      title: 'CPF/CNPJ Cliente',
      dataIndex: 'cpf_cnpj_client',
      key: 'cpf_cnpj_client',
      responsive: ['sm'],
      render: (text, record, i) =>
        <div className='row px-2'>
          {record?.customer?.doc}
        </div>
      ,
    },
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['sm'],
      render: (text, record, i) =>
        <div className='row px-2'>
          {convertDateBR(record?.created_at)}
        </div>
      ,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      responsive: ['sm'],
      render: (text, record, index) => (
        < Space size="small" >
          <Button
            size='small' type="primary" style={{ background: colorError }}
            onClick={() => showDeleteConfirm(record)}
          >
            Deletar
          </Button>
        </Space >
      ),
    },
  ];

  const formatDate = (date) => {
    return date ? format(date, 'yyyy-MM-dd') : '';
  };

  function CustomDatePickerInputInit({ value, onClick }) {
    return (
      <input
        className='input-default'
        type="text"
        onClick={onClick}
        value={value}
        placeholder="Data Inicial"
        // readOnly
        style={{
          padding: '6px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          border: '1px solid #d3d3d3',
        }}
      />
    );
  }

  function CustomDatePickerInputFinal({ value, onClick }) {
    return (
      <input
        className='input-default'
        type="text"
        onClick={onClick}
        value={value}
        placeholder="Data Final"
        // readOnly
        style={{
          padding: '6px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          border: '1px solid #d3d3d3',
        }}
      />
    );
  }

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{ title: 'Simulações' }]}
        />
        <div
          className='px-4 py-3'
          style={{
            background: colorBgContainer,
          }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={showModalFilter}
                >
                  Adicionar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    resetFilters()
                  }}
                  style={{
                    background: colorError,
                  }}
                  type='primary'
                >
                  Limpar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    getQuotationsExportXLSALL()
                  }}
                  style={{
                    background: colorWarning,
                  }}
                  type='primary'
                >
                  Exportar últimos 60 dias
                </Button>
              </div>
            </div>
            <div className='table-content'>
              <Table pagination={false} loading={(loadingList) && { indicator: <LoadingOutlined /> }} rowKey="id" columns={columns} dataSource={dataList?.data} />
            </div>
          </div>
          {
            dataList?.last_page > 1 &&
            <div className='m-3 text-right'>
              <Pagination
                pageSize={dataList?.per_page}
                showSizeChanger={false}
                showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} itens`}
                onChange={(e) => updateDataList(e)}
                current={dataList?.current_page}
                defaultPageSize={`${dataList?.per_page}`}
                total={dataList?.total}
              />
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Filtros"
          open={openModalFilter}
          footer={null}
          onCancel={hideModalFilter}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                id: filters.id,
                subitems_code: filters.subitems_code,
                employee_email: filters.employee_email,
                doc: filters.doc,
                created_at: filters.created_at,
              }}
              validationSchema={filterListSchema}
              onSubmit={() => (
                updateFilter()
              )
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='p-2' htmlFor="subitems_code">Código Simulação</label>
                      <Input
                        className='input-default'
                        value={filters.subitems_code}
                        onChange={(e) => setFieldFilter('subitems_code', e.target.value)}
                        size="large"
                        placeholder="Código Simulação"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='p-2' htmlFor="employee_email">Email Vendedor</label>
                      <Input
                        className='input-default'
                        value={filters.employee_email}
                        onChange={(e) => setFieldFilter('employee_email', e.target.value)}
                        size="large"
                        placeholder="Email Vendedor"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="doc">CPF/CNPJ Cliente</label>
                      <Input
                        className='input-default'
                        value={filters.doc}
                        onChange={(e) => setFieldFilter('doc', e.target.value)}
                        size="large"
                        placeholder="CPF/CNPJ"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="created_at">Data Inicial / Data Final</label>
                      <DatePicker
                        customInput={<CustomDatePickerInputInit />} // Usa o componente personalizado // Usa o input personalizado
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Data de Início"
                        locale="pt-BR" // Define o locale para português do Brasil
                        dateFormat="dd/MM/yyyy"
                      />
                      <DatePicker
                        customInput={<CustomDatePickerInputFinal />} // Usa o componente personalizado // Usa o input personalizado
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="Data de Término"
                        locale="pt-BR" // Define o locale para português do Brasil
                        dateFormat="dd/MM/yyyy"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        size='small'
                        className='m-2'
                        disabled={(loadingList) ? true : false}
                        loading={(loadingList) ? true : false}
                        onClick={() => handleSubmit(values)}
                        type="primary"
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>
        <FooterDefault />
      </Content>
    </>
  );
};

export default Quotations;