import React, { useState, useEffect } from 'react'
import { Breadcrumb, Row, Col, Button, Input, Layout, theme, Modal, Space, Table, Drawer, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  CloseOutlined,
  LoadingOutlined,
  ExclamationCircleFilled,
  CheckOutlined
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as FreightActions } from '../../store/reducers/freight';
import { Creators as RefreshActions } from '../../store/reducers/refresh';

//Styles And Helpers
import { convertDateBR, converterforReal } from '../../helpers/index'

//Contexts

//Components
import FooterDefault from '../../components/LayoutDefault/FooterDefault'
import Edit from './actions/Edit';
import Add from './actions/Add';

//Variables
const filterListSchema = yup.object().shape({
  email: yup.string()
    .min(2, 'O campo deve conter no mínimo 2 caractéres'),
});
const { Content } = Layout;
const { confirm } = Modal;

const Freight = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataFreightList, loadingFreightList, dataFreightListUpdate } = useSelector((state) => (state.freight))

  //States Local
  const {
    token: { colorBgContainer, colorSuccess, colorError, colorInfo },
  } = theme.useToken();

  //---- Open Filter Table
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const title = 'Frete'
  const [openTypeContentDrawer, setOpenTypeContentDrawer] = useState({
    edit: {
      status: true,
      title: `Editar ${title}`,
      dataEdit: {}
    },
    add: {
      status: false,
      title: `Adicionar ${title}`
    },
  });
  const [filters, setFilters] = useState({ filial: '', freight: '', fees_boarding: '', city: '' });

  //Context

  //Cicle of Life
  useEffect(() => {
    document.title = 'Frete - Iguaçu Máquinas';
    const autoLogin = async () => {
      const refreshToken = await AsyncStorage.getItem('iguacu:refresh');
      dispatch(RefreshActions.getRefreshRequest({ token: refreshToken }));
      return refreshToken
    }
    autoLogin()
  }, []);

  useEffect(() => {
    updateDataList('1')
  }, []);

  useEffect(() => {
    if (dataFreightListUpdate) {
      updateDataList('1')
    }
  }, [dataFreightListUpdate]);


  //Functions
  const updateDataList = (selectedPage) => {
    let payloadList = {
      page: selectedPage,
      filial: filters.filial,
      freight: filters.freight,
      fees_boarding: filters.fees_boarding,
      city: filters.city
    }
    // console.log('payloadList', payloadList)
    dispatch(FreightActions.getFreightListRequest(payloadList))
  }

  //---Filter
  const updateFilter = () => {
    updateDataList(1)
    setOpenModalFilter(false)
  }

  const setFieldFilter = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const resetFilters = () => {
    setFilters({ filial: '', freight: '', fees_boarding: '', city: '' });
    let payloadList = {
      page: '1',
      filial: '',
      freight: '',
      fees_boarding: '',
      city: ''
    }

    // console.log('payloadList', payloadList)
    dispatch(FreightActions.getFreightListRequest(payloadList))
  }

  const showModalFilter = () => {
    setOpenModalFilter(true);
  }
  const hideModalFilter = () => {
    setOpenModalFilter(false);
  }

  const showDeleteConfirm = (data) => {
    confirm({
      title: `Tem certeza que deseja deletar ?`,
      icon: <ExclamationCircleFilled />,
      okText: 'SIM',
      okType: '',
      cancelText: 'NÃO',
      onOk() {
        // console.log('OK');
        dispatch(FreightActions.deleteFreightDelRequest({ id: data?.id }))
      },
    });
  };

  //---Drawer Actions Adicionar || Deletar
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setOpenTypeContentDrawer({
      edit: {
        status: false,
        title: `Editar ${title}`,
        dataEdit: {}
      },
      add: {
        status: false,
        title: `Adicionar ${title}`
      },
    })
  };

  const handleChangeTypeContentDrawer = (name, dataEdit = {}) => {
    if (name === 'add') {
      setOpenTypeContentDrawer({
        edit: {
          status: false,
          title: `Editar ${title}`,
          dataEdit: {}
        },
        add: {
          status: true,
          title: `Adicionar ${title}`
        },
      })
    } else if (name === 'edit') {
      setOpenTypeContentDrawer({
        edit: {
          status: true,
          title: `Editar ${title}`,
          dataEdit: dataEdit
        },
        add: {
          status: false,
          title: `Adicionar ${title}`
        },
      })
    }
    showDrawer()
  }

  const getTitle = () => {
    if (openTypeContentDrawer?.add?.status) {
      return openTypeContentDrawer?.add?.title
    } else if (openTypeContentDrawer?.edit?.status) {
      return openTypeContentDrawer?.edit?.title
    }
  }

  //---Configurations Columns Table
  const columns = [
    {
      title: 'Filial',
      dataIndex: 'filial',
      key: 'filial',
      render: (text, i) =>
      <div >
        {text}
      </div>
    ,
    },
    {
      title: 'Cidade',
      dataIndex: 'city',
      key: 'city',
      render: (text, i) =>
        <div >
          {text}
        </div>
      ,
    },
    {
      title: 'Frete',
      dataIndex: 'freight',
      key: 'freight',
      render: (text, i) =>
        <div >
          {converterforReal(text)}
        </div>
      ,
    },
    {
      title: 'Taxa de Embarque',
      dataIndex: 'fees_boarding',
      key: 'fees_boarding',
      render: (text, i) =>
        <div >
          {converterforReal(text)}
        </div>
      ,
    },
    {
      title: 'Ativo',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text, i) =>
        <div >
          {(text === 1) ?
            <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
            :
            <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
          }
        </div>
      ,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        <Space size="middle" >
          {
            record?.deleted_at !== null ?
              <div className='flex-column justify-content-center '>
                <div className='align-items-center'>
                  {`Deletado em:`}
                </div>
                <div className='justify-content-center'>
                  {`${convertDateBR(record?.deleted_at)}`}
                </div>
              </div>
              :
              <>
                <Button
                  size='small' type="primary" style={{ background: colorInfo }}
                  onClick={() => handleChangeTypeContentDrawer('edit', record)}
                >
                  editar
                </Button>
                <Button
                  size='small' type="primary" style={{ background: colorError }}
                  onClick={() => showDeleteConfirm(record)}
                >
                  Deletar
                </Button>
              </>
          }
        </Space >
      ),
    },
  ];

  //Render
  return (
    <>
      <Content>
        <Breadcrumb
          className='breadcrumb px-3 py-2'
          items={[{ title: 'Frete' }]}
        />
        <div
          className='px-4 py-3'
          style={{
            background: colorBgContainer,
          }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  type="primary"
                  style={{ background: colorInfo }}
                  onClick={showModalFilter}
                >
                  Adicionar Filtros
                </Button>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    resetFilters()
                  }}
                  style={{
                    background: colorError,
                  }}
                  type='primary'
                >
                  Limpar Filtros
                </Button>
              </div>
              <div className='p-2'>
                <Button
                  size='small'
                  className='m-2'
                  onClick={() => {
                    handleChangeTypeContentDrawer('add', true)
                  }}
                  style={{
                    background: colorSuccess,
                  }}
                  type='primary'
                >
                  Adicionar
                </Button>
              </div>
            </div>
          </div>
          <div className='table-content'>
            <Table
              rowClassName={(record, index) => record?.deleted_at !== null ? 'table-row-alerted' : ''}
              pagination={false}
              loading={(loadingFreightList) && { indicator: <LoadingOutlined /> }}
              rowKey="id"
              columns={columns}
              dataSource={dataFreightList?.data}
            />
          </div>
          {
            dataFreightList?.last_page > 1 &&
            <div className='m-3 text-right'>
              <Pagination
                pageSize={dataFreightList?.per_page}
                showSizeChanger={false}
                showTotal={(total, range) => `${total} itens`}
                onChange={(e) => updateDataList(e)}
                current={dataFreightList?.current_page}
                defaultPageSize={`${dataFreightList?.per_page}`}
                total={dataFreightList?.total}
              />
            </div>
          }
        </div>

        {/* Modal Filters */}
        <Modal title="Filtros"
          open={openModalFilter}
          footer={null}
          onCancel={hideModalFilter}
        >
          <div className='p-2' style={{ backgroundColor: '#f0f2f5' }}>
            <Formik
              initialValues={{
                filial: filters?.filial,
                freight: filters?.freight,
                fees_boarding: filters?.fees_boarding,
                city: filters?.city,
              }}
              validationSchema={filterListSchema}
              onSubmit={() => (
                updateFilter()
              )
              }
            >
              {({ handleSubmit, values, errors, touched }) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="filial">Filial</label>
                      <Input
                        className='input-default'
                        value={filters.filial}
                        onChange={(e) => setFieldFilter('filial', e.target.value)}
                        size="large"
                        placeholder="Filial"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="fees_boarding">Taxa de Embarque</label>
                      <Input
                        className='input-default'
                        value={filters.fees_boarding}
                        onChange={(e) => setFieldFilter('fees_boarding', e.target.value)}
                        size="large"
                        placeholder="Taxa de Embarque"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <label className='p-2' htmlFor="discount">Frete</label>
                      <Input
                        className='input-default'
                        value={filters.freight}
                        onChange={(e) => setFieldFilter('freight', e.target.value)}
                        size="large"
                        placeholder="Frete"
                      />
                    </Col>
                    <Col span={12}>
                      <label className='p-2' htmlFor="city">Cidade</label>
                      <Input
                        className='input-default'
                        value={filters.city}
                        onChange={(e) => setFieldFilter('city', e.target.value)}
                        size="large"
                        placeholder="Cidade"
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        size='small'
                        className='m-2'
                        disabled={(loadingFreightList) ? true : false}
                        loading={(loadingFreightList) ? true : false}
                        onClick={() => handleSubmit(values)}
                        type="primary"
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal>

        {/* Modal Actions Edit | Add */}
        <Drawer
          title={getTitle()}
          placement={'right'}
          closable={true}
          onClose={onCloseDrawer}
          open={openDrawer}
          key={'right'}
          closeIcon={<CloseOutlined />}
          width={'40%'}
        >
          <>
            {
              (openTypeContentDrawer?.edit.status) &&
              <>
                <Edit dataEdit={openTypeContentDrawer?.edit?.dataEdit}></Edit>
              </>
            }

            {
              (openTypeContentDrawer?.add.status) &&
              <>
                <Add ></Add>
              </>
            }
          </>
        </Drawer>
        <FooterDefault />
      </Content>
    </>
  );
};

export default Freight;