export const Types = {
  //Get - List
  GET_QUOTATIONS_LIST_REQUEST: 'GET_QUOTATIONS_LIST_REQUEST',
  GET_QUOTATIONS_LIST_SUCCESS: 'GET_QUOTATIONS_LIST_SUCCESS',
  GET_QUOTATIONS_LIST_FAIL: 'GET_QUOTATIONS_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_QUOTATIONS_LIST: 'SET_UPDATE_QUOTATIONS_LIST',

  //Get PDF
  GET_QUOTATIONS_PDF: 'GET_QUOTATIONS_PDF',

  //Delete
  DELETE_QUOTATIONS_DEL_REQUEST: 'DELETE_QUOTATIONS_DEL_REQUEST',
  DELETE_QUOTATIONS_DEL_SUCCESS: 'DELETE_QUOTATIONS_DEL_SUCCESS',
  DELETE_QUOTATIONS_DEL_FAIL: 'DELETE_QUOTATIONS_DEL_FAIL',


  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {
  dataList: [],
  loadingList: false,
  errorList: false,
  errorDataList: false,
  dataListUpdate: false,

  loadingDelete: false,
  errorDelete: false,
  errorDataDelete: false,

};

export default function Quotations(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Get - List
    case Types.GET_QUOTATIONS_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorList: false,
      };
    case Types.GET_QUOTATIONS_LIST_SUCCESS:
      return {
        ...state,
        dataList: action.payload,
        loadingList: false,
        errorList: false,
        errorDataList: false
      };
    case Types.GET_QUOTATIONS_LIST_FAIL:
      return {
        ...state,
        dataList: false,
        loadingList: false,
        errorList: true,
        errorDataList: action.payload,
      };

    case Types.SET_UPDATE_QUOTATIONS_LIST:
      return {
        ...state,
        dataListUpdate: action.payload,
      };

    //Delete 
    case Types.DELETE_QUOTATIONS_DEL_REQUEST:
      return {
        ...state,
        loadingDelete: true,
        errorDelete: false,
      };
    case Types.DELETE_QUOTATIONS_DEL_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: false,
        errorDataDelete: false,
      };
    case Types.DELETE_QUOTATIONS_DEL_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: true,
        errorDataDelete: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        dataList: false,
        loadingList: false,
        errorList: false,
        errorDataList: false,
        dataListUpdate: false,

        loadingDelete: false,
        errorDelete: false,
        errorDataDelete: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  //Get - List
  getQuotationsListRequest: (payload) => ({
    type: Types.GET_QUOTATIONS_LIST_REQUEST,
    payload,
  }),
  getQuotationsListRequestSuccess: (payload) => ({
    type: Types.GET_QUOTATIONS_LIST_SUCCESS,
    payload,
  }),
  getQuotationsListRequestFail: (payload) => ({
    type: Types.GET_QUOTATIONS_LIST_FAIL,
    payload,
  }),

  setUpdateQuotations: (payload) => ({
    type: Types.SET_UPDATE_QUOTATIONS_LIST,
    payload,
  }),

  //PDF
  getQuotationsPdfRequest: (payload) => ({
    type: Types.GET_QUOTATIONS_PDF,
    payload,
  }),

  //Delete 
  deleteQuotationsDelRequest: (payload) => ({
    type: Types.DELETE_QUOTATIONS_DEL_REQUEST,
    payload,
  }),
  deleteQuotationsDelRequestSuccess: () => ({
    type: Types.DELETE_QUOTATIONS_DEL_SUCCESS,
  }),
  deleteQuotationsDelRequestFail: (payload) => ({
    type: Types.DELETE_QUOTATIONS_DEL_FAIL,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
