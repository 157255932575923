import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';

import { useLocation } from 'react-router-dom';

import { Button, Input } from 'antd';

import {
  LockOutlined
} from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as AuthActions } from '../../../store/reducers/authentication';

//Styles And Helpers
import 'react-toastify/dist/ReactToastify.css';

//Contexts
import { LoginWrapper } from '../../../styles/globalStyles';

//Components
import Logo from '../../../assets/logo.svg';

//Variables

const alterPasswordSchema = yup.object().shape({
  password: yup.string()
    .min(5, 'A senha deve ter pelo menos 5 caracteres')
    .required('A senha é obrigatória'),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'A confirmação de senha deve ser igual à senha')
    .min(5, 'A confirmação senha deve ter pelo menos 5 caracteres')
    .required('A confirmação senha é obrigatória'),
});

const NewPassword = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingResetPassword } = useSelector((state) => (state.authentication))

  //States Local

  //Context

  //Cicle of Life

  //Functions
  const alterPassword = (values) => {
    const payload = values
    dispatch(AuthActions.resetPasswordRequest(payload));
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  //Render
  return (
    <>
      <LoginWrapper>
        <div className='container-login'>
          <div>
            <div className='content-logo'>
              <img src={Logo} alt="Iguaçu Máquinas" />
              <h1>Iguaçu Máquinas</h1>
            </div>
            <p className='sub-title' >Alterar senha do vendedor <br/> no aplicativo IGPRICE</p>

            <Formik
              initialValues={{
                token: token,
                email: email,
                password: '',
                password_confirmation: ''
              }}
              validationSchema={alterPasswordSchema}
              onSubmit={values => (alterPassword(values))}
            >
              {({ handleSubmit, values, errors, touched }) => (
                <>
                  <div className='my-2'>
                    <Field name="password" type="password">
                      {({ field }) => {
                        return <Input
                          type='password'
                          value={values.password}
                          {...field}
                          size="large"
                          placeholder="Senha"
                          prefix={<LockOutlined />}
                        />
                      }}
                    </Field>
                    {errors.password && touched.password ? <div className='error my-2 px-2'>{errors.password}</div> : null}
                  </div>
                  <div className='my-2'>
                    <Field name="password_confirmation" type="password">
                      {({ field }) => {
                        return <Input
                          type='password'
                          value={values.password_confirmation}
                          {...field}
                          size="large"
                          placeholder="Confirmação de Senha"
                          prefix={<LockOutlined />}
                        />
                      }}
                    </Field>
                    {errors.password_confirmation && touched.password_confirmation ? <div className='error my-2 px-2'>{errors.password_confirmation}</div> : null}
                  </div>
                  <div className='my-2'>
                    <Button
                      disabled={(loadingResetPassword) ? true : false}
                      loading={(loadingResetPassword) ? true : false}
                      onClick={() => handleSubmit()}
                      type="primary"
                      block
                    >
                      Salvar
                    </Button>
                  </div>
                </>
              )}
            </Formik>

          </div>
        </div>
      </LoginWrapper>
    </>
  );
};

export default NewPassword;