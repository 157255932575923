import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as SubitensActions,
  Types as SubitensTypes,
} from '../../reducers/subitens';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listSubitens({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/sub_items?page=${payload?.page}&name=${payload?.name}&code=${payload?.code}&group%5Bname%5D%3D=${payload?.group}&brand_code=${payload?.brand_code}&product_model_code=${payload?.product_model_code}&machine_types_code=${payload?.machine_types_code}`
    );
    yield put(SubitensActions.setUpdateSubitens(false));
    yield put(SubitensActions.getSubitensListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(SubitensActions.getSubitensListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addSubitens({ payload }) {
  try {
    yield call(api.post, '/admin/sub_items', payload);
    yield put(SubitensActions.setUpdateSubitens(true));
    yield put(SubitensActions.postSubitensAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(SubitensActions.postSubitensAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

  }
}

function* editSubitens({ payload }) {
  try {
    yield call(api.put, `/admin/sub_items/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(SubitensActions.setUpdateSubitens(true));
    yield put(SubitensActions.putSubitensEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(SubitensActions.putSubitensEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

  }
}

function* deleteSubitens({ payload }) {
  try {
    yield call(api.delete, `/admin/sub_items/${payload?.id}`);
    // console.log('data store', data)
    yield put(SubitensActions.setUpdateSubitens(true));
    yield put(SubitensActions.postSubitensAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(SubitensActions.deleteSubitensDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listSubitensWatcher() {
  yield takeLatest(SubitensTypes.GET_SUBITENS_LIST_REQUEST, listSubitens);
}

export function* addSubitensWatcher() {
  yield takeLatest(SubitensTypes.POST_SUBITENS_ADD_REQUEST, addSubitens);
}

export function* editSubitensWatcher() {
  yield takeLatest(SubitensTypes.PUT_SUBITENS_EDIT_REQUEST, editSubitens);
}

export function* deleteSubitensWatcher() {
  yield takeLatest(SubitensTypes.DELETE_SUBITENS_DEL_REQUEST, deleteSubitens);
}

export default function* rootSaga() {
  yield all([
    fork(listSubitensWatcher),
    fork(addSubitensWatcher),
    fork(editSubitensWatcher),
    fork(deleteSubitensWatcher),
  ]);
}
