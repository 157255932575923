import React, { useEffect } from 'react'
import { Col, Row, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import * as yup from 'yup'; 

//Dispatchs
import { Creators as AdministratorsActions } from '../../../../store/reducers/administrators';
import { convertDateBR } from '../../../../helpers';

//Styles And Helpers

//Contexts

//Components

//Variables
const ShowSchema = yup.object().shape({
  first_name: yup.string()
    .min(2, 'A nome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  last_name: yup.string()
    .min(2, 'A sobrenome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  email: yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
});
// { first_name: '', last_name: '', email: '', password: '', is_active: '', avatar: '' }

const Show = ({ dataShow }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer

  //States Local

  //Context

  //Cicle of Life
  useEffect(() => {

  }, []);

  //Functions
  const submitEdit = (values) => {
    const payload = values
    dispatch(AdministratorsActions.putAdministratorsEditRequest(payload))
  }


  //Render
  return (
    <>
      <div className='p-3 content-box-form'>
        <Formik
          initialValues={{
            code: dataShow?.code,
            name: dataShow?.name,
            deleted_at: dataShow?.deleted_at,
          }}
          validationSchema={ShowSchema}
          onSubmit={(values) => (
            submitEdit(values)
          )
          }
        >
          {({ setFieldValue, handleSubmit, values, errors, touched }) => (
            <>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={24}>
                  <label className='px-2 ant-form-item-required' htmlFor="name">Código</label>
                  <Input
                    className='input-default'
                    value={values.code}
                    size="Small"
                    placeholder="Código"
                  />
                </Col>
                <Col className='my-2' span={24}>
                  <label className='px-2 ant-form-item-required' htmlFor="name">Grupo</label>
                  <Input
                    className='input-default'
                    value={values.name}
                    size="Small"
                    placeholder="Grupo"
                  />
                </Col>
              </Row>
              {
                values.deleted_at &&
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="deleted_at">Deletado Em:</label>
                    <Input
                      className='input-default'
                      value={convertDateBR(values.deleted_at)}
                      size="Small"
                      placeholder="Deletado em:"
                    />
                  </Col>
                </Row>
              }
            </>
          )}
        </Formik >
      </div >
    </>
  );
};

export default Show;