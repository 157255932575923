import { Button, Result } from 'antd';
import React from 'react';
const NoFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle="Desculpa está página não existe."
    extra={(
      <Button type="primary" onClick={() => console.log('voltar')}>
        Back Home
      </Button>
)}
  />
);

export default NoFoundPage;
