import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as EmployeesActions,
  Types as EmployeesTypes,
} from '../../reducers/employees';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';


function* listEmployees({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/employees?page=${payload?.page}&first_name=${payload?.first_name}&last_name=${payload?.last_name}&email=${payload?.email}`
    );
    yield put(EmployeesActions.setUpdateEmployees(false));
    yield put(EmployeesActions.getEmployeesListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(EmployeesActions.getEmployeesListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editEmployees({ payload }) {
  try {
    yield call(api.put, `/admin/employees/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(EmployeesActions.setUpdateEmployees(true));
    yield put(EmployeesActions.putEmployeesEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(EmployeesActions.putEmployeesEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteEmployees({ payload }) {
  try {
    yield call(api.delete, `/admin/employees/${payload?.id}`);
    // console.log('data store', data)
    yield put(EmployeesActions.setUpdateEmployees(true));
    yield put(EmployeesActions.postEmployeesAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(EmployeesActions.deleteEmployeesDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* forguetPassword({ payload }) {
  try {
    const {
      data,
    } = yield call(api.post, '/employees/request_password_reset', payload);
    yield put(EmployeesActions.forguetPasswordRequestSuccess(data));
  } catch (error) {
    yield put(EmployeesActions.forguetPasswordRequestFail(error?.response?.data));
  }
}

export function* listEmployeesWatcher() {
  yield takeLatest(EmployeesTypes.GET_EMPLOYEES_LIST_REQUEST, listEmployees);
}

export function* editEmployeesWatcher() {
  yield takeLatest(EmployeesTypes.PUT_EMPLOYEES_EDIT_REQUEST, editEmployees);
}

export function* deleteEmployeesWatcher() {
  yield takeLatest(EmployeesTypes.DELETE_EMPLOYEES_DEL_REQUEST, deleteEmployees);
}

export function* forguetPasswordWatcher() {
  yield takeLatest(EmployeesTypes.FORGUETPASSWORD_REQUEST, forguetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(listEmployeesWatcher),
    fork(editEmployeesWatcher),
    fork(deleteEmployeesWatcher),
    fork(forguetPasswordWatcher),
  ]);
}
