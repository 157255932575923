import { all } from 'redux-saga/effects';
import authSagas from './authentication';
import administratorsSagas from './administrators';
import quotationsSagas from './quotations';
import employeesSagas from './employees';
import customersSagas from './customers';
import suppliersSagas from './suppliers';
import discountGroupSagas from './discount_group';
import discountProductSagas from './discount_product';
import messagesSagas from './messages';
import refreshSagas from './refresh';

import brandsSagas from './brands';
import machineTypesSagas from './machine_types';
import productModelsSagas from './product_models';
import productsSagas from './products';

import groupsSagas from './groups';
import subitensSagas from './subitens';

import userSagas from './user';
import freightSagas from './freigth';

export default function* rootSaga() {
  yield all([
    authSagas(),
    administratorsSagas(),
    quotationsSagas(),
    employeesSagas(),
    customersSagas(),
    suppliersSagas(),
    discountGroupSagas(),
    discountProductSagas(),
    messagesSagas(),
    refreshSagas(),

    brandsSagas(),
    machineTypesSagas(),
    productModelsSagas(),
    productsSagas(),
    groupsSagas(),
    subitensSagas(),

    userSagas(),
    freightSagas()
  ]);
}
