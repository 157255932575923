import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Switch, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import api from '../../../../services/api';

//Dispatchs
import { Creators as DiscountProductActions } from '../../../../store/reducers/discount_product';
import { Creators as RefreshActions } from '../../../../store/reducers/refresh';

//Styles And Helpers

//Contexts

//Components

//Variables
const EditSchema = yup.object().shape({
  name: yup.string()
    .required('A nome é obrigatória'),
  discount: yup.string()
    .max(6, 'O desconto inválido, ele deve ser assim Ex: 11.58 até 100.00')
    .typeError('O desconto deve ser um número')
    .test('validar-ponto-flutuante', 'O desconto deve ser um número em %, Ex: 11.58 até 100.00', value => {
      const regex = /^(100(\.00)?|\d{1,2}(\.\d{1,2})?)$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('A desconto é obrigatório')
});

const Edit = ({ dataEdit }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingEdit } = useSelector((state) => (state.discount_product))

  //States Local
  const [dataDiscountProduct, setDataDiscountProduct] = useState(null);

  //Context

  //Cicle of Life
  useEffect(() => {
    fetchGetDiscountProduct()
  }, []);


  //Functions
  async function fetchGetDiscountProduct() {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/discount_product/${dataEdit?.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setDataDiscountProduct(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  const submitEdit = (values) => {
    const payload = values
    dispatch(DiscountProductActions.putDiscountProductEditRequest(payload))
  }

  const [loader, setLoader] = useState(null);

  //Render
  return (
    <>
      <div className='p-2 content-box-form'>
        {loader &&
          <div className='p-2'>
            <LoadingOutlined />
          </div>
        }
        {
          dataDiscountProduct?.id &&
          <Formik
            initialValues={{
              id: dataDiscountProduct?.id,
              name: dataDiscountProduct?.name,
              discount: dataDiscountProduct?.discount,
              is_active: (dataDiscountProduct?.is_active === 0) ? false : true,
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => (
              submitEdit(values)
            )
            }
          >
            {({ setFieldValue, handleSubmit, values, errors, touched }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="name">Nome do Grupo<b className='text-danger'>*</b></label>
                    <Field name="name" type="text" key={'name'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.name}
                          {...field}
                          size="Small"
                          placeholder="Nome do Grupo"
                        />
                      }}
                    </Field>
                    {errors.name && touched.name ? <div className='error my-2 px-2'>{errors.name}</div> : null}
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="discount">Máximo de % Desconto<b className='text-danger'>*</b></label>
                    <Field name="discount" type="text" key={'discount'}>
                      {({ field }) => {
                        return <Input
                          className='input-default'
                          value={values.discount}
                          {...field}
                          size="Small"
                          placeholder="Máximo de % Desconto"
                        />
                      }}
                    </Field>
                    {errors.discount && touched.discount ? <div className='error my-2 px-2'>{errors.discount}</div> : null}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2' htmlFor="last_name">Inativo/Ativo</label>
                    <div className='my-2'>
                      <Switch
                        onChange={(value) => setFieldValue('is_active', value)}
                        value={values.is_active}
                        checked={values.is_active}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='my-2' span={24}>
                    <Button
                      className='m-2'
                      disabled={(loadingEdit) ? true : false}
                      loading={(loadingEdit) ? true : false}
                      onClick={() => handleSubmit(values)}
                      type="primary"
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Formik >
        }
      </div >
    </>
  );
};

export default Edit;