import React, { useState, useEffect } from 'react'
import { Col, Row, Input, Button, theme, Pagination } from 'antd';
import { useDispatch } from 'react-redux';
import api from '../../../../services/api';
import { ArrowRightOutlined, ArrowLeftOutlined, CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';

//Dispatchs
import { Creators as RefreshActions } from '../../../../store/reducers/refresh';
import { converterforReal } from '../../../../helpers';

//Styles And Helpers

//Contexts

//Components

//Variables
let delayTimer = null;

const Sugestions = ({ dataSugestions }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer

  //States Local

  const {
    token: { colorError },
  } = theme.useToken();
  const [fieldFilter, setFieldFilter] = useState('');
  const [dataProductsList, setProductsList] = useState([]);
  const [dataSugestionList, setSugestionList] = useState([]);
  const [idsAdds, setIdsAdds] = useState([]);

  const [loader, setLoader] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);

  //Context

  //Cicle of Life
  useEffect(() => {
    fetchListProductsModels(1, '')
    fetchListSugestions()
  }, []);

  //Functions
  async function fetchListProductsModels(page, name) {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/products?page=${page}&serie=${name}&isSugestions=1&qtd=all`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setProductsList(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  async function fetchListSugestions() {
    const token = localStorage.getItem('iguacu:token');
    setLoaderDelete(true)
    return fetch(`${api.defaults.baseURL}/admin/model-suggestions/${dataSugestions?.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setSugestionList(response)
        mountIdsAdds(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoaderDelete(false)
      });
  }

  const mountIdsAdds = (data) => {
    const extractedData = [];
    data?.forEach((item, i) => {
      console.log('item', item)
      extractedData.push(item?.suggestion_product_id)
    })
    setIdsAdds(extractedData)
  }

  const updateDataList = (selectedPage) => {
    // fetchListProductsModels(selectedPage, fieldFilter)
    // dispatch(ProductsActions.getProductsListRequest(payloadList))
  }

  async function fetchDeleteSugestion(id) {
    const token = localStorage.getItem('iguacu:token');
    setLoaderDelete(true)

    return fetch(`${api.defaults.baseURL}/admin/model-suggestions/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        // 'Content-type': 'application/json; charset=UTF-8'
      },
      body: ""
    })
      .then((response) => {
        fetchListSugestions()
        // console.log('response', response)
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
        setLoaderDelete(false)
      });
  }

  const fetchAddSugestion = async (payload) => {
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)

    try {
      try {
        const response = await fetch(`${api.defaults.baseURL}/admin/model-suggestions`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
          },
          body: JSON.stringify(payload)
        });
        await response.json();
        fetchListSugestions();
      } catch (error) {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }
    } finally {
      setLoader(false);
    }
  }

  const onSearch = (e) => {
    // console.log(e.target.value)
    setFieldFilter(e.target.value)
    clearTimeout(delayTimer); // Cancela o temporizador anterior
    delayTimer = setTimeout(() => {
      fetchListProductsModels(1, e.target.value)
    }, 1000); // Aguarda 500ms antes de fazer a chamada para a API
  }

  const tbodyStyle = {
    maxHeight: '300px', // Set your desired maximum height here
    overflow: 'auto', // Enable vertical scrolling if content exceeds the maximum height
  };
  //Render
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col className='p-2' span={12}>
          <Row gutter={[16, 16]}>
            <Col className='p-2' span={6}>
              <label className='ant-form-item-required' htmlFor="code"></label>
              <p>Código:<br /> <b>{dataSugestions?.code}</b></p>
            </Col>
            <Col className='p-2' span={6}>
              <p>Grupo de Máquina:<br /><b> {dataSugestions?.machine_type.name}</b></p>
            </Col>
            <Col className='p-2' span={12}>
              <p>Código Grupo de Máquina:<br /><b>{dataSugestions?.machine_type.code}</b></p>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className=''>
        <Row gutter={[16, 16]}>
          <Col className='content-box-form' span={12}>
            <h3 className='m-2'>Lista de Produtos</h3>
            <label className=' ant-form-item-required' htmlFor="filtrar"></label>
            <Input
              className='input-default'
              value={fieldFilter}
              onChange={(e) => onSearch(e)}
              size="small"
              placeholder="Filtrar"
            />
            <div style={tbodyStyle} >
              {loader &&
                <div className='p-2'>
                  <LoadingOutlined />
                </div>
              }
              <table className="zebra-table" >
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Ativo</th>
                    <th>Cód.Modelo</th>
                    <th>Modelo</th>
                    <th>Basecode</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dataProductsList?.length > 0 &&
                    dataProductsList?.map((itemProduct, i) => (
                      <tr key={i} className={i % 2 === 0 ? 'even' : 'odd'} style={!idsAdds.includes(itemProduct?.id) ? {} : { backgroundColor: '#effff3' }} >
                        <td style={{ width: '5%' }}>
                          {itemProduct?.id}
                        </td>
                        <td style={{ width: '10%' }}>
                          {(itemProduct?.deleted_at === null) ?
                            <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
                            :
                            <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
                          }
                        </td>
                        <td style={{ width: '10%' }}>
                          {itemProduct?.model?.code}
                        </td>
                        <td style={{ width: '10%' }}>
                          <b>{itemProduct?.model?.name}</b>
                        </td>
                        <td style={{ width: '55%' }}>
                          <b>{itemProduct.serie} ({itemProduct.basecode})</b>
                        </td>
                        <td style={{ width: '10%' }}>
                          {
                            (!idsAdds.includes(itemProduct?.id)) &&
                            <Button
                              type="primary"
                              onClick={() => fetchAddSugestion({
                                'product_model_id': dataSugestions?.id,
                                'suggestion_product_id': itemProduct?.id
                              })}
                              icon={<ArrowRightOutlined />}
                              size={'small'}
                              style={{ fontSize: 10, width: 18, height: 18, }}
                              title='Adicionar Sugestão'
                            />
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            {
              dataProductsList?.last_page > 1 &&
              <div className='m-3 text-right'>
                <Pagination
                  pageSize={dataProductsList?.per_page}
                  showSizeChanger={false}
                  showTotal={(total, range) => `${total} itens`}
                  onChange={(e) => updateDataList(e)}
                  current={dataProductsList?.current_page}
                  defaultPageSize={`${dataProductsList?.per_page}`}
                  total={dataProductsList?.total}
                />
              </div>
            }
          </Col>
          <Col className='content-box-form' span={12}>
            <h3 className='m-2 my-4'>Lista de Sugestões Adicionadas</h3>
            <div style={tbodyStyle}>
              {loaderDelete &&
                <div className='p-2'>
                  <LoadingOutlined />
                </div>
              }
              <table className="zebra-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Ativo</th>
                    <th>Cód.Modelo</th>
                    <th>Modelo</th>
                    <th>Basecode</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dataSugestionList?.length > 0 &&
                    dataSugestionList?.sort((a, b) => {
                      const dateA = new Date(a.created_at || a.updated_at);
                      const dateB = new Date(b.created_at || b.updated_at);
                      return dateB - dateA;
                    })
                      .map((itemSugestion, i) => (
                        <tr key={i} className={i % 2 === 0 ? 'even' : 'odd'} >
                          <td style={{ width: '5%' }}>
                            {
                              <Button
                                type="primary"
                                onClick={() => fetchDeleteSugestion(itemSugestion?.id)}
                                icon={<ArrowLeftOutlined />}
                                size={'small'}
                                style={{ fontSize: 10, width: 18, height: 18, background: colorError }}
                                title='Remover Sugestão'
                              />
                            }
                          </td>
                          <td style={{ width: '5%' }}>
                            {itemSugestion?.suggestion_product?.id}
                          </td>
                          <td style={{ width: '5%' }}>
                            {(itemSugestion?.suggestion_product?.deleted_at === null) ?
                              <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
                              :
                              <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
                            }
                          </td>
                          <td style={{ width: '5%' }}>
                            {itemSugestion?.model?.code}
                          </td>
                          <td style={{ width: '30%' }}>
                            {itemSugestion?.model?.name}
                          </td>
                          <td style={{ width: '40%' }}>
                            <b>{itemSugestion?.suggestion_product?.serie}  ({itemSugestion?.suggestion_product?.basecode})</b>
                          </td>
                        </tr>
                      ))
                  }
                  {
                    !dataSugestionList?.length > 0 &&
                    <tr >
                      <td colSpan="4" style={{ width: '100%' }}><b>Não há registros</b></td>
                    </tr>
                  }
                </tbody>
              </table>
              {
                dataProductsList?.last_page > 1 &&
                <div className='m-3 text-right'>
                  <Pagination
                    pageSize={dataProductsList?.per_page}
                    showSizeChanger={false}
                    showTotal={(total, range) => `${total} itens`}
                    onChange={(e) => updateDataList(e)}
                    current={dataProductsList?.current_page}
                    defaultPageSize={`${dataProductsList?.per_page}`}
                    total={dataProductsList?.total}
                  />
                </div>
              }
            </div>
          </Col>
        </Row>
      </div >
    </>
  );
};

export default Sugestions;