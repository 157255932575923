import { combineReducers } from 'redux';
import authentication from './authentication';
import administrators from './administrators';
import quotations from './quotations';
import employees from './employees';
import customers from './customers';
import suppliers from './suppliers';
import discount_group from './discount_group';
import discount_product from './discount_product';
import messages from './messages';
import refresh from './refresh';

import brands from './brands';
import machine_types from './machine_types';
import products_models from './product_models';
import products from './products';

import groups from './groups';
import subitens from './subitens';

import user from './user';
import freight from './freight';

const rootReducer = combineReducers({
  authentication,
  administrators,
  quotations,
  employees,
  customers,
  suppliers,
  discount_group,
  discount_product,
  messages,
  refresh,

  brands,
  machine_types,
  products_models,
  products,

  groups,
  subitens,

  user,
  freight
});

export default rootReducer;
