export const Types = {

  GET_DISCOUNTPRODUCT_LIST_REQUEST: 'GET_DISCOUNTPRODUCT_LIST_REQUEST',
  GET_DISCOUNTPRODUCT_LIST_SUCCESS: 'GET_DISCOUNTPRODUCT_LIST_SUCCESS',
  GET_DISCOUNTPRODUCT_LIST_FAIL: 'GET_DISCOUNTPRODUCT_LIST_FAIL',

  //Set for Update list
  SET_UPDATE_DISCOUNTPRODUCT_LIST: 'SET_UPDATE_DISCOUNTPRODUCT_LIST',

  //Store - Add
  POST_DISCOUNTPRODUCT_ADD_REQUEST: 'POST_DISCOUNTPRODUCT_ADD_REQUEST',
  POST_DISCOUNTPRODUCT_ADD_SUCCESS: 'POST_DISCOUNTPRODUCT_ADD_SUCCESS',
  POST_DISCOUNTPRODUCT_ADD_FAIL: 'POST_DISCOUNTPRODUCT_ADD_FAIL',

  //Update - Edit
  PUT_DISCOUNTPRODUCT_EDIT_REQUEST: 'PUT_DISCOUNTPRODUCT_EDIT_REQUEST',
  PUT_DISCOUNTPRODUCT_EDIT_SUCCESS: 'PUT_DISCOUNTPRODUCT_EDIT_SUCCESS',
  PUT_DISCOUNTPRODUCT_EDIT_FAIL: 'PUT_DISCOUNTPRODUCT_EDIT_FAIL',

  //Delete
  DELETE_DISCOUNTPRODUCT_DEL_REQUEST: 'DELETE_DISCOUNTPRODUCT_DEL_REQUEST',
  DELETE_DISCOUNTPRODUCT_DEL_SUCCESS: 'DELETE_DISCOUNTPRODUCT_DEL_SUCCESS',
  DELETE_DISCOUNTPRODUCT_DEL_FAIL: 'DELETE_DISCOUNTPRODUCT_DEL_FAIL',

  //CLear 
  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
};

const INITIAL_STATE = {

  loadingDiscountProductList: false,
  errorDiscountProductList: false,
  errorDataDiscountProductList: false,
  dataDiscountProductListUpdate: false,

  loadingDiscountProductAdd: false,
  errorDiscountProductAdd: false,
  errorDataDiscountProductAdd: false,

  loadingDiscountProductEdit: false,
  errorDiscountProductEdit: false,
  errorDataDiscountProductEdit: false,

  loadingDiscountProductDelete: false,
  errorDiscountProductDelete: false,
  errorDataDiscountProductDelete: false,

};

export default function discount_group(state = INITIAL_STATE, action) {
  switch (action.type) {

    case Types.GET_DISCOUNTPRODUCT_LIST_REQUEST:
      return {
        ...state,
        loadingDiscountProductList: true,
        errorDiscountProductList: false,
      };
    case Types.GET_DISCOUNTPRODUCT_LIST_SUCCESS:
      return {
        ...state,
        dataDiscountProductList: action.payload,
        loadingDiscountProductList: false,
        errorDiscountProductList: false,
        errorDataDiscountProductList: false
      };
    case Types.GET_DISCOUNTPRODUCT_LIST_FAIL:
      return {
        ...state,
        dataDiscountProductList: false,
        loadingDiscountProductList: false,
        errorDiscountProductList: true,
        errorDataDiscountProductList: action.payload,
      };

    case Types.SET_UPDATE_DISCOUNTPRODUCT_LIST:
      return {
        ...state,
        dataDiscountProductListUpdate: action.payload,
      };

    //Store - Add
    case Types.POST_DISCOUNTPRODUCT_ADD_REQUEST:
      return {
        ...state,
        loadingDiscountProductAdd: true,
        errorDiscountProductAdd: false,
      };
    case Types.POST_DISCOUNTPRODUCT_ADD_SUCCESS:
      return {
        ...state,
        loadingDiscountProductAdd: false,
        errorDiscountProductAdd: false,
        errorDataDiscountProductAdd: false,
      };
    case Types.POST_DISCOUNTPRODUCT_ADD_FAIL:
      return {
        ...state,
        loadingDiscountProductAdd: false,
        errorDiscountProductAdd: true,
        errorDataDiscountProductAdd: action.payload,
      };

    //Update - Edit
    case Types.PUT_DISCOUNTPRODUCT_EDIT_REQUEST:
      return {
        ...state,
        loadingDiscountProductEdit: true,
        errorDiscountProductEdit: false,
      };
    case Types.PUT_DISCOUNTPRODUCT_EDIT_SUCCESS:
      return {
        ...state,
        loadingDiscountProductEdit: false,
        errorDiscountProductEdit: false,
        errorDataDiscountProductEdit: false,
      };
    case Types.PUT_DISCOUNTPRODUCT_EDIT_FAIL:
      return {
        ...state,
        loadingDiscountProductEdit: false,
        errorDiscountProductEdit: true,
        errorDataDiscountProductEdit: action.payload,
      };

    //Delete 
    case Types.DELETE_DISCOUNTPRODUCT_DEL_REQUEST:
      return {
        ...state,
        loadingDiscountProductDelete: true,
        errorDiscountProductDelete: false,
      };
    case Types.DELETE_DISCOUNTPRODUCT_DEL_SUCCESS:
      return {
        ...state,
        loadingDiscountProductDelete: false,
        errorDiscountProductDelete: false,
        errorDataDiscountProductDelete: false,
      };
    case Types.DELETE_DISCOUNTPRODUCT_DEL_FAIL:
      return {
        ...state,
        loadingDiscountProductDelete: false,
        errorDiscountProductDelete: true,
        errorDataDiscountProductDelete: action.payload,
      };

    //Clear
    case Types.CLEAR_ALL_REQUEST:
      return {
        ...state,

        loadingDiscountProductList: false,
        errorDiscountProductList: false,
        errorDataDiscountProductList: false,
        dataDiscountProductListUpdate: false,

        loadingDiscountProductAdd: false,
        errorDiscountProductAdd: false,
        errorDataDiscountProductAdd: false,

        loadingDiscountProductEdit: false,
        errorDiscountProductEdit: false,
        errorDataDiscountProductEdit: false,

        loadingDiscountProductDelete: false,
        errorDiscountProductDelete: false,
        errorDataDiscountProductDelete: false,

      };

    default:
      return state;
  }
}

export const Creators = {

  // DICOUNT PRODUCT ----------
  //Get - List
  getDiscountProductListRequest: (payload) => ({
    type: Types.GET_DISCOUNTPRODUCT_LIST_REQUEST,
    payload,
  }),

  getDiscountProductListRequestSuccess: (payload) => ({
    type: Types.GET_DISCOUNTPRODUCT_LIST_SUCCESS,
    payload,
  }),

  getDiscountProductListRequestFail: (payload) => ({
    type: Types.GET_DISCOUNTPRODUCT_LIST_FAIL,
    payload,
  }),

  setUpdateDiscountProduct: (payload) => ({
    type: Types.SET_UPDATE_DISCOUNTPRODUCT_LIST,
    payload,
  }),

  //Store - Add 
  postDiscountProductAddRequest: (payload) => ({
    type: Types.POST_DISCOUNTPRODUCT_ADD_REQUEST,
    payload,
  }),

  postDiscountProductAddRequestSuccess: () => ({
    type: Types.POST_DISCOUNTPRODUCT_ADD_SUCCESS,
  }),

  postDiscountProductAddRequestFail: (payload) => ({
    type: Types.POST_DISCOUNTPRODUCT_ADD_FAIL,
    payload,
  }),

  //Update - Edit 
  putDiscountProductEditRequest: (payload) => ({
    type: Types.PUT_DISCOUNTPRODUCT_EDIT_REQUEST,
    payload,
  }),

  putDiscountProductEditRequestSuccess: () => ({
    type: Types.PUT_DISCOUNTPRODUCT_EDIT_SUCCESS,
  }),

  putDiscountProductEditRequestFail: (payload) => ({
    type: Types.PUT_DISCOUNTPRODUCT_EDIT_FAIL,
    payload,
  }),

  //Delete 
  deleteDiscountProductDelRequest: (payload) => ({
    type: Types.DELETE_DISCOUNTPRODUCT_DEL_REQUEST,
    payload,
  }),

  deleteDiscountProductDelRequestSuccess: () => ({
    type: Types.DELETE_DISCOUNTPRODUCT_DEL_SUCCESS,
  }),

  deleteDiscountProductDelRequestFail: (payload) => ({
    type: Types.DELETE_DISCOUNTPRODUCT_DEL_FAIL,
    payload,
  }),

  //Clear
  clearAllRequest: () => ({
    type: Types.CLEAR_ALL_REQUEST
  }),
};
