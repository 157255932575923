import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as MachineTypesActions,
  Types as MachineTypes,
} from '../../reducers/machine_types';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';


//MACHINE TYPES
function* listMachineTypes({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/machine_types?page=${payload?.page}&name=${payload?.name}&code=${payload?.code}&brand_id=${payload?.brand_id}`
    );
    
    yield put(MachineTypesActions.setUpdateMachineTypes(false));
    yield put(MachineTypesActions.getMachineTypesListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MachineTypesActions.getMachineTypesListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addMachineTypes({ payload }) {
  try {
    yield call(api.post, '/admin/machine_types', payload);
    yield put(MachineTypesActions.setUpdateMachineTypes(true));
    yield put(MachineTypesActions.postMachineTypesAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MachineTypesActions.postMachineTypesAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editMachineTypes({ payload }) {
  try {
    yield call(api.put, `/admin/machine_types/${payload?.id}`, payload);
    yield put(MachineTypesActions.setUpdateMachineTypes(true));
    yield put(MachineTypesActions.putMachineTypesEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MachineTypesActions.putMachineTypesEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteMachineTypes({ payload }) {
  try {
    yield call(api.delete, `/admin/machine_types/${payload?.id}`);
    yield put(MachineTypesActions.setUpdateMachineTypes(true));
    yield put(MachineTypesActions.postMachineTypesAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {

    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MachineTypesActions.deleteMachineTypesDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* uploadImageMachineTypes({ payload }) {
  try {
    yield call(api.post, `/admin/machine_types/${payload?.id}/photo`, payload?.formData);
    yield put(MachineTypesActions.setUpdateMachineTypes(true));
    yield put(MachineTypesActions.postMachineTypesUploadImageRequestSuccess());
    toast.success("Imagem salva com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MachineTypesActions.postMachineTypesUploadImageRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteImageMachineTypes({ payload }) {
  try {
    yield call(api.delete, `/admin/machine_types/${payload?.id}/photo`, payload?.formData);
    yield put(MachineTypesActions.setUpdateMachineTypes(true));
    yield put(MachineTypesActions.deleteMachineTypesDelImageRequestSuccess());
    toast.success("Imagem deletada com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(MachineTypesActions.deleteMachineTypesDelImageRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

//MACHINE TYPES
export function* listMachineTypesWatcher() {
  yield takeLatest(MachineTypes.GET_MACHINETYPES_LIST_REQUEST, listMachineTypes);
}

export function* addMachineTypesWatcher() {
  yield takeLatest(MachineTypes.POST_MACHINETYPES_ADD_REQUEST, addMachineTypes);
}

export function* editMachineTypesWatcher() {
  yield takeLatest(MachineTypes.PUT_MACHINETYPES_EDIT_REQUEST, editMachineTypes);
}

export function* deleteMachineTypesWatcher() {
  yield takeLatest(MachineTypes.DELETE_MACHINETYPES_DEL_REQUEST, deleteMachineTypes);
}

export function* uploadImageMachineTypesWatcher() {
  yield takeLatest(MachineTypes.POST_MACHINETYPES_UPLOADIMAGE_REQUEST, uploadImageMachineTypes);
}

export function* deleteImageMachineTypesWatcher() {
  yield takeLatest(MachineTypes.DELETE_MACHINETYPES_DELIMAGE_REQUEST, deleteImageMachineTypes);
}

export default function* rootSaga() {
  yield all([
    
    fork(listMachineTypesWatcher),
    fork(addMachineTypesWatcher),
    fork(editMachineTypesWatcher),
    fork(deleteMachineTypesWatcher),
    fork(uploadImageMachineTypesWatcher),
    fork(deleteImageMachineTypesWatcher),

  ]);
}
