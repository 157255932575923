import { configureStore } from "@reduxjs/toolkit";
import combineReducers from './reducers';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
  blacklist: [
    'administrators',

    'brands',
    'machine_types',
    'product_models',
    'products',

    'groups',
    'subitens',
    
    'refresh',
    'customers',
    'employees',
    'messages',
    'quotations',
    'suppliers',
    'user'
  ],
}

let sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, combineReducers)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, sagaMiddleware]
})

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store)

export { store, persistor };