import { all, call, takeLatest, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  Creators as DiscountGroupActions,
  Types as DiscountGroupTypes,
} from '../../reducers/discount_group';

import {
  Creators as RefreshActions,
} from '../../reducers/refresh';

function* listDiscountGroup({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/admin/discount_group?page=${payload?.page}&discount=${payload?.discount}&name=${payload?.name}`
    );
    yield put(DiscountGroupActions.setUpdateDiscountGroup(false));
    yield put(DiscountGroupActions.getDiscountGroupListRequestSuccess(data));
    // toast.info("Success", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(DiscountGroupActions.getDiscountGroupListRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* addDiscountGroup({ payload }) {
  try {
    yield call(api.post, '/admin/discount_group', payload);
    yield put(DiscountGroupActions.setUpdateDiscountGroup(true));
    yield put(DiscountGroupActions.postDiscountGroupAddRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(DiscountGroupActions.postDiscountGroupAddRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* editDiscountGroup({ payload }) {
  console.log('payload', payload)
  try {
    yield call(api.put, `/admin/discount_group/${payload?.id}`, payload);
    // console.log('data edit', data)
    yield put(DiscountGroupActions.setUpdateDiscountGroup(true));
    yield put(DiscountGroupActions.putDiscountGroupEditRequestSuccess());
    toast.success("Salvo com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(DiscountGroupActions.putDiscountGroupEditRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

function* deleteDiscountGroup({ payload }) {
  try {
    yield call(api.delete, `/admin/discount_group/${payload?.id}`);
    // console.log('data store', data)
    yield put(DiscountGroupActions.setUpdateDiscountGroup(true));
    yield put(DiscountGroupActions.postDiscountGroupAddRequestSuccess());
    toast.success("Deletado com sucesso", {
      position: toast.POSITION.BOTTOM_CENTER
    });

  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(RefreshActions.setAlertModalExperedLogin(true));
    } else {
      yield put(DiscountGroupActions.deleteDiscountGroupDelRequestFail(error?.response?.data));
      toast.info(`${JSON.stringify(error?.response?.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }
}

export function* listDiscountGroupWatcher() {
  yield takeLatest(DiscountGroupTypes.GET_DISCOUNTGROUP_LIST_REQUEST, listDiscountGroup);
}

export function* addDiscountGroupWatcher() {
  yield takeLatest(DiscountGroupTypes.POST_DISCOUNTGROUP_ADD_REQUEST, addDiscountGroup);
}

export function* editDiscountGroupWatcher() {
  yield takeLatest(DiscountGroupTypes.PUT_DISCOUNTGROUP_EDIT_REQUEST, editDiscountGroup);
}

export function* deleteDiscountGroupWatcher() {
  yield takeLatest(DiscountGroupTypes.DELETE_DISCOUNTGROUP_DEL_REQUEST, deleteDiscountGroup);
}

export default function* rootSaga() {
  yield all([
    fork(listDiscountGroupWatcher),
    fork(addDiscountGroupWatcher),
    fork(editDiscountGroupWatcher),
    fork(deleteDiscountGroupWatcher),
  ]);
}
