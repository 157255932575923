import React, { useEffect } from 'react'
import { Col, Row, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import * as yup from 'yup';

//Dispatchs
import { Creators as AdministratorsActions } from '../../../store/reducers/administrators';

//Styles And Helpers

//Contexts

//Components

//Variables
const ShowSchema = yup.object().shape({
  first_name: yup.string()
    .min(2, 'A nome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  last_name: yup.string()
    .min(2, 'A sobrenome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  email: yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
});
// { first_name: '', last_name: '', email: '', password: '', is_active: '', avatar: '' }

const Show = ({ dataShow }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer

  //States Local

  //Context

  //Cicle of Life
  useEffect(() => {

  }, []);

  //Functions
  const submitEdit = (values) => {
    const payload = values
    dispatch(AdministratorsActions.putAdministratorsEditRequest(payload))
  }

  //Render
  return (
    <>
      <div className='p-3 content-box-form'>
        {/* <p>{JSON.stringify(dataShow)}</p> */}
        <Formik
          initialValues={{
            type: dataShow?.type,
            social_reason_or_name: dataShow?.social_reason_or_name,
            fantasy_name: dataShow?.fantasy_name,
            doc: dataShow?.doc,
            email: dataShow?.email,
            phoneNumber: dataShow?.phone_number,
            addresses: dataShow?.addresses,
          }}
          validationSchema={ShowSchema}
          onSubmit={(values) => (
            submitEdit(values)
          )
          }
        >
          {({ setFieldValue, handleSubmit, values, errors, touched }) => (
            <>
              <h3 className='my-3'>Pessoa</h3>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2 ant-form-item-required' htmlFor="social_reason_or_name">Tipo de Pessoa</label>
                  <Input
                    className='input-default'
                    value={(values.type === "J") ? 'JURÍDICA' : 'FÍSICA'}
                    size="Small"
                    placeholder="Tipo de Pessoa"
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2 ant-form-item-required' htmlFor="social_reason_or_name">Razão Social / Nome</label>
                  <Input
                    className='input-default'
                    value={values.social_reason_or_name}
                    size="Small"
                    placeholder="Razão Social / Nome"
                  />
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2 ant-form-item-required' htmlFor="fantasy_name">Nome Fantasia</label>
                  <Input
                    className='input-default'
                    value={values.fantasy_name}
                    size="Small"
                    placeholder="Nome Fantasia"
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2 ant-form-item-required' htmlFor="doc">CPF/CNPJ</label>
                  <Input
                    className='input-default'
                    value={values.doc}
                    size="Small"
                    placeholder="CPF/CNPJ"
                  />
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2 ant-form-item-required' htmlFor="email">Email</label>
                  <Input
                    className='input-default'
                    value={values.email}
                    size="Small"
                    placeholder="Email"
                  />
                </Col>
              </Row>

              <h3 className='my-3'>Telefones</h3>
              {
                (values?.phoneNumber) && values?.phoneNumber.length > 0 ?
                  (
                    values?.phoneNumber.map((item, i) => (
                      <div key={i}>
                        <Row gutter={[16, 16]}>
                          <Col className='my-2' span={12}>
                            <label className='px-2 ant-form-item-required' htmlFor="ddd">DDD</label>
                            <Input
                              className='input-default'
                              value={item.ddd}
                              size="Small"
                              placeholder="DDD"
                            />
                          </Col>
                          <Col className='my-2' span={12}>
                            <label className='px-2 ant-form-item-required' htmlFor="numero">Número</label>
                            <Input
                              className='input-default'
                              value={item.number}
                              size="Small"
                              placeholder="NÚMERO"
                            />
                          </Col>
                        </Row>
                      </div>
                    ))
                  )
                  :
                  (<p className='px-3'>Não há registro</p>)
              }

              <h3 className='my-3'>Endereços</h3>
              {
                (values?.addresses) && values?.addresses.length > 0 ?
                  (
                    values?.addresses.map((item, i) => (
                      <div key={i}>
                        <Row gutter={[16, 16]}>
                          <Col className='my-2' span={12}>
                            <label className='px-2 ant-form-item-required' htmlFor="zipcode">CEP</label>
                            <Input
                              className='input-default'
                              value={item.zipcode}
                              size="Small"
                              placeholder="CEP"
                            />
                          </Col>
                          <Col className='my-2' span={12}>
                            <label className='px-2 ant-form-item-required' htmlFor="street">Rua</label>
                            <Input
                              className='input-default'
                              value={item.street}
                              size="Small"
                              placeholder="Rua"
                            />
                          </Col>

                        </Row>
                        <Row gutter={[16, 16]}>
                          <Col className='my-2' span={8}>
                            <label className='px-2 ant-form-item-required' htmlFor="neighborhood">Bairro</label>
                            <Input
                              className='input-default'
                              value={item.neighborhood}
                              size="Small"
                              placeholder="Neighborhood"
                            />
                          </Col>
                          <Col className='my-2' span={8}>
                            <label className='px-2 ant-form-item-required' htmlFor="numero">Número</label>
                            <Input
                              className='input-default'
                              value={item.number}
                              size="Small"
                              placeholder="NÚMERO"
                            />
                          </Col>
                          <Col className='my-2' span={8}>
                            <label className='px-2 ant-form-item-required' htmlFor="address_details">Complemento</label>
                            <Input
                              className='input-default'
                              value={item.address_details}
                              size="Small"
                              placeholder="Complemento"
                            />
                          </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                          <Col className='my-2' span={8}>
                            <label className='px-2 ant-form-item-required' htmlFor="city">Cidade</label>
                            <Input
                              className='input-default'
                              value={item.city}
                              size="Small"
                              placeholder="Cidade"
                            />
                          </Col>
                          <Col className='my-2' span={8}>
                            <label className='px-2 ant-form-item-required' htmlFor="state">Estado</label>
                            <Input
                              className='input-default'
                              value={item.state}
                              size="Small"
                              placeholder="Neighborhood"
                            />
                          </Col>
                          <Col className='my-2' span={8}>
                            <label className='px-2 ant-form-item-required' htmlFor="country">Pais</label>
                            <Input
                              className='input-default'
                              value={item?.country?.name}
                              size="Small"
                              placeholder="Pais"
                            />
                          </Col>
                        </Row>
                      </div>
                    ))
                  )
                  :
                  (<p className='px-3'>Não há registro</p>)
              }


            </>
          )}
        </Formik >
      </div >
    </>
  );
};

export default Show;