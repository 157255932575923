import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Input, Upload, theme, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { CheckOutlined, YoutubeFilled, PlusOutlined, CloseOutlined, CloseCircleFilled, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

//Dispatchs
import { Creators as RefreshActions } from '../../../../store/reducers/refresh';
import { Creators as ProductsActions } from '../../../../store/reducers/products';
import { converterforReal } from '../../../../helpers';

//Styles And Helpers

//Contexts

//Components

//Variables
const ShowSchema = yup.object().shape({
  minimum_coefficient: yup.string()
    .typeError('O coeficiênte mínimo deve ser um valor numérico, Exemplo: 0.45 .')
    .test('validar-ponto-flutuante', 'O coeficiênte mínimo deve ser um valor numérico, Exemplo: 0.45 .', value => {
      const regex = /^\d+(\.\d{1,2})?$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('O coeficiênte mínimo é obrigatório'),
  tax_discount: yup.string()
    .typeError('O desconto tributário deve ser um número, Exemplo: 17.60 . ')
    .test('validar-ponto-flutuante', 'O desconto tributário deve ser um número, Exemplo: 17.60 .', value => {
      const regex = /^\d+(\.\d{1,2})?$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('O desconto tributário é obrigatório'),
  consession: yup.string()
    .typeError('A concessão deve ser um número, Exemplo: 17.60 .')
    .test('validar-ponto-flutuante', 'A concessão deve ser um número, Exemplo: 17.60 .', value => {
      const regex = /^\d+(\.\d{1,2})?$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('A concessão é obrigatório'),
  special: yup.string()
    .typeError('O especial deve ser um número, Exemplo: 17.60 .')
    .test('validar-ponto-flutuante', 'O especial deve ser um número, Exemplo: 17.60 .', value => {
      const regex = /^\d+(\.\d{1,2})?$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('O especial é obrigatório'),
  condition: yup.string()
    .typeError('A condição deve ser um número')
    .test('validar-ponto-flutuante', 'A condição deve ser um número, Exemplo: 17.60 .', value => {
      const regex = /^\d+(\.\d{1,2})?$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('A condição é obrigatório'),

  price: yup.string()
    .typeError('O preço base deve ser um número, Exemplo: 324117.60 .')
    .test('validar-ponto-flutuante', 'O preço base deve ser um número, Exemplo: 324117.60 .', value => {
      const regex = /^\d+(\.\d{1,2})?$/; // Aceita de 0.00 a 100.00 com duas casas decimais
      return regex.test(value);
    })
    .required('O preço é obrigatório'),

});

const { TextArea } = Input;

const Show = ({ data }) => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { dataProductsListUpdate, loadingEdit } = useSelector((state) => (state.products))

  //States Local
  const {
    token: { colorError },
  } = theme.useToken();

  const [dataGroupsShow, setDataGroupsShow] = useState(null);
  const [textLinkExternal, setTextLinkExternal] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderLinkExternalVideo, setLinkExternalVideo] = useState(false);

  //Context

  //Cicle of Life
  useEffect(() => {
    fetchProducts(data?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataProductsListUpdate) {
      fetchProducts(data?.id)
    }
  }, [dataProductsListUpdate]);

  useEffect(() => {
    mountPhotos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGroupsShow])

  //Functions
  async function fetchProducts(id) {
    setDataGroupsShow(null)
    const token = localStorage.getItem('iguacu:token');
    setLoader(true)
    return fetch(`${api.defaults.baseURL}/admin/products/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setDataGroupsShow(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLoader(false)
      });
  }

  const [fileListPhotos, setFileListPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);
  const mountPhotos = () => {
    let photos = []

    if (dataGroupsShow?.photos && dataGroupsShow?.photos?.length > 0) {
      (dataGroupsShow?.photos && dataGroupsShow?.photos.map((item, i) => {
        photos.push(
          {
            uid: item?.id,
            name: 'Imagem ' + item?.id,
            status: 'done',
            url: item?.path,
            thumbUrl: item?.path,
          }
        )
        return true
      }))
    }
    setFileListPhotos(photos)
    return true
  }

  const handleUpload = () => {
    const formData = new FormData();
    let dataTrat = fileListPhotos;

    if (dataGroupsShow?.photos?.length > 0) {
      for (let index = 0; index < dataGroupsShow?.photos.length - 1; index++) {
        dataTrat.splice(index, 1)
      }
    }

    dataTrat.forEach((file) => {
      formData.append('photos[]', file);
    })
    setUploading(true);
    const id = dataGroupsShow?.id
    const token = localStorage.getItem('iguacu:token');
    // You can use any AJAX library you like
    fetch(api.defaults.baseURL + `/admin/products/${id}/files`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(() => {
        fetchProducts(id)
        toast.success("Enviado com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const removePhoto = (id) => {
    const sub_item_group_id = dataGroupsShow?.id

    const token = localStorage.getItem('iguacu:token');
    // You can use any AJAX library you like
    fetch(api.defaults.baseURL + `/admin/products/${sub_item_group_id}/photos/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(() => {
        fetchProducts(dataGroupsShow?.id)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const propsUpload = {
    multiple: true,
    onRemove: async (file) => {
      const index = fileListPhotos.indexOf(file);
      removePhoto(fileListPhotos[index]?.uid)
      const newFileList = fileListPhotos.slice();
      newFileList.splice(index, 1);
      setFileListPhotos(newFileList);
    },
    beforeUpload: async (file) => {

      const acceptedFormats = ['image/png', 'image/jpeg'];
      const isFormatValid = acceptedFormats.includes(file.type);
      const isSizeValid = file.size / 1024 / 1024 <= 2;

      if (!isFormatValid) {
        toast.error("Por favor, envie apenas arquivos PNG ou JPG!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      if (!isSizeValid) {
        toast.error("O tamanho máximo do arquivo é de 2MB!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false;
      }

      setFileListPhotos(prevFileList => [...prevFileList, file]);
      return false;
    },
    fileList: fileListPhotos,
  };

  const submitEdit = (values) => {

    const payload = values
    dispatch(ProductsActions.putProductsEditRequest(payload))
  }

  const onChangeLinkExternal = (e) => {
    setTextLinkExternal(e.target.value)
  }

  async function fetchAddVideoExternal(link_external, id) {
    const token = localStorage.getItem('iguacu:token');
    setLinkExternalVideo(true)
    return fetch(`${api.defaults.baseURL}/admin/products/${id}/video-external`, {
      method: 'POST',
      body: JSON.stringify({ 'link_external': link_external }),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        fetchProducts(dataGroupsShow?.id)
        setTextLinkExternal('')
        toast.success("Link do vídeo salvo com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        if (response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      })
      .finally(() => {
        setLinkExternalVideo(false)
      });
  }

  async function fetchDeleteExternalLink(id) {
    const token = localStorage.getItem('iguacu:token');
    const product_id = dataGroupsShow?.id
    // setLoader(true)

    return fetch(`${api.defaults.baseURL}/admin/products/${product_id}/videos/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: ""
    })
      .then((response) => {
        fetchProducts(dataGroupsShow?.id)
        // console.log('response', response)
      }).catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(RefreshActions.setAlertModalExperedLogin(true));
        }
      }).finally(() => {
        // setLoader(false)
      });
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const sendVideoLinkExternal = (linkExternal, id) => {
    // Expressão regular para verificar se a string é um link
    var urlPattern = /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    // Testar se a string corresponde ao padrão de URL

    if (urlPattern.test(linkExternal)) {
      fetchAddVideoExternal(linkExternal, id)
    } else {
      toast.error("Link do vídeo inválido!", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  //Render
  return (
    <>
      {
        loader && <LoadingOutlined />
      }
      <div className='p-3 content-box-form'>
        {
          dataGroupsShow &&
          <Formik
            initialValues={{
              id: dataGroupsShow?.id,
              model_id: dataGroupsShow?.model_id,
              model: dataGroupsShow?.model?.code,
              model_freight: dataGroupsShow?.model?.freight,
              serie: dataGroupsShow?.serie,
              basecode: dataGroupsShow?.basecode,
              minimum_coefficient: dataGroupsShow?.minimum_coefficient,
              recomended_price: dataGroupsShow?.recomended_price,
              tax_discount: dataGroupsShow?.tax_discount,
              condition: dataGroupsShow?.condition,
              consession: dataGroupsShow?.consession,
              special: dataGroupsShow?.special,
              code: dataGroupsShow?.code,
              price: dataGroupsShow?.price,
              description: dataGroupsShow?.description,
              deleted_at: dataGroupsShow?.deleted_at,
            }}
            validationSchema={ShowSchema}
            onSubmit={(values) => (
              submitEdit(values)
            )
            }
          >
            {({ setFieldValue, handleSubmit, values, errors, touched }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={8}>
                    <label className='px-2 ant-form-item-required' htmlFor="model">Modelo</label>
                    <Input
                      className='input-default'
                      value={values.model}
                      size="Small"
                      placeholder="Modelo"
                    />
                  </Col>
                  <Col className='my-2' span={8}>
                    <label className='px-2 ant-form-item-required' htmlFor="serie">Série</label>
                    <Input
                      className='input-default'
                      value={values.serie}
                      size="Small"
                      placeholder="Série"
                    />
                  </Col>
                  <Col className='my-2' span={8}>
                    <label className='px-2 ant-form-item-required' htmlFor="freight">Code Base</label>
                    <Input
                      className='input-default'
                      value={values.basecode}
                      size="Small"
                      placeholder="Frete"
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="minimum_coefficient">Coeficiênte minímo <b className='text-danger'>* valor numérico</b></label>
                    <Input
                      className='my-2'
                      value={values.minimum_coefficient}
                      placeholder="Coeficiênte minímo"
                    />
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="tax_discount">Desconto Tributário <b className='text-danger'>* valor em %</b></label>
                    <Input
                      className='my-2'
                      value={values.tax_discount}
                      placeholder="Desconto Tributário"
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="consession">Concessão <b className='text-danger'>* valor em %</b></label>
                    <Input
                      className='my-2'
                      value={values.consession}
                      placeholder="Concessão"
                    />
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="special">Especial <b className='text-danger'>* valor em %</b></label>
                    <Input
                      className='my-2'
                      value={values.special}
                      placeholder="Especial"
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="condition">Condição<b className='text-danger'>* valor em %</b></label>
                    <Input
                      className='my-2 '
                      value={values.condition}
                      placeholder="Concessão"
                    />
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="code">Código</label>
                    <Input
                      className='input-default'
                      value={values.code}
                      size="Small"
                      placeholder="Código"
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="recomended_price">Preço Custo</label>
                    <Input
                      className='input-default'
                      value={converterforReal(values.recomended_price)}
                      size="Small"
                      placeholder="Preço Sugerido"
                    />
                  </Col>
                  <Col className='my-2' span={12}>
                    <label className='px-2 ant-form-item-required' htmlFor="price">Preço Base<b className='text-danger'>*</b></label>
                    <Input
                      className='my-2'
                      value={values.price}
                      placeholder="Frete"
                    />
                  </Col>
                </Row>
                {
                  (dataGroupsShow?.seller_price && values.model_freight) &&
                  <Row gutter={[16, 16]}>
                    <Col className='my-2' span={12}>
                      <label className='px-2 ant-form-item-required' htmlFor="recomended_price">Preço Venda<b className='text-danger'> valor com o frete da máquina</b></label>
                      <Input
                        className='input-default'
                        value={converterforReal(dataGroupsShow?.seller_price)}
                        size="Small"
                        placeholder="Preço Sugerido"
                      />
                    </Col>
                    <Col className='my-2' span={12}>
                      <label className='px-2 ant-form-item-required' htmlFor="model_freight">Frete<b className='text-danger'> config. no Protheus</b></label>
                      <Input
                        className='input-default'
                        value={values.model_freight}
                        size="Small"
                        placeholder="Frete"
                      />
                    </Col>
                  </Row>
                }
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <label className='px-2' htmlFor="description">Descrição</label>
                    <Field name="description" type="text" key={'description'}>
                      {({ field }) => {
                        return <TextArea
                          className='my-2'
                          {...field}
                          value={values.description}
                          placeholder="Descrição"
                          rows={4}
                        />
                      }}
                    </Field>
                    {errors.description && touched.description ? <div className='error my-2 px-2'>{errors.description}</div> : null}
                  </Col>
                  <Col span={12}>
                    <label className='px-2' htmlFor="description">Ativo</label>
                    <div className='p-2'>
                      {(values?.deleted_at === null) ?
                        <CheckOutlined style={{ fontSize: 18, color: "#52c41a" }} />
                        :
                        <CloseOutlined style={{ fontSize: 18, color: "#dc3545" }} />
                      }
                    </div>
                  </Col>
                </Row>
                {/* <Row gutter={[16, 16]}>
                  <Col className='my-2' span={24}>
                    <Button
                      className='m-2'
                      disabled={(loadingEdit) ? true : false}
                      loading={(loadingEdit) ? true : false}
                      onClick={() => handleSubmit(values)}
                      type="primary"
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row> */}
                <Row gutter={[16, 16]}>
                  <Col className='my-2' span={12}>
                    <h3 className='my-3'>Fotos</h3>
                    <div>
                      <div >
                        <Upload listType="picture-card" {...propsUpload}>
                          <Button icon={<UploadOutlined />}></Button>
                        </Upload>
                        <Button
                          type="primary"
                          size={'small'}
                          onClick={handleUpload}
                          disabled={fileListPhotos?.length === 0}
                          loading={uploading}
                          style={{
                            margin: 3,
                          }}
                        >
                          {uploading ? 'Uploading' : 'Enviar Fotos'}
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col className='my-2' span={12}>
                    <h3 className='my-3'>Vídeos</h3>
                    <div>
                      <Row gutter={[16, 16]}>
                        <Col className='' span={24}>
                          <div className='' style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Input
                              size='small'
                              className='my-1'
                              placeholder="Link do Vídeo"
                              value={textLinkExternal}
                              onChange={onChangeLinkExternal}
                            />
                            <Button
                              className='m-2'
                              disabled={(loaderLinkExternalVideo) ? true : false}
                              loading={(loaderLinkExternalVideo) ? true : false}
                              onClick={() => sendVideoLinkExternal(textLinkExternal, values.id)}
                              icon={<PlusOutlined />}
                              size='small'
                              type="primary"
                            />
                          </div>
                        </Col>
                        <Col className='' span={24}>
                          <div style={{ display: 'flex' }}>
                            {
                              dataGroupsShow?.videos?.length > 0 && dataGroupsShow?.videos.map((video, i) => {
                                return (
                                  <div key={i} style={{ position: 'relative' }}>
                                    <Button
                                      className='m-2'
                                      title={`Abrir V${video?.id}`}
                                      onClick={() => openInNewTab(video?.link_external)}
                                      icon={<YoutubeFilled />}
                                      size='large'
                                      style={{ width: 50, height: 40 }}
                                      type="primary"
                                    />
                                    <Popconfirm
                                      title="Tem certeza que deseja excluir ?"
                                      onConfirm={() => fetchDeleteExternalLink(video?.id)}
                                      okText="Sim"
                                      cancelText="Não"
                                    >
                                      <Button
                                        type="primary"
                                        disabled={(loaderLinkExternalVideo) ? true : false}
                                        loading={(loaderLinkExternalVideo) ? true : false}
                                        icon={<CloseCircleFilled />}
                                        size={'small'}
                                        style={{ position: 'absolute', top: 0, right: 0, fontSize: 8, background: colorError, width: 18, height: 18 }}
                                        title='Excluir'
                                      />
                                    </Popconfirm>
                                    <p style={{ width: '100%', textAlign: 'center', color: '#000', fontWeight: 'bold' }}>V{video?.id}</p>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Formik >
        }
      </div >
    </>
  );
};

export default Show;