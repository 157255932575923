import React, { useState } from 'react'
import { Col, Row, Button, Switch, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { CloseCircleFilled } from '@ant-design/icons';
import * as yup from 'yup';

//Dispatchs
import { Creators as AdministratorsActions } from '../../../store/reducers/administrators';

//Styles And Helpers

//Contexts

//Components

//Variables
const AddSchema = yup.object().shape({
  first_name: yup.string()
    .min(2, 'A nome deve ter pelo menos 2 caracteres')
    .required('A nome é obrigatória'),
  last_name: yup.string()
    .min(2, 'A sobrenome deve ter pelo menos 2 caracteres')
    .required('A sobrenome é obrigatória'),
  email: yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
  password: yup.string()
    .min(5, 'A senha deve ter pelo menos 5 caracteres')
    .required('A senha é obrigatória'),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'A confirmação de senha deve ser igual à senha')
    .min(5, 'A confirmação senha deve ter pelo menos 5 caracteres')
    .required('A confirmação senha é obrigatória'),
});
// { first_name: '', last_name: '', email: '', password: '', is_active: '', avatar: '' }

const Add = () => {

  //Dispatchs
  const dispatch = useDispatch();

  //States Reducer
  const { loadingAdd } = useSelector((state) => (state.administrators))

  //States Local
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  //Context

  //Cicle of Life

  //Functions
  const submitAdd = (values) => {

    var formData = new FormData();
    formData.append('first_name', values.first_name);
    formData.append('last_name', values.last_name);
    formData.append('email', values.email);
    formData.append('password', values.password);
    formData.append('password_confirmation', values.password_confirmation);
    formData.append('is_active', (values.is_active) ? 1 : 0);

    if (selectedFile) {
      formData.append('avatar', selectedFile)
    }

    const payload = formData
    dispatch(AdministratorsActions.postAdministratorsAddRequest(payload))
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // console.log('selected file', file.name)

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };


  //Render
  return (
    <>
      <div className='p-2 content-box-form'>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            is_active: true,
            avatar: null
          }}
          validationSchema={AddSchema}
          onSubmit={(values) => (
            submitAdd(values)
          )
          }
        >
          {({ setFieldValue, handleSubmit, values, errors, touched }) => (
            <>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2 ant-form-item-required' htmlFor="first_name">Nome<b className='text-danger'>*</b></label>
                  <Field name="first_name" type="text" key={'first_name'}>
                    {({ field }) => {
                      return <Input
                        className='input-default'
                        value={values.first_name}
                        {...field}
                        size="large"
                        placeholder="Nome"

                      />
                    }}
                  </Field>
                  {errors.first_name && touched.first_name ? <div className='error my-2 px-2'>{errors.first_name}</div> : null}
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="last_name">Sobrenome<b className='text-danger'>*</b></label>
                  <Field name="last_name" type="text" key={'last_name'}>
                    {({ field }) => {
                      return <Input
                        className='input-default'
                        value={values.last_name}
                        {...field}
                        size="large"
                        placeholder="Sobrenome"
                      />
                    }}
                  </Field>
                  {errors.last_name && touched.last_name ? <div className='error my-2 px-2'>{errors.last_name}</div> : null}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="email">Email<b className='text-danger'>*</b></label>
                  <Field name="email" type="text" key={'email'}>
                    {({ field }) => {
                      return <Input
                        className='input-default'
                        value={values.email}
                        {...field}
                        type='email'
                        size="large"
                        placeholder="Email"
                      />
                    }}
                  </Field>
                  {errors.email && touched.email ? <div className='error my-2 px-2'>{errors.email}</div> : null}
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="last_name">Inativo/Ativo</label>
                  <div className='my-2'>
                    <Switch
                      onChange={(value) => setFieldValue('is_active', value)}
                      value={values.is_active}
                      checked={values.is_active}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="password">Senha<b className='text-danger'>*</b></label>
                  <Field name="password" type="password" key={'password'}>
                    {({ field }) => {
                      return <Input
                        className='input-default'
                        type='password'
                        value={values.password}
                        {...field}
                        size="large"
                        placeholder="Senha"
                      />
                    }}
                  </Field>
                  {errors.password && touched.password ? <div className='error my-2 px-2'>{errors.password}</div> : null}
                </Col>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="password_confirmation">Confirmar Senha<b className='text-danger'>*</b></label>
                  <Field name="password_confirmation" type="password_confirmation" key={'password_confirmation'}>
                    {({ field }) => {
                      return <Input
                        className='input-default'
                        type='password'
                        value={values.password_confirmation}
                        {...field}
                        size="large"
                        placeholder="Confirmar Senha"
                      />
                    }}
                  </Field>
                  {errors.password_confirmation && touched.password_confirmation ? <div className='error my-2 px-2'>{errors.password_confirmation}</div> : null}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className='my-2' span={12}>
                  <label className='px-2' htmlFor="avatar">Avatar</label>
                  <div className='my-2'>
                    <input type="file" className='custom-file-input' onChange={handleFileChange} accept="image/*, png/*" />
                    {previewImage && (
                      <div className='preview-image'>
                        <CloseCircleFilled className='close-preview-image' onClick={() => {
                          setPreviewImage(null)
                          setSelectedFile(null)
                        }} />
                        {previewImage && (
                          <div className='box-preview-image'>
                            <img
                              src={previewImage}
                              alt="Preview"
                              style={{ maxWidth: `100px` }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='my-2' span={24}>
                  <Button
                    className='m-2'
                    disabled={(loadingAdd) ? true : false}
                    loading={(loadingAdd) ? true : false}
                    onClick={() => handleSubmit(values)}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik >
      </div >
    </>
  );
};

export default Add;